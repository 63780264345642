import React from 'react'
import PropTypes from 'prop-types'
import { UncontrolledPopover } from 'reactstrap'
import Loader from 'react-loaders'

// DEPENENCIES
import profile_avatar from '../../assets/images/mock/StockProfilePic.png'

// STYLES
import '../../assets/styles/common/header.scss'
import '../../assets/styles/components/profile/ProfileHeader.scss'
import './ProfilePage.scss'

// CHILDREN
import EditGolferModal from './EditGolferModal'
import ProgramsModal from './Programs/ProgramsModal'
import SmallMilestoneIcon from '../common/SmallMilestoneIcon'
import DivisionPopover from './DivisionPopover'
import { withRouting } from '../common/hooks'
import ProfileHeaderActions from './ProfileHeaderActions'
import { abbrNum } from '../../utility/helperFunctions'

class ProfileHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      showLogActionsModal: false,
      showProgramsModal: false,
      alertType: 'success',
      alertTitle: '',
      alertMessage: '',
      lastAction: 0,
      loading: false,
      programsLoader: true,
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  getImageURLForUser(user) {
    if (user.avatar) {
      return user.avatar.small_file_url
    } else {
      return profile_avatar
    }
  }

  toggleModal() {
    this.setState((prevState) => ({ showModal: !prevState.showModal }))
  }

  toggleProgramsModal = () => {
    const { userActions, user } = this.props
    this.setState({ showProgramsModal: !this.state.showProgramsModal }, () => {
      if (this.state.showProgramsModal) {
        userActions.getUserCourses(user.id, 'student').then(() => {
          this.setState({
            programsLoader: false,
          })
        })
      }
    })
  }

  // eslint-disable-next-line complexity
  render() {
    const { current_user, user } = this.props
    const isCoach = current_user.type === 'Coach'

    const fullName = `${this.props.user.first_name} ${this.props.user.last_name}`

    return (
      <div id='ProfileHeader'>
        <div>
          <div className='row p-0 header-bg'>
            <div className='bar bar-nav container-fluid w-100 p-0 m-0'>
              <div className='bg-overlay overlay-5 no-gutter w-100 p-2 m-0'>
                <div className='row container-fluid profile-header-container'>
                  <div className='col-12'>
                    <div className='image-container'>
                      {this.props.userLoader ? (
                        <img
                          src={profile_avatar}
                          alt='User Loading'
                          className='img-responsive mx-auto d-block user-profile-img user-profile-img-web'
                        />
                      ) : (
                        <img
                          role='presentation'
                          src={this.getImageURLForUser(this.props.user)}
                          alt={fullName}
                          className='img-responsive mx-auto d-block user-profile-img user-profile-img-web animated fadeIn'
                          onClick={this.toggleModal}
                          onKeyDown={this.toggleModal}
                        />
                      )}
                    </div>
                    <div className='profile-data'>
                      <div className='data-section-1'>
                        {this.props.userLoader ? null : (
                          <h1 className='text-white profile-name animated fadeIn'>
                            {fullName}
                          </h1>
                        )}
                        {this.props.statsLoader ? null : (
                          <h4 className='text-white profile-stats animated fadeIn'>
                            Total Points -{' '}
                            {this.props.stats
                              ? abbrNum(this.props.stats.points, 1)
                              : 'Loading points'}
                          </h4>
                        )}
                      </div>
                      <div className='division-icon-container icon-from-header'>
                        {this.props.divisionLoader ? (
                          <Loader type='line-scale' active color='#ff6633' />
                        ) : (
                          <div>
                            <SmallMilestoneIcon
                              division={this.props.division.current_division}
                            />
                            <UncontrolledPopover
                              target='SmallMilestoneIcon'
                              placement='right'
                              trigger='legacy'
                            >
                              <DivisionPopover
                                division={this.props.division}
                                isCoach={isCoach}
                                isStudent={current_user.type === 'Student'}
                                isSelf={current_user.id === user.id}
                                firstName={user.first_name}
                              />
                            </UncontrolledPopover>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!this.props.userLoader && (
                  <div className='row container-fluid btn-row mobile-btn-row hidden-sm-up animated fadeIn'>
                    {isCoach && (
                      <div className='pull-right'>
                        <button
                          className='btn btn-primary edit-btn'
                          onClick={this.props.toggleLogActionsModal}
                        >
                          <i className='fa fa-plus' />
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {!this.props.userLoader && (
                  <ProfileHeaderActions
                    user={user}
                    onTogglePlayerProgressModal={this.props.togglePlayerProgressModal}
                    onToggleJourneyModal={this.props.toggleJourneyModal}
                    onToggleLogActionsModal={this.props.toggleLogActionsModal}
                    onToggleEditGolferModal={this.toggleModal}
                    onToggleProgramsModal={this.toggleProgramsModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <EditGolferModal
            toggleModal={this.toggleModal}
            showModal={this.state.showModal}
            golfer={this.props.user}
          />
        )}
        {this.state.showProgramsModal && (
          <ProgramsModal
            toggleModal={this.toggleProgramsModal}
            showModal={this.state.showProgramsModal}
            current_user={this.props.current_user}
            golfer={this.props.user}
            loader={this.state.programsLoader}
            courses={this.props.courses}
          />
        )}
      </div>
    )
  }
}

ProfileHeader.propTypes = {
  current_user: PropTypes.object,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    family: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  stats: PropTypes.shape({
    points: PropTypes.number,
  }),
  division: PropTypes.shape({
    current_division: PropTypes.number,
  }),
  userActions: PropTypes.shape({
    getUserCourses: PropTypes.func,
  }),
  userLoader: PropTypes.bool,
  statsLoader: PropTypes.bool,
  divisionLoader: PropTypes.bool,
  courses: PropTypes.array,
  togglePlayerProgressModal: PropTypes.func,
  toggleJourneyModal: PropTypes.func,
  toggleLogActionsModal: PropTypes.func,
  navigate: PropTypes.func,
}

export default withRouting(ProfileHeader)
