import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ReactComponent as BackArrow } from '../../../../assets/images/common/icons/back-arrow-icon.svg'
import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import moment from 'moment'
import './EventHeader.scss'

const EventHeader = ({ event, onClickBack, openEditModal }) => {
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const attendances = (event.students?.length || 0)
  const eventDateInTimezone = moment(event.event_date).tz(timezone)
  const completed = event.status === 'completed'

  return (
    <div className='event-header sticky-top'>
      <div className='w-100 d-flex align-items-center '>
        <div className='col-10 d-flex align-items-center'>
          <span className='mr-3 h-75' role='button' tabIndex={0} onClick={onClickBack} onKeyDown={null}>
            <BackArrow style={{ width: '25px' }}/>
          </span>
          <span className='event-title mr-5'>{event.name || event.indexName}</span>
          <span><i className='fa fa-calendar'/>{moment(eventDateInTimezone).format('dddd, MMMM D, YYYY')}</span>
          {event.location && <span><i className='fa fa-map-marker'/>{event.location}</span>}
        </div>
        <div className='col-2'>
          { event.status === 'completed' ?
            <span><GolferIcon fill='#fff' className='mr-2'/>{attendances} Golfer{attendances !== 1 && 's'} Attended</span>
            :
            event.status === 'draft' ?
              null
              :
              <div>
                <button
                  className='btn btn-primary text-white pull-right ml-3'
                  onClick={() => openEditModal({ action: !completed ? 'Edit Roster' : '' })}
                >
                  <span className='pl-3'>Edit {completed ? 'Event' : 'Roster'}</span>
                </button>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

EventHeader.propTypes = {
  event: PropTypes.object.isRequired,
  onClickBack: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
}

export default EventHeader
