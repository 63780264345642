import React from "react";
import moment from "moment";
import _ from "lodash";
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as userActions from "../../actions/userActions";

// COMPONENTS
import PageHeader from '../common/PageHeader/PageHeader'
import AccountForm from "./AccountForm/AccountForm";

class AccountPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      uploadPhotoFile: "",
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      email: this.props.user.email,
      phone: this.props.user.phone,
      gender: this.props.user.gender,
      birth_day: this.props.user ? this.setDate("day") : "",
      birth_month: this.props.user ? this.setDate("month") : "",
      birth_year: this.props.user ? this.setDate("year") : "",
      birthdate: "",
      headline: this.props.user.headline || '',
      bio: this.props.user.bio || '',
      booking_url: this.props.user.booking_url || '',
      is_golfer: this.props.user.is_golfer,
      facebook: this.props.user.social_media.facebook,
      twitter: this.props.user.social_media.twitter,
      insta: this.props.user.social_media.insta,
      current_password: "",
      password: "",
      password_confirmation: "",
      id: this.props.user.id,
      uploadPhotoPreview: "",
      avatarEditState: false,
      errors: [],
      photoUploading: false,
    };
  }
  componentDidMount() {
    // console.log(this.props.user);
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Account");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
  }

  setDate = (type) => {
    const { user } = this.props;
    if (!_.isEmpty(user)) {
      let date = moment(user.birthdate, "YYYY/MM/DD");
      switch (type) {
        case "day":
          return date.format("D");
        case "month":
          return date.format("M");
        case "year":
          return date.format("YYYY");
        default:
          return null;
      }
    }
    return null;
  };

  resetForm() {
    this.setState({
      uploadPhotoFile: "",
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      email: this.props.user.email,
      phone: this.props.user.phone,
      gender: this.props.user.gender,
      birth_day: this.props.user ? this.setDate("day") : "",
      birth_month: this.props.user ? this.setDate("month") : "",
      birth_year: this.props.user ? this.setDate("year") : "",
      headline: this.props.user.headline,
      bio: this.props.user.bio || '',
      booking_url: this.props.user.booking_url || '',
      is_golfer: this.props.user.is_golfer,
      facebook: this.props.user.social_media.facebook,
      twitter: this.props.user.social_media.twitter,
      insta: this.props.user.social_media.insta,
      current_password: "",
      password: "",
      password_confirmation: "",
      uploadPhotoPreview: "",
      avatarEditState: false,
      errors: [],
      loader: false,
    });
  }

  // FORM STATE

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleInputChange = (telNumber, selectedCountry) => {
    this.setState({
      phone: telNumber,
    });
  };

  onChangeBirthday = (event) => {
    switch (event.target.name) {
      case "day":
        this.setState({ birth_day: event.target.value });
        break;
      case "month":
        this.setState({ birth_month: event.target.value });
        break;
      case "year":
        this.setState({ birth_year: event.target.value });
        break;
      default:
        return;
    }
  };

  handleGolferChange = (e) => {
    this.setState({
      is_golfer: e.target.checked,
    });
  };

  handleAvatarChange = (e) => {
    const uploadPhotoFile = e.target.files[0];
    const uploadPhotoPreview = URL.createObjectURL(uploadPhotoFile);
    this.setState({ uploadPhotoFile, uploadPhotoPreview });
  };

  handleDisable = (e) => {
    e.preventDefault();
    console.log("Pretending to disable");
  };

  handleDelete = (e) => {
    e.preventDefault();
    console.log("Pretending to delete");
  };

  toggleUpdateAvatarModal = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      updateAvatarModalIsOpen: !prevState.updateAvatarModalIsOpen,
    }));
  };

  toggleAvatarEditState = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      avatarEditState: !prevState.avatarEditState,
    }));
  };

  // SUBMIT

  // eslint-disable-next-line complexity
  validateForm = (formState) => {
    const hasPasswordChanges = formState.password || formState.password_confirmation
    const passwordsMatch = formState.password === formState.password_confirmation

    if (!formState.email && this.props.user.type === 'Coach'){
      toast.error('Email can\'t be empty', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }

    if (hasPasswordChanges && !passwordsMatch){
      toast.error('Your new passwords must match', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }
    const age = moment().diff(formState.birthdate, 'years')
    if (this.props.user && this.props.user.family_admin && age < 13){
      toast.error('Family Admin accounts cannot be under 13 years old', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }
    const hasChanges =
      formState.first_name !== this.props.user.first_name ||
      formState.last_name !== this.props.user.last_name ||
      formState.email !== this.props.user.email ||
      formState.phone !== this.props.user.phone ||
      formState.gender !== this.props.user.gender ||
      formState.bio !== this.props.user.bio ||
      formState.headline !== this.props.user.headline ||
      formState.booking_url !== this.props.user.booking_url ||
      formState.is_golfer !== this.props.user.is_golfer ||
      formState.birthdate !== this.props.user.birthdate ||
      !_.isEqual(formState.social_media, this.props.user.social_media) ||
      formState.uploadPhotoFile ||
      hasPasswordChanges
    if (!hasChanges){
      toast.error('You didn\'t change anything!', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }
    return true
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { _in, ...formState } = this.state; //eslint-disable-line

    const birthdate = moment()
      .date(formState.birth_day)
      .month(formState.birth_month - 1)
      .year(formState.birth_year)
      .toDate()
    formState.birthdate = birthdate

    if (this.validateForm(formState)) {
      this.setState({ photoUploading: true, loader: true });
      this.props.user_actions.updateCurrentUser(formState).then(() => {
        this.setState({ photoUploading: false, loader: false });
      });

      // this.resetForm();
    }
  };

  render() {
    const { loader } = this.state;
    return (
      <div id='AccountPage'>
        <div>
          <PageHeader title='Account' />
          <div className='col-md-9 mx-auto my-5 card animated fadeIn'>
            <AccountForm
              user={this.props.user}
              current_user={this.props.user}
              handleChange={this.handleChange}
              handleInputChange={this.handleInputChange}
              onChangeBirthday={this.onChangeBirthday}
              handleGolferChange={this.handleGolferChange}
              handleAvatarChange={this.handleAvatarChange}
              formState={this.state}
              errors={this.state.errors}
              toggleAvatarEditState={this.toggleAvatarEditState}
              avatarEditState={this.state.avatarEditState}
              photoUploading={this.state.photoUploading}
              isFamilyAdmin={
                this.props.user.type === "Student" &&
                this.props.user.family_admin
              }
              isCoach={this.props.user.type === "Coach"}
              isCurrentUser={true}
            />

            <div className='d-flex justify-content-center align-items-center my-4'>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={this.handleSubmit}
                disabled={loader}
              >
                Sav{loader ? "ing" : "e"} Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    family_admin: PropTypes.bool,
    bio: PropTypes.string,
    booking_url: PropTypes.string,
  }),
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
