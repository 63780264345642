import React, { Component } from 'react'
import PropTypes from 'prop-types'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as commonActions from '../../../actions/commonActions'
import * as userActions from '../../../actions/userActions'
import * as courseActions from '../../../actions/courseActions'

// CHILDREN
import ProgramRow from '../ProgramBuilderOld/ProgramRow'
import Pagination from '../../common/Pagination'
import Loader from '../../common/Op36Loader-web'

// ASSETS
import './ProgramMarketplaceFlow.scss'

class ProgramMarketPlaceFlow extends Component {
  state = {
    programLoader: false,
    perPage: 12,
  }

  componentDidMount() {
    this._loadTemplates(1)
  }

  render() {
    const { pages } = this.props

    return (
      <div
        id='ProgramMarketplaceContainer'
        className='col-md-6 mx-auto animated fadeIn'
      >
        <div className='program-selector-row'>
          <button>
            Your Class Copies
          </button>
        </div>
        <div className='available-programs-container'>
          {this._renderTemplates()}
        </div>
        {pages > 1 && (
          <div className='row animated fadeIn'>
            <Pagination pageCount={pages} fetchData={this._loadTemplates} />
          </div>
        )}
      </div>
    )
  }

  _loadTemplates = (page) => {
    const { user, user_actions } = this.props
    const { perPage } = this.state
    this.setState({ programLoader: true }, () => {
      if (user.type === 'Admin') {
        user_actions
          .getUserCourses(user.id, 'admin', page, perPage)
          .then(() => this.setState({ programLoader: false }))
      } else {
        this.setState({ programLoader: true })
        user_actions
          .getUserCourses(
            user.communities[0].id,
            null,
            page,
            perPage,
            null
          )
          .then(() => {
            this._refreshUser()
          })
      }
    })
  }

  _refreshUser = () => {
    const { user_actions, user } = this.props
    user_actions.refreshCurrentUser(user.id).then(() => {
      this.setState({ programLoader: false })
    })
  }

  _viewProgram = program => {
    const { onSelectedProgram } = this.props

    onSelectedProgram(program)
  }

  _renderTemplates = () => {
    const { user, courses } = this.props
    const { programLoader } = this.state

    if (programLoader) {
      return (
        <div className='col-md-6 mx-auto animated fadeIn template-loader-container'>
          <Loader message='Loading Templates' />
        </div>
      )
    }

    if (courses && courses.length > 0) {
      const programs = this.props.courses ? [...this.props.courses] : []
      const completedPrograms = this.props.completedCourses
        ? [...this.props.completedCourses]
        : []

      // combine both active and completed programs together & push them into the variable programs
      Array.prototype.push.apply(programs, completedPrograms)

      return programs.map((template, index) => (
        <ProgramRow
          program={template}
          viewProgram={this._viewProgram}
          user={user}
          key={index}
        />
      ))
    }
  }
}

ProgramMarketPlaceFlow.propTypes = {
  onSelectedProgram: PropTypes.func.isRequired,
  user: PropTypes.shape({
    communities: PropTypes.array,
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  push: PropTypes.func,
  selectTemplate: PropTypes.func,
  user_actions: PropTypes.shape({
    refreshCurrentUser: PropTypes.func,
    getUserCourses: PropTypes.func,
  }),
  courses: PropTypes.array,
  completedCourses: PropTypes.array,
  pages: PropTypes.number,
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    courses: state.courses.courses,
    completed_courses: state.courses.completed_courses,
    archived_courses: state.courses.archived_courses,
    template_courses: state.courses.template_courses,
    featured_courses: state.courses.featured_courses,
    pages: state.pages,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    course_actions: bindActionCreators(courseActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramMarketPlaceFlow)
