import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import SubmitButton from '../../common/buttons/SubmitButton'
import DeleteAccountModal from './DeleteAccountModal'
import { deleteUser } from '../../../actions/userActions'

import classes from './AccountSettings.module.scss'

const AccountSettings = ({ userId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.current_user)

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handleDeleteAccount = () => {
    dispatch(deleteUser(userId, password))
      .then(() => navigate('/family'))
      .catch((error) => {
        if (error.response && error.response.data?.errors?.current_password) {
          setPasswordError('The password is incorrect. Make sure you are typing the password of the student you want to delete.')
        } else {
          setPasswordError('Something went wrong. Please try again.')
        }
      })
  }

  return (
    <div className={classNames(classes.accountSettings, 'col-md-8 p-5 animated fadeIn d-flex flex-column')}>
      <div className='row'>
        <h4 className='col-md-12'>Account Settings</h4>
      </div>
      <p className={classNames(classes.deleteSubtitle, 'pb-2')}>Delete Account and Data</p>
      {currentUser.family_admin ? (
        <>
          <p className={classes.deleteMessage}>Are you sure you want to delete the account and its data?</p>

          <SubmitButton
            onClick={(e) => { e.preventDefault(); setShowDeleteAccountModal(true) }}
            buttonMessage='Delete Account'
            buttonColor='orange-button'
            className={classNames(classes.deleteButton, 'mt-auto')}
          />

          <DeleteAccountModal
            showModal={showDeleteAccountModal}
            closeModal={() => { setPassword(''); setPasswordError(''); setShowDeleteAccountModal(false) }}
            password={password}
            passwordError={passwordError}
            handlePasswordChange={(e) => { setPassword(e.target.value) }}
            handleDeleteAccount={handleDeleteAccount}
          />
        </>
      ) : (
        <>
          <p className={classes.deleteMessage}>Only Family Admins can Delete Account. Please ask your parent to do it for you.</p>
        </>
      )}
    </div>
  )
}

AccountSettings.propTypes = {
  userId: PropTypes.number.isRequired,
}

export default AccountSettings
