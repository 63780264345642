import PropTypes from 'prop-types'
import SubmitButton from '../common/buttons/SubmitButton'
import styles from './EventInProgress.module.scss'

const EventInProgress = ({ onClick, isQuickLog }) => (
  <div className={styles.messageCard}>
    <i className='fa fa-exclamation-triangle' />
    <span className='ml-3'>{isQuickLog ? 'Quick Log Event' : 'Logged scores'} in Progress</span>
    <div className={styles.continueEditingButton}>
      <SubmitButton
        className='mb-3'
        buttonMessage='Continue Editing'
        buttonColor='orange-button'
        onClick={onClick}
      />
    </div>
  </div>
)

EventInProgress.propTypes = {
  onClick: PropTypes.func.isRequired,
  isQuickLog: PropTypes.bool,
}

export default EventInProgress
