import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import EventHeader from './Header/EventHeader'
import PageFooter from '../../common/PageFooter/PageFooter'
import EventForm from '../Form/EventForm'
import Loader from '../../common/Op36Loader-web'
import EventInfo from './Leaderboard/EventInfo'
import Leaderboard from './Leaderboard/Leaderboard'
import GuestList from './GuestList'
import ConfirmationModal2 from '../../common/ConfirmationModal2'
import EventInProgress from '../EventInProgress'
import { ReactComponent as EditIcon } from '../../../assets/images/common/icons/programBuilder/edit-icon.svg'

import * as userActions from '../../../actions/userActions'
import * as communitiesActions from '../../../actions/communitiesActions'
import * as eventActions from '../../../actions/eventActions'
import colors from '../../../assets/styles/globals.scss'

import styles from './EventDetail.module.scss'
import { setField } from '../../../reducers/nineHoleEventReducer'
import classNames from 'classnames'

const EditIconComponent = (
  <div className={styles.editIconWrapper}>
    <EditIcon fill='#fff' width='40px' height='40px'/>
  </div>
)

const EventDetail = ({ eventId }) => { // eslint-disable-line max-lines-per-function
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const currentUser = useSelector(state => state.user.current_user)
  const event = useSelector(state => state.nineHoleEvent)
  const [showForm, setShowForm] = useState(false)
  const [eventLoader, setEventLoader] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteEnrollmentModal, setShowDeleteEnrollmentModal] = useState(false)
  const [showLoadScoresModal, setShowLoadScoresModal] = useState(false)
  const [selectedStudentId, setSelectedStudentId] = useState(null)
  const completed = !event.status || event.status === 'completed'

  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[event.id] && localScores[event.id]['scores']

  const onClickBack = () => {
    navigate('/events')
  }

  const openEditModal = ({ action }) => {
    if (action) { dispatch(setField({ field: 'action', value: action })) }
    setShowForm(!showForm)
  }

  const closeEditModal = () => {
    fetchEvent()
    setShowForm(false)
  }

  const fetchCourses = useCallback(() => {
    dispatch(userActions.getEventCourses(currentUser.communities[0].id))
  }, [dispatch, currentUser.communities])

  const fetchStudents = useCallback((searchTerm = null) => {
    dispatch(communitiesActions.getAllStudents(currentUser.communities[0].id, null, null, searchTerm))
  }, [dispatch, currentUser.communities])

  const fetchEvent = useCallback(() => {
    setEventLoader(true)
    dispatch(eventActions.getEventDetail(params.eventId || eventId)).unwrap().then(() => {
      setEventLoader(false)
    })
  }, [dispatch, params.eventId, eventId])

  const handleDeleteEnrollment = () => {
    dispatch(eventActions.deleteEnrollment({ eventId: event.id, userId: selectedStudentId })).unwrap()
      .catch(() => {
        toast.error('Something went wrong.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => {
        setShowDeleteEnrollmentModal(false)
      })
  }

  const updateEvent = () => {
    setEventLoader(true)
    dispatch(eventActions.updateEvent(event)).then(() => {
      toast.success(`Success! Updated ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      removeDraftScores()
      _.delay(handleEventSubmittedClick, 5000)
      setEventLoader(false)
    }).catch(() => setEventLoader(false))
  }

  const updateEnrollments = () => {
    setEventLoader(true)
    dispatch(eventActions.updateEnrollments({ event })).then(() => {
      toast.success(`Success! Updated ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      setEventLoader(false)
      handleEventSubmittedClick()
    }).catch(() => setEventLoader(false))
  }

  const handleEventSubmittedClick = () => {
    if (!showForm) {
      return
    }

    fetchEvent()
    setShowForm(false)
  }

  const deleteEvent = () => {
    dispatch(eventActions.deleteEvent(event.id)).then(() => {
      toast.success(`Successfully deleted ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      navigate('/events')
    })
  }

  const removeStudent = (id) => {
    setSelectedStudentId(id)
    setShowDeleteEnrollmentModal(true)
  }

  const editResultsButton = {
    text: 'Edit Results',
    onClick: () => openEditModal({ action: 'Edit Results' }),
  }

  const deleteEventButton = {
    text: 'Delete Event',
    onClick: () => setShowDeleteModal(true),
    style: { backgroundColor: colors.primaryNavy },
  }

  const sendEmailButton = {
    text: 'Send an Email',
    icon: 'fa fa-envelope',
    onClick: () => navigate('/announcements/new-campaign', { state: { smartToggle: 'email', students: event.students } }),
  }

  const logScoresButton = {
    text: 'Log Scores',
    onClick: () => handleLogScoresClick(),
  }

  const handleLogScoresClick = () => {
    const scores = JSON.parse(localStorage.getItem('draftScores'))
    if (scores && scores[event.id]) {
      setShowLoadScoresModal(true)
    } else {
      openEditModal({ action: 'Log Scores' })
    }
  }

  const loadScores = () => {
    setShowLoadScoresModal(false)
    const updatedStudents = event.students.map((std) => {
      const studentData = eventScores[std.user_id]
      if (studentData) {
        return {
          ...std,
          score: studentData['score'],
          greens: studentData['greens'],
          putts: studentData['putts'],
          yardage: studentData['yardage'],
          fullTeeYardage: studentData['fullTeeYardage'],
          level: studentData['level'],
        }
      }
      return std
    })
    dispatch(setField({ field: 'students', value: updatedStudents }))
    openEditModal({ action: 'Log Scores' })
  }

  const removeDraftScores = () => {
    const localScores = JSON.parse(localStorage.getItem('draftScores'))
    delete localScores[event.id]
    localStorage.setItem('draftScores', JSON.stringify(localScores))
  }

  const startFromScratch = () => {
    removeDraftScores()
    setShowLoadScoresModal(false)
    openEditModal({ action: 'Log Scores' })
  }

  useEffect(() => {
    fetchEvent()
  }, [fetchEvent])

  return (
    <div className={styles.eventDetail} style={{ marginLeft: !showForm ? '-15px' : '0' }} >
      {showForm ?
        <EventForm
          isEdit
          onClickBack={closeEditModal}
          fetchCourses={fetchCourses}
          fetchStudents={fetchStudents}
          updateEvent={updateEvent}
          updateEnrollments={updateEnrollments}
          handleEventSubmittedClick={handleEventSubmittedClick}
          removeDraftScores={removeDraftScores}
          eventLoader={eventLoader}
        /> :
        eventLoader
          ? (<Loader message='loading event' />)
          : (
            <div className='w-100'>
              <EventHeader
                className='sticky-top'
                event={event}
                onClickBack={onClickBack}
                openEditModal={openEditModal}
              />
              <div className='row mx-0'>
                {completed ?
                  <>
                    <div className='col-md-4'>
                      <EventInfo/>
                    </div>
                    <div className='col-md-8'>
                      <Leaderboard/>
                    </div>
                  </> :
                  <div className={classNames('col-12 px-0', styles.guestList)}>
                    {eventScores &&
                      <div className='px-4 py-3'>
                        <EventInProgress onClick={loadScores}/>
                      </div>
                    }
                    <GuestList students={event.students} removeStudent={removeStudent}/>
                  </div>
                }
              </div>
              <PageFooter
                firstButton={completed ? editResultsButton : sendEmailButton}
                secondButton={completed ? deleteEventButton : (event.status === 'draft' ? null : logScoresButton)}
                reversed
              />
            </div>
          )
      }
      <ConfirmationModal2
        showModal={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onDeny={() => setShowDeleteModal(false)}
        denyMessage='Cancel'
        onProceed={() => deleteEvent()}
        proceedMessage='Delete Event'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will delete all activities for the golfers of this event.'
      />

      <ConfirmationModal2
        showModal={showDeleteEnrollmentModal}
        closeModal={() => setShowDeleteEnrollmentModal(false)}
        onDeny={() => setShowDeleteEnrollmentModal(false)}
        denyMessage='Cancel'
        onProceed={() => handleDeleteEnrollment()}
        proceedMessage='Remove Student'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will remove the student from this event.'
      />

      <ConfirmationModal2
        showModal={showLoadScoresModal}
        closeModal={() => setShowLoadScoresModal(false)}
        onDeny={startFromScratch}
        denyMessage='Start from Scratch'
        onProceed={loadScores}
        proceedMessage='Load Scores'
        icon={EditIconComponent}
        title='Load draft scores?'
        message='There are draft scores saved for this event. Do you want to load them?'
      />
    </div>
  )
}

EventDetail.propTypes = {
  eventId: PropTypes.number,
}

export default EventDetail
