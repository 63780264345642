import { useSelector } from 'react-redux'

import ManageRoster from '../../Form/ManageRoster'
import { addStudent, deleteStudent, addCourse, deleteCourse, updateStudent, updateCourse } from '../../../../reducers/seriesReducer'

const ManageSeriesRoster = () => {
  const series = useSelector(state => state.series)
  return (
    <ManageRoster
      students={series.students}
      courses={series.courses}
      addStudent={addStudent}
      deleteStudent={deleteStudent}
      addCourse={addCourse}
      deleteCourse={deleteCourse}
      updateStudent={updateStudent}
      updateCourse={updateCourse}
    />
  )
}

export default ManageSeriesRoster
