import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyActions from "../../actions/familyActions";
import * as userActions from "../../actions/userActions";
import * as authActions from "../../actions/authActions";

// DEPENDENCIES
import Modal from "react-modal";

// CHILDREN
// import EditGolferForm from "./EditGolferForm";
import AccountForm from "../account/AccountForm/AccountForm";

class FamilyMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      uploadPhotoFile: "",
      first_name: props.golfer.first_name,
      last_name: props.golfer.last_name,
      email: props.golfer.email,
      username: props.golfer.username,
      phone: props.golfer.phone,
      gender: props.golfer.gender,
      birth_day: props.golfer ? this.setDate("day") : "",
      birth_month: props.golfer ? this.setDate("month") : "",
      birth_year: props.golfer ? this.setDate("year") : "",
      birthdate: "",
      headline: props.golfer.headline,
      bio: props.golfer.bio,
      booking_url: props.golfer.booking_url,
      is_golfer: props.golfer.is_golfer,
      current_password: "",
      password: "",
      password_confirmation: "",
      resetEmailSent: false,
      id: props.golfer.id,
      uploadPhotoPreview: "",
      avatarEditState: false,
      errors: [],
      photoUploading: false,
    };
  }

  setDate = (type) => {
    const { golfer } = this.props;
    if (!_.isEmpty(golfer)) {
      let date = moment(golfer.birthdate, "YYYY/MM/DD");
      switch (type) {
        case "day":
          return date.format("D");
        case "month":
          return date.format("M");
        case "year":
          return date.format("YYYY");
        default:
          return null;
      }
    }
    return null;
  };

  resetForm() {
    this.setState({
      first_name: this.props.golfer.first_name,
      last_name: this.props.golfer.last_name,
      email: this.props.golfer.email,
      username: this.props.golfer.username,
      phone: this.props.golfer.phone,
      gender: this.props.golfer.gender,
      birth_day: this.props.golfer ? this.setDate("day") : "",
      birth_month: this.props.golfer ? this.setDate("month") : "",
      birth_year: this.props.golfer ? this.setDate("year") : "",
      headline: this.props.golfer.headline,
      bio: this.props.golfer.bio,
      booking_url: this.props.golfer.booking_url,
      is_golfer: this.props.golfer.is_golfer,
      current_password: "",
      password: "",
      password_confirmation: "",
      uploadPhotoPreview: "",
      avatarEditState: false,
      errors: [],
      loader: false,
    });
  }

  // FORM STATE

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleInputChange = (telNumber, selectedCountry) => {
    this.setState({
      phone: telNumber,
    });
  };

  handleResetPassword = () => {
    let email = this.state.email ? this.state.email : this.state.username;
    this.props.auth_actions
      .triggerResetPassword(email)
      .then((response) => {
        this.setState({ resetEmailSent: true });
        this.props.toggleModal();
      })
      .catch((err) => {});
  };

  // eslint-disable-next-line complexity
  validateForm = (formState, golfer) => {
    const hasPasswordChanges = formState.password || formState.password_confirmation
    const passwordsMatch = formState.password === formState.password_confirmation

    if (!formState.email && golfer.type === 'Coach'){
      toast.error('Email can\'t be empty', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }

    if (hasPasswordChanges && !passwordsMatch){
      toast.error('Your new passwords must match', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }

    const age = moment().diff(formState.birthdate, 'years')
    if (golfer && golfer.family_admin && age < 13){
      toast.error('Family Admin accounts cannot be under 13 years old', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }
    const hasChanges =
      formState.first_name !== golfer.first_name ||
      formState.last_name !== golfer.last_name ||
      formState.email !== golfer.email ||
      formState.username !== golfer.username ||
      formState.phone !== golfer.phone ||
      formState.gender !== golfer.gender ||
      formState.bio !== golfer.bio ||
      formState.headline !== golfer.headline ||
      formState.booking_url !== golfer.booking_url ||
      formState.is_golfer !== golfer.is_golfer ||
      formState.uploadPhotoFile ||
      formState.birthdate !== golfer.birthdate ||
      hasPasswordChanges
    if (!hasChanges){
      toast.error('You didn\'t change anything!', { position: toast.POSITION.BOTTOM_LEFT })
      return false
    }
    return true
  }

  handleSubmit = (e) => {
    // e.preventDefault();
    const { _in, ...formState } = this.state; //eslint-disable-line
    const { golfer } = this.props

    const birthdate = moment()
      .date(formState.birth_day)
      .month(formState.birth_month - 1)
      .year(formState.birth_year)
      .toDate()

    formState.birthdate = birthdate

    if (this.validateForm(formState, golfer)) {
      this.setState({ photoUploading: true, loader: true });
      this.props.user_actions.updateUser(formState).then((response) => {
        //this.props.toggleModal()
        if (_.isEmpty(this.props.error)) {
          this.props.user_actions.getUser(golfer.id).then(() => {
            this.setState({ loader: false, photoUploading: false });
            console.log(this.props.error);
            this.props.toggleModal();
          });
        } else {
          this.setState({ loader: false, photoUploading: false });
        }
      });

      // this.resetForm();
    }
  };

  onChangeBirthday = (event) => {
    switch (event.target.name) {
      case "day":
        this.setState({ birth_day: event.target.value });
        break;
      case "month":
        this.setState({ birth_month: event.target.value });
        break;
      case "year":
        this.setState({ birth_year: event.target.value });
        break;
      default:
        return;
    }
  };

  handleGolferChange = (e) => {
    this.setState({
      is_golfer: e.target.checked,
    });
  };

  render() {
    let { showModal, toggleModal, golfer } = this.props;
    const { loader } = this.state;

    return (
      <div>
        <Modal
          isOpen={showModal}
          onRequestClose={toggleModal}
          contentLabel='EditGolferModal'
          // style={modalStyles}
        >
          <div className='modal-header row'>
            <div className='col-11'>
              <h5 className='modal-title display-4'>Edit Golfer Account</h5>
            </div>
            <div className='col-1'>
              <button
                type='button'
                className='close pull-right font-200'
                data-dismiss='modal'
                aria-label='Close'
                onClick={toggleModal}
              >
                <span aria-hidden='true'>✕</span>
              </button>
            </div>
          </div>

          <div className='modal-content'>
            <div className='modal-body' id='input-border'>
              <div className='row mb-2 p-2'>
                <div className='col-md-9 mx-auto'>
                  <div className='form-group'>
                    <AccountForm
                      user={golfer}
                      current_user={this.props.user}
                      handleChange={this.handleChange}
                      handleInputChange={this.handleInputChange}
                      onChangeBirthday={this.onChangeBirthday}
                      handleGolferChange={this.handleGolferChange}
                      handleAvatarChange={this.handleAvatarChange}
                      formState={this.state}
                      toggleAvatarEditState={this.toggleAvatarEditState}
                      avatarEditState={this.state.avatarEditState}
                      photoUploading={this.state.photoUploading}
                      handleResetPassword={this.handleResetPassword}
                      isProfile
                      error={this.props.error}
                      isCoach={this.props.user.type === "Coach"}
                      isCurrentUser={this.props.user.id === golfer.id}
                      isFamilyAdmin={
                        this.props.user.type === "Student" &&
                        this.props.user.family_admin
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center my-4'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={() => this.handleSubmit()}
                  disabled={
                    // need to clean up this logic, but it works for now
                    loader || this._userCantChangeThis(this.props.user, golfer)
                  }
                >
                  Sav{loader ? "ing" : "e"} Changes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  _userCantChangeThis = (user, golfer) => {
    if (user.id === golfer.id) {
      return false;
    }
    if (user.family_admin && user.family.id === golfer.family.id) {
      return false;
    }
    if (user.type === "Coach" && golfer.type !== "Coach") {
      return false;
    }
    return true;
  };
}

FamilyMemberModal.propTypes = {
  golfer: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    family_admin: PropTypes.bool,
  }),
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.current_user,
    family: state.family,
    error: state.ui.error || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    auth_actions: bindActionCreators(authActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FamilyMemberModal);
