import styles from './CoachDashboardProgramsOverview.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as EnrollmentsIcon } from '../../../assets/images/common/icons/sidebarMenu/Account.svg'
import { ReactComponent as ProgramsIcon } from '../../../assets/images/common/icons/sidebarMenu/Programs.svg'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../../../utility/datesHelper'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import ProgramsOverviewAction from './ProgramsOverviewAction'
import { useEffect } from 'react'
import { getCoachCourses } from '../../../actions/dashboardActions'
import PropTypes from 'prop-types'

const CoachDashboardProgramsOverview = ({ communityId }) => {
  const courses = useSelector(state => state.dashboard.courses)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCoachCourses(communityId))
  }, [communityId, dispatch])

  const activeCourses = courses?.filter(course => {
    if (!course.registration_starts_at){
      return true
    }
    const start_date = getMomentInCommunityTimezoneReversed(moment(course.registration_starts_at), timezone, DATETIME_FORMAT).toDate()
    const end_date = getMomentInCommunityTimezoneReversed(moment(course.registration_ends_at), timezone, DATETIME_FORMAT).toDate()
    const current_date = new Date()
    if ( start_date < current_date && current_date < end_date){
      return true
    }
    return false
  })

  const futureCoursesCount = courses?.reduce((count, course) => {
    if (!course.registration_starts_at){
      return count += 0
    }
    const start_date = getMomentInCommunityTimezoneReversed(moment(course.registration_starts_at), timezone, DATETIME_FORMAT).toDate()
    return start_date > new Date() ? count += 1 : count
  }, 0)

  const activeEnrollmentsCount = activeCourses?.reduce((count, course) => count += course.enrollments.length, 0)

  const spotsAvailable = activeCourses?.map(course => course.golfer_limit ? [course.enrollments.length, course.golfer_limit] : [0, 0])
    .reduce((count, courseSpots) => [count[0] + courseSpots[0], count[1] + courseSpots[1]], [0, 0])

  return (
    <div className={styles.CoachDashboardProgramsOverview}>
      <div className={styles.title}>Current Programs Overview</div>
      <div className={styles.programsOverviewContainer}>
        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <EnrollmentsIcon className={styles.icon}/>
          </div>
          <div className={styles.info}>
            <div className={styles.data}>{activeEnrollmentsCount}</div>
            <div className={styles.text}>Active Enrollment(s)</div>
          </div>
        </div>

        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <GolferIcon className={styles.icon}/>
          </div>
          {spotsAvailable &&
            <div className={styles.info}>
              <div className={styles.textSpots}>
                <span className={styles.spotsTaken}>{spotsAvailable[1] - spotsAvailable[0]}</span>
                /
                <span>{spotsAvailable[1]} </span>
                Spots Available
              </div>
            </div>
          }
        </div>

        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <ProgramsIcon className={styles.icon}/>
          </div>
          <div className={styles.info}>
            <div className={styles.data}>{activeCourses?.length}</div>
            <div className={styles.text}>Active Program(s)</div>
          </div>
        </div>

        <div className={styles.programsOverviewItem}>
          <div className={styles.iconWrapper}>
            <ProgramsIcon className={styles.icon}/>
          </div>
          <div className={styles.info}>
            <div className={styles.data}>{futureCoursesCount}</div>
            <div className={styles.text}>Future Program(s)</div>
          </div>
        </div>
      </div>

      <ProgramsOverviewAction
        programs={activeCourses?.length}
        spots={spotsAvailable && spotsAvailable[1] - spotsAvailable[0]}
        enrollments={activeEnrollmentsCount}
      />
    </div>
  )
}

CoachDashboardProgramsOverview.propTypes = {
  communityId: PropTypes.number,
}

export default CoachDashboardProgramsOverview
