import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { forwardRef, Fragment, useEffect } from 'react'

import { honeybadger } from '../../honeybadger'
import { getCategoryPrograms, getCommunityLandingPagePrograms } from '../../actions/programActions'
import { defaultPerPage, setCategoryViewScrollPosition } from '../../reducers/communityLandingPageReducer'
import ProgramCard from './ProgramCard'
import InfiniteScroll from '../infinite-scroll/InfiniteScroll'

import classes from './ProgramList.module.scss'

const ProgramList = forwardRef((_, programsScreenRef) => {
  const dispatch = useDispatch()
  const { categories, courses, community, filters, hasMore, page, categoryViewScrollPosition } = useSelector(state => state.communityLandingPage)

  const isFutureRegistrationFilter = filters.future
  const isOneCategory = courses[0].courseCategoryId === courses[courses.length - 1].courseCategoryId

  useEffect(() => {
    const currentRef = programsScreenRef
    currentRef?.current?.scrollTo(0, categoryViewScrollPosition)

    return () => {
      if (currentRef.current) {
        const { scrollTop } = currentRef.current
        dispatch(setCategoryViewScrollPosition(scrollTop))
      }
    }
  }, [dispatch, programsScreenRef, categoryViewScrollPosition])

  return (
    <InfiniteScroll
      next={() => {
        dispatch(getCommunityLandingPagePrograms({ communityId: community.id, filters, page: page + 1, perPage: defaultPerPage }))
      }}
      hasMore={hasMore}
      dataLength={courses.length}
      scrollableTarget='programsScreen'
    >
      {courses.map((course, idx) => {
        const category = categories.entities[course.courseCategoryId]

        if (!category) {
          honeybadger.notify(
            'Program with undefined category accessed on Community Landing Page',
            {
              name: 'Custom log error - Community Landing Page',
              context: {
                categories: categories.entities,
                course,
              },
            }
          )
        }

        const programCard = (
          <ProgramCard
            key={course.id}
            program={course}
            community={community}
            color={category?.color || '#000000'}
            hasFutureRegistration={isFutureRegistrationFilter}
          />
        )
        const categoryId = course.courseCategoryId

        const isDifferentCategory = idx === 0 || courses[idx - 1].courseCategoryId !== categoryId
        const isOtherAndSingle = !categoryId && isOneCategory

        if (isDifferentCategory && !isOtherAndSingle) {
          return (
            <Fragment key={course.id}>
              <h2 className={classnames('mt-3', classes.categoryName)}>
                {category?.id === null ? 'Other Programs' : (
                  <button
                    onClick={() => {
                      if (category) {
                        dispatch(getCategoryPrograms({ categoryId: category.id, communityId: community.id, page: 1, perPage: defaultPerPage }))
                      }
                    }}
                    className={classes.categoryNameButton}
                  >
                    {category?.name}
                    <i className={classnames('fa fa-angle-right ml-3', classes.angle)} />
                  </button>
                )}
              </h2>
              {programCard}
            </Fragment>
          )
        } else {
          return programCard
        }
      })}
    </InfiniteScroll>
  )
})

ProgramList.displayName = 'ProgramList'

export default ProgramList
