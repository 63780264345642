import React from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as userActions from "../../actions/userActions";
import * as courseActions from "../../actions/courseActions";
import * as scheduleActions from '../../actions/scheduleActions';
import * as lessonPlanActions from "../../actions/lessonPlanActions";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SearchInput from '../common/SearchInput'
import _ from "lodash";
import Modal from "react-modal";
import CoachClassCard from "./CoachClassCard/CoachClassCard";
import CopyProgramDialog from './CopyProgram/CopyProgramDialog'
import PageHeader from '../common/PageHeader/PageHeader'
import * as fadeAnimation from "../common/fadeAnimation";
import Loader from "../common/Op36Loader";
import Placeholder from "../common/Placeholder";
import "./Classes.scss";
import "./CoachClassesPage.scss";
import ValidationModal from "../common/ValidationModal";
import Pagination from "../common/Pagination";
import { withRouting } from "../common/hooks";
import { ReactComponent as TableSelectDropDownIcon } from '../../assets/images/common/icons/table-select-drop-down-icon.svg'
import { UncontrolledPopover } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as OutsideLinkIcon } from '../../assets/images/common/icons/outside-link-icon.svg'
import colors from '../../assets/styles/globals.scss'

const KEYS_TO_FILTERS = ["name", "meeting_times"];

class CoachClassesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showAddCourseModal: false,
      showCopyCourseModal: false,
      alertTitle: "Error",
      alertMessage: "Something went wrong, please try again later.",
      alertType: "danger",
      in: false,
      loading: true,
      selectedIndex:
        this.props.params && this.props.params.tabIndex
          ? parseInt(this.props.params.tabIndex, 10)
          : 0,
      completedCourseLoader: true,
      showValidationModal: false,
      validationSuccess: true,
      validationMessage: "",
      createClassLoader: false,
      searchTerm: "",
      perPage: 12,
      showCourseType: "active",
      loader: false,
      errors: {},
      createdCourseId: -1,
      startStep: ''
    };
  }

  componentWillMount() {
    //this.props.user_actions.getUserCourses(this.props.user.id);
    const { common_actions } = this.props
    const { selectedIndex, perPage } = this.state;

    this._loadPrograms(1, perPage, selectedIndex);
    common_actions.setCurrentTab('Programs')
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);

    this.props.lessonPlan_actions.grabLessonPlans(this.props.user.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.courses !== nextProps.courses) {
      this.setState({ courses: nextProps.courses })
    }
  }

  componentDidMount() {
    this._toggleEnterState();
  }

  setStartStep(step) {
    this.setState({ startStep: step })
  }

  setErrors(errors) {
    this.setState({ errors: errors })
  }

  render() {
    const {
      user,
      courses,
      completed_courses,
      template_courses,
      featured_courses,
      pages,
    } = this.props;
    const {
      showCourseType,
      showAddCourseModal,
      showCopyCourseModal,
      course,
      createClassLoader,
      loader,
      validationSuccess,
      showValidationModal,
      validationMessage,
      searchTerm,
    } = this.state;
    const templateOrProgram = user.type === 'Admin' ? 'Templates' : 'Group Programs'
    return (
      <div id='CoachClassesPage' className='mb-5'>
        <PageHeader
          title={templateOrProgram}
          actionComponent={this._headerActionButton(showCopyCourseModal)}
        />

        <Tabs onSelect={(index) => this._toggleCoursesType(index)}>
          <TabList className='tabList'>
            <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>Upcoming Programs</Tab>
            <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>Program History</Tab>
            <Tab className='tab react-tabs__tab tabCommunityLanding' disabled={true}>
              <Link to={`/landing/${user.communities[0].id}`} target='_blank' className='d-flex align-items-center view-community-link'>
                <OutsideLinkIcon id='outsideLinkIcon'/>
                <span className='view-community-label'>View Community Landing Page</span>
              </Link>
            </Tab>
          </TabList>
          <TabPanel className='tabPanel'>
            <div className='programs-search-bar mt-2'>
              <SearchInput placeholder='Search Programs' onChange={this._searchUpdated}/>
            </div>
            <div className='row'>
              {searchTerm === '' && pages > 1 && (
                <Pagination pageCount={pages} fetchData={this._loadPrograms} />
              )}
            </div>
            {this.state.in ? (
              <div className='container-fluid'>
                <div className='row'>
                  {this._displayCourses('active')}
                </div>
              </div>
            ) : (
              <div />
            )}
          </TabPanel>
          <TabPanel className='tabPanel'>
            <div className='programs-search-bar mt-2'>
              <SearchInput placeholder='Search Programs' onChange={this._searchUpdated}/>
            </div>
            <div className='row'>
              {searchTerm === '' && pages > 1 && (
                <Pagination pageCount={pages} fetchData={this._loadPrograms} />
              )}
            </div>
            {this.state.in ? (
              <div className='container-fluid'>
                <div className='row'>
                  {this._displayCourses('completed')}
                </div>
              </div>
            ) : (
              <div />
            )}
          </TabPanel>
        </Tabs>

        <Modal
          isOpen={showCopyCourseModal}
          onRequestClose={this._closeCopyCourseModal}
          contentLabel='Copy Program Modal'
        >
          <CopyProgramDialog
            closeModal={this._closeCopyCourseModal}
            user={user}
          />
        </Modal>

        <ValidationModal
          validationSuccess={validationSuccess}
          showValidationModal={showValidationModal}
          closeValidationModal={this._closeValidationModal}
          validationMessage={validationMessage}
        />
      </div>
    );
  }

  _toggleCoursesType = ( index ) => {
    if (index === 0 ){
      this._toggleCourses('active', 1)
    } else if (index === 1) {
      this._toggleCourses('completed', 2)
    }
  }

  _searchUpdated = (e) => {
    const term = e.target.value
    if (!term) {
      this.setState({ searchTerm: "" }, () => {
        this._loadPrograms(1);
      });
    } else {
      this.setState({ searchTerm: term }, () => {
        const { searchTerm } = this.state;
        this._loadPrograms(null, searchTerm);
      });
    }
  };

  _toggleCourses = (type, index) => {
    this.setState({ showCourseType: type, selectedIndex: index }, () => {
      if (type === "completed") {
        this._loadPrograms(1, null);
      } else {
        this._loadPrograms(1, null);
      }
    });
  };

  _toggleEnterState = () => {
    setTimeout(
      function () {
        this.setState({ in: true });
      }.bind(this),
      fadeAnimation.FADE_DURATION
    );
  };

  _openAddCourseModal = () => {
    const templatesLoaded = false

    this.setState({
      showAddCourseModal: true,
      templatesLoaded: templatesLoaded,
    })
  }

  _openAddCoursePage = () => {
    this.props.navigate('/programs/new')
  }

  _closeAddCourseModal = () => {
    this.setState({ showAddCourseModal: false, errors: {} });
  };

  _openCopyCourseModal = () => {
    this.setState({
      showCopyCourseModal: true,
    })
  }

  _closeCopyCourseModal = () => {
    this.setState({ showCopyCourseModal: false, errors: {} })
  }

  _toggleValidationModal = (success, message) => {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success,
    });
  };

  _closeValidationModal = () => {
    this.setState({
      showValidationModal: false,
    });
  };

  performCourseAction = (course) => {
    if (this.state.createdCourseId > -1) {
      return this.props.course_actions.updateCourse({ ...course, id: this.state.createdCourseId })
    }
    return this.props.course_actions.createCourse(course)
  }

  performCourseFromTemplateAction = (template_id, course) => {
    if (this.state.createdCourseId > -1) {
      return this.props.course_actions.updateCourse({ ...course, id: this.state.createdCourseId })
    }
    return this.props.course_actions.createCourseFromTemplate(template_id, course)
  }

  _addCourse = (createCourse) => {
    const { course_actions, schedule_actions, courses, navigate } = this.props;

    if (createCourse) {
      this.setState({ createClassLoader: true })
      this.performCourseAction(createCourse).then((response) => {
        this.setState({ createdCourseId: response.course.id })
        if(createCourse.schedule?.scheduleSessions.length) {
          schedule_actions.createSchedule({...createCourse.schedule, courseId: response.course.id}).then(() => {
            course_actions.updateCourseDuration(response.course.id)
            this._toggleValidationModal(
              true,
              "You have successfully created a new class"
            )
            setTimeout(() => {
              navigate(`/programs/${response.course.id}`)
              this.setState({
                showAddCourseModal: false,
                showCopyCourseModal: false,
                createClassLoader: false,
              })
            }, 2000)
          })
          .catch((err) => {this.setState({ createClassLoader: false, errors: err.response.data.errors }) })
        }
        else {
          this._toggleValidationModal(
            true,
            "You have successfully created a new class"
          )
          setTimeout(() => {
            navigate(`/programs/${response.course.id}`)
            this.setState({
              showAddCourseModal: false,
              showCopyCourseModal: false,
              createClassLoader: false,
            })
          }, 2000)

        }
      }).catch((err) => {
        this.setState({ errors: err.response.data.errors, createClassLoader: false, startStep: '-1' })
      })
    }
  }

  _loadPrograms = (page, searchTerm) => {
    const { user_actions, user } = this.props;
    const { showCourseType, perPage, selectedIndex } = this.state;
    if (showCourseType === "active" && user.type === "Admin") {
      user_actions
        .getUserCourses(user.id, "admin", page, perPage, searchTerm)
        .then(() => {
          this.setState({
            coursesLoaded: true,
            selectedIndex,
            loading: false,
          });
        });
    }

    if (
      showCourseType === 'active' &&
      user.type !== 'Admin'
    ) {
      user_actions
        .getUserCourses(user.communities[0].id, null, page, perPage, searchTerm)
        .then(() => {
          this.setState({
            coursesLoaded: true,
            selectedIndex,
            loading: false,
          });
        });

      this.setState({ completedCoursesLoaded: true, selectedIndex });
    } else if (
      parseInt(selectedIndex, 10) === 2 &&
      user.type !== 'Admin'
    ) {
      user_actions
        .getUserCourses(
          user.communities[0].id,
          "completed",
          page,
          perPage,
          searchTerm
        )
        .then((res) => {
          this.setState({
            completedCourseLoader: false,
          });
        });
    } else if (
      parseInt(selectedIndex, 10) === 3 &&
      user.type !== 'Admin'
    ) {
      user_actions
        .getUserCourses(
          user.communities[0].id,
          "archived",
          page,
          perPage,
          searchTerm
        )
        .then(() => {
          this.setState({
            archivedCoursesLoaded: true,
            selectedIndex,
            loading: false,
          });
        });
    }
  };

  _createCourseFromTemplate = (template_id, course) => {
    const { course_actions, schedule_actions, navigate } = this.props
    if (template_id) {
      this.setState({ createClassLoader: true })

      this.performCourseFromTemplateAction(template_id, course).then((response) => {
        this.setState({ createdCourseId: response.course.id })
        if (course.schedule) {
          schedule_actions.createSchedule({...course.schedule, courseId: response.course.id}).then(() => {
            course_actions.updateCourseDuration(response.course.id)
            this.setState({ createClassLoader: false })
            this._toggleValidationModal(
              true,
              "You have successfully created a new class"
            )
            setTimeout(() => {
              navigate(`/programs/${response.course.id}`)
              this.setState({ showAddCourseModal: false, showCopyCourseModal: false })
            }, 2000)
          })
          .catch((err) => {
            this.setState({ errors: err.response.data.errors, createClassLoader: false })
          })
        } else {
          this.setState({ createClassLoader: false })
          this._toggleValidationModal(
            true,
            "You have successfully created a new class"
          )
          setTimeout(() => {
            navigate(`/programs/${response.course.id}`)
            this.setState({ showAddCourseModal: false, showCopyCourseModal: false })
          }, 2000)
        }
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, createClassLoader: false })
      })
    }
  }

  _createCourseFromProgram = (course_id, course) => {
    const { course_actions, navigate } = this.props
    if (course_id) {
      this.setState({ createClassLoader: true })
      course_actions.createCourseFromProgram(course_id, course).then((response) => {
        this.setState({ createClassLoader: false })
        navigate(`/programs/${response.course.id}`)
        this.setState({ showAddCourseModal: false, showCopyCourseModal: false })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors, createClassLoader: false })
      })
    }
  }

  _filterCourses = (filter) => {
    const { user, completed_courses } = this.props;
    let courses = Object.assign([], this.state.courses);
    if (user.type === "Admin") {
      switch (filter) {
        case "active":
          return courses.filter(
            (course) =>
              !course.archived &&
              !course.is_completed &&
              course.is_template &&
              course.is_default_template
          );
        case "archived":
          return courses.filter(
            (course) =>
              course.archived &&
              course.is_template &&
              course.is_default_template
          );
        default:
          return [];
      }
    } else {
      switch (filter) {
        case "active":
          //return courses.filter(course => !course.archived && !course.is_completed && !course.is_template && !course.is_default_template);
          return courses;

        case "completed":
          //return courses.filter(course => course.is_completed && !course.archived && !course.is_template && !course.is_default_template);
          if (completed_courses && completed_courses.length > 0) {
            return completed_courses;
          } else {
            return [];
          }

        case "archived":
          return courses.filter(
            (course) => course.archived && !course.is_template
          );

        default:
          return;
      }
    }
  };

  _reloadComponent = () => {
    this._forceUpdate();
  };

  _checkIfLoaded = (filter) => {
    const { courses, loading } = this.state;
    const { completed_courses } = this.props;
    switch (filter) {
      case "active":
        return courses && courses.length > 0 && !loading;
      case "completed":
        return completed_courses && completed_courses.length > 0 && !loading;
      case "archived":
        return false;
      default:
        return courses && courses.length > 0 && !loading;
    }
  };

  _displayCourses = (filter) => {
    const { course_actions, user, courses, completed_courses } = this.props;
    const { searchTerm, loading, showCourseType } = this.state;

    const anyActiveCourse = courses && courses.length > 0
    const anyCompletedCourse = completed_courses && completed_courses.length > 0

    if (filter === 'active' && anyActiveCourse || filter === 'completed' && anyCompletedCourse) {
      const filteredCourses = this._filterCourses(showCourseType);
      return filteredCourses.map((course, index) => (
        <div className='col-xl-4 col-lg-6 col-md-6' key={"wrap-" + course.id}>
          <CoachClassCard
            index={index}
            course={course}
            courseActions={course_actions}
            user={user}
            reloadClassesPage={this._reloadComponent}
          />
        </div>
      ));
    } else if (loading) {
      return (
        <div className='' style={{ marginTop: "50px", width: "100%" }}>
          <Loader message='loading active programs' />
        </div>
      );
    } else if (!anyCompletedCourse && !loading && filter === "completed") {
      return (
        <div className='col-6 mx-auto mt-5'>
          <Placeholder
            title={'No programs are completed'}
            icon={"fa-flag"}
            subtext1={'You can view the history of completed programs here.'}
          />
        </div>
      );
    } else if (!anyActiveCourse && !loading && filter === "active") {
      return (
        <div className='col-6 mx-auto mt-5'>
          <Placeholder
            title={'No programs are active'}
            icon={"fa-flag"}
            subtext1={'You currently have no active programs.'}
            subtext2={'Click the button below to add a new program.'}
            buttonText={'Add Program'}
            buttonIcon={"fa-plus"}
            handleClick={this._openAddCoursePage}
          />
        </div>
      );
    }
  };

  _headerActionButton = (showCopyProgramModal) => (
    <div>
      <button id='buildProgramButton' className='btn btn-primary pl-3 pr-3'>
        <div className='d-flex align-items-center'>
          <span className='mr-2'>Build a Program</span>
          <TableSelectDropDownIcon stroke={colors.lightNavy} style={{ marginLeft: '2px' }}/>
        </div>
      </button>
      {!showCopyProgramModal &&
        <UncontrolledPopover
          placement='bottom'
          target='buildProgramButton'
          trigger='legacy'
          popperClassName='program-popover-style'
        >
          <div className='d-flex flex-column build-program-actions'>
            <Link to={'/programs/new'}>Build a Program</Link>
            <p aria-hidden='true' onClick={this._openCopyCourseModal}>Copy Existing Program</p>
          </div>
        </UncontrolledPopover>
      }
    </div>
  )
}

CoachClassesPage.propTypes = {
  courses: PropTypes.array,
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
  }),
  user: PropTypes.shape({
    communities: PropTypes.array,
    type: PropTypes.string,
  }),
  lesson_plans: PropTypes.array,
  navigate: PropTypes.func,
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    courses: state.courses.courses,
    completed_courses: state.courses.completed_courses,
    archived_courses: state.courses.archived_courses,
    template_courses: state.courses.template_courses,
    featured_courses: state.courses.featured_courses,
    pages: state.pages,
    lesson_plans: state.lessonPlans.community_lesson_plans,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    course_actions: bindActionCreators(courseActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    lessonPlan_actions: bindActionCreators(lessonPlanActions, dispatch),
    schedule_actions: bindActionCreators(scheduleActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(CoachClassesPage))
