import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Popover } from 'reactstrap'
import PropTypes from 'prop-types'

import ReportsPopover from './ReportsPopover'

const ProfileHeaderActions = ({
  user,
  onTogglePlayerProgressModal,
  onToggleJourneyModal,
  onToggleLogActionsModal,
  onToggleEditGolferModal,
  onToggleProgramsModal,
}) => {
  const currentUser = useSelector(state => state.user.current_user)
  const [reportDropdown, setReportDropdown] = useState(false)
  const navigate = useNavigate()

  const toggleDropdown = () => setReportDropdown(state => !state)

  const goToFamily = () => {
    const familyId = user.family.id

    navigate(
      `/familydetail/${familyId}`,
      { state: { familyId: familyId } }
    )
  }

  const isCoachInCommunity = currentUser.type === 'Coach' && currentUser.communities[0].id === user.communities[0].id
  const isCurrentUser = currentUser.id === user.id
  const isAdminInFamily = currentUser.type === 'Student' && currentUser.family_admin && currentUser.family.id === user.family.id
  const hasWriteAccess = isCoachInCommunity || isCurrentUser || isAdminInFamily

  return (
    <div className='row container-fluid btn-row animated fadeIn'>
      {isCoachInCommunity && (
        <div className='pull-right hidden-sm-down'>
          {currentUser.user_settings.coach_permission > 1 && (
            <>
              <Button
                type='button'
                id='reports-popover'
                onClick={toggleDropdown}
                className='btn btn-primary edit-btn'
              >
                <i className='fa fa-book' />
              </Button>
              <Popover
                placement='bottom'
                isOpen={reportDropdown}
                target='reports-popover'
                toggle={toggleDropdown}
              >
                <ReportsPopover
                  toggleDropdown={toggleDropdown}
                  toggleJourneyModal={onToggleJourneyModal}
                  togglePlayerProgressModal={onTogglePlayerProgressModal}
                  fromSticky={false}
                />
              </Popover>
            </>
          )}
        </div>
      )}
      {hasWriteAccess && (
        <>
          <div className='pull-right hidden-sm-down'>
            <button
              className='btn btn-primary edit-btn'
              onClick={onToggleLogActionsModal}
            >
              <i className='fa fa-plus' />
            </button>
          </div>
          <div className='pull-right hidden-sm-down'>
            <button
              onClick={onToggleEditGolferModal}
              className='btn btn-primary edit-btn'
            >
              <i className='fa fa-pencil-square-o' />
            </button>
          </div>
          <div className='pull-right hidden-sm-down'>
            <button
              onClick={goToFamily}
              className='btn btn-primary family-btn'
            >
              <i className='fa fa-group' />
            </button>
          </div>
          <div className='pull-right hidden-sm-down'>
            <button
              onClick={onToggleProgramsModal}
              className='btn btn-primary programs-btn'
            >
              <i className='fa fa-flag' />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

ProfileHeaderActions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    family: PropTypes.shape({
      id: PropTypes.number,
    }),
    communities: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  onTogglePlayerProgressModal: PropTypes.func,
  onToggleJourneyModal: PropTypes.func,
  onToggleLogActionsModal: PropTypes.func,
  onToggleEditGolferModal: PropTypes.func,
  onToggleProgramsModal: PropTypes.func,
}

export default ProfileHeaderActions
