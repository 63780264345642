import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as leaderboardActions from '../../actions/leaderboardActions';
import * as commonActions from '../../actions/commonActions';
import { Tab, Tabs, TabList, TabPanel } from "../common/Tabs";
import moment from 'moment';

import Header from '../common/Header';
import LeaderboardList from './LeaderboardList';
import TabBar from '../common/TabBar';
import './Leaderboard.scss';

class LeaderboardPage extends React.Component{
  constructor(props, context){
    super(props, context);
    this.state = {
      nowTS: moment().unix(),
      weekStartTS: moment().startOf('isoweek').unix(),
      monthStartTS: moment().startOf('month').unix()
    };
  }

  componentWillMount(){
    if(!this.props.web){
      this.props.common_actions.setCurrentTab("Leaderboard");
    }
  }

  displayWeeklyLeaderboardList(){
    return(
      <LeaderboardList
        type={leaderboardActions.TYPE_WEEKLY}
        startTS={this.state.weekStartTS}
        endTS={this.state.nowTS}
        course={this.props.course}
        isWeb={this.props.web}
        />
    );
  }

  displayMonthlyLeaderboardList(){
    return(
      <LeaderboardList
        type={leaderboardActions.TYPE_MONTHLY}
        startTS={this.state.monthStartTS}
        endTS={this.state.nowTS}
        course={this.props.course}
        />
    );
  }

  displayAllTimeLeaderboardList(){
    return(
      <LeaderboardList
        type={leaderboardActions.TYPE_ALL_TIME}
        course={this.props.course}
        />
    );
  }

  renderLeaderboardWeb(){
    return(
       <div id="leaderboardWrapper" className="componentBody leaderboardWrapper-web  mx--15 p-0 px-0">
          <Tabs>
            <TabList className="react-tabs__tab-list row seg-control-tab seg-control-tab-extend mx-0 w-100 primary-color-bg">
              <Tab className="react-tabs__tab title col text-center">Week</Tab>
              <Tab className="react-tabs__tab title col text-center">Month</Tab>
              <Tab className="react-tabs__tab  title col text-center">All Time</Tab>
            </TabList>
            <TabPanel className="seg-control-content px-0  componentBodyInner ">
              {this.displayWeeklyLeaderboardList()}
            </TabPanel>
            <TabPanel className="seg-control-content px-0  componentBodyInner ">
              {this.displayMonthlyLeaderboardList()}
            </TabPanel>
            <TabPanel className="seg-control-content px-0  componentBodyInner ">
              {this.displayAllTimeLeaderboardList()}
            </TabPanel>
          </Tabs>
        </div>
    )
  }

renderLeaderboardMobile(){
  return(
    <div id="LeaderboardPage" className="hasTabBar">
        <Header
          wrapperClass={'LeaderboardPage'}
          title={'Leaderboard'}/>
        <div id="leaderboardWrapper" className="componentBody">
          <Tabs>
            <TabList className="react-tabs__tab-list row seg-control-tab primary-color-bg">
              <Tab className="react-tabs__tab title col text-center">Week</Tab>
              <Tab className="react-tabs__tab title col text-center">Month</Tab>
              <Tab className="react-tabs__tab title col text-center">All Time</Tab>
            </TabList>
            <TabPanel className="seg-control-content iOSscroll componentBodyInner ">
              {this.displayWeeklyLeaderboardList()}
            </TabPanel>
            <TabPanel className="seg-control-content iOSscroll componentBodyInner ">
              {this.displayMonthlyLeaderboardList()}
            </TabPanel>
            <TabPanel className="seg-control-content iOSscroll componentBodyInner ">
              {this.displayAllTimeLeaderboardList()}
            </TabPanel>
          </Tabs>
        </div>
      <TabBar/>
    </div>
  )
}

  render(){
    return(
      <div>
        {this.props.web ? this.renderLeaderboardWeb() : this.renderLeaderboardMobile()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps){
  return {
    ui: state.ui,
    user: state.user
  };
}

function mapDispatchToProps(dispatch){
  return{
    actions: bindActionCreators(leaderboardActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardPage);
