import { combineReducers } from "redux";
import ui from "./uiReducer";
import user from "./userReducer";
import activity from "./activityReducer";
import activities from "./activitiesReducer";
import playTypes from "./playTypeReducer";
import practiceTypes from "./practiceTypeReducer";
import courses from "./coursesReducer";
import drills from "./drillsReducer";
import objectives from "./objectiveReducer";
import ranks from "./ranksReducer";
import coaches from "./coachesReducer";
import comments from "./commentsReducer";
import likes from "./likesReducer";
import socialObjectives from "./socialObjectivesReducer";
import leaderboard from "./leaderboardReducer";
import notifications from "./notificationsReducer";
import users from "./usersReducer";
import invitations from "./invitationsReducer";
import invitation from "./invitationReducer";
import communities from "./communitiesReducer";
import community from "./communityReducer";
import communityStages from "./communityStagesReducer";
import communityFlags from "./communityFlagsReducer";
import announcements from "./announcementsReducer";
import loading from "./loadingReducer";
import families from "./familiesReducer";
import family from "./familyReducer";
import communityStudents from "./communityStudentsReducer";
import students from "./userPreviewReducer";
import familyEnrollments from "./familyEnrollmentsReducer";
import tags from "./tagsReducer";
import subjects from "./subjectsReducer";
import reports from "./reportsReducer";
import goal from "./goalReducer";
import currencies from "./currenciesReducer";
import educations from "./educationsReducer";
import education from "./educationReducer";
import userEducation from "./userEducationReducer";
import resources from "./resourcesReducer";
import resource from "./resourceReducer";
import resourceSections from "./resourceSectionsReducer";
import resourceSection from "./resourceSectionReducer";
import resourceCategories from "./resourceCategoriesReducer";
import recentResources from "./recentResourcesReducer";
import events from "./eventsReducer";
import event from "./eventReducer";
import pages from "./pagesReducer";
import representatives from "./representativesReducer";
import representativeNotes from "./representativeNotesReducer";
import representativeActionTypes from "./representativeActionTypesReducer";
import onboardingNeededAccounts from "./onboardingNeededAccountsReducer";
import flaggedAccounts from "./flaggedAccountsReducer";
import contactPriorityAccounts from "./contactPriorityAccountsReducer";
import accountOverview from "./accountOverviewReducer";
import teamActivity from "./teamActivityReducer";
import userDivision from "./userDivisionReducer";
import leads from "./leadsReducer";
import leadNotes from "./leadNotesReducer";
import views from "./viewsReducer";
import accolades from "./accoladeReducer";
import upsellProducts from "./upsellProductReducer";
import challenges from "./challengesReducer";
import proShop from "./proShopReducer";
import lessonPlans from "./lessonPlanReducer";
import accountEngagement from "./accountEngagementReducer";
import upsellProductUsage from "./upsellProductUsageReducer";
import salesLeads from "./salesLeadReducer";
import keyMetrics from "./keyMetricsReducer";
import skillLevels from "./skillLevelsReducer";
import adminTags from "./adminTagReducer";
import consents from "./consentReducer";
import materials from './materialsReducer'
import programRegistration from './programRegistrationReducer'
import nineHoleEvent from './nineHoleEventReducer'
import programBuilder from './programBuilderReducer'
import programPresentation from './programPresentationReducer'
import series from './seriesReducer'
import dashboard from './dashboardReducer'
import communitySettings from './community-settings'
import communityLandingPage from './communityLandingPageReducer'

import { loadingBarReducer } from "react-redux-loading-bar";

import * as types from "../actions/actionTypes";

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  ui,
  user,
  users,
  activity,
  activities,
  playTypes,
  practiceTypes,
  courses,
  drills,
  objectives,
  socialObjectives,
  comments,
  likes,
  ranks,
  coaches,
  leaderboard,
  notifications,
  communities,
  community,
  communityStages,
  communityFlags,
  announcements,
  invitations,
  invitation,
  loading,
  families,
  family,
  familyEnrollments,
  communityStudents,
  students,
  tags,
  subjects,
  reports,
  goal,
  currencies,
  educations,
  education,
  userEducation,
  resources,
  resource,
  resourceSections,
  resourceSection,
  resourceCategories,
  recentResources,
  events,
  event,
  loadingBar: loadingBarReducer,
  pages,
  representatives,
  representativeNotes,
  representativeActionTypes,
  onboardingNeededAccounts,
  flaggedAccounts,
  contactPriorityAccounts,
  accountOverview,
  teamActivity,
  userDivision,
  leads,
  leadNotes,
  views,
  accolades,
  upsellProducts,
  challenges,
  proShop,
  lessonPlans,
  accountEngagement,
  upsellProductUsage,
  salesLeads,
  keyMetrics,
  skillLevels,
  adminTags,
  consents,
  materials,
  programRegistration,
  nineHoleEvent,
  programBuilder,
  programPresentation,
  series,
  dashboard,
  communitySettings,
  communityLandingPage,
})

const rootReducer = (state, action) => {
  if (action.type === types.SIGN_OUT) {
    state = undefined;
  }

  if (
    action.type !== types.UI_ERROR &&
    action.type !== types.IS_LOADING &&
    action.type !== types.SIGN_OUT
  ) {
    // Set error to {} for any action other than UI_ERROR and IS_LOADING
    state = {
      ...state,
      ui: {
        ...state.ui,
        error: {},
      },
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
