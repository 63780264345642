import React from "react";
// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// import * as userActions from '../../../actions/userActions';
// import * as practiceTypeActions from '../../../actions/practiceTypeActions';

import "../../../assets/styles/components/profile/ProfileStats.scss";
import Placeholder from "../../common/Placeholder";
import ConfirmationModal from "../../common/ConfirmationModal";

import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import Modal from "react-modal";
import moment from "moment";
import DateTime from '../../common/DateTimePicker'
import "chartjs-plugin-annotation";
import CountUp from "react-countup";

import Slider from '../../common/Slider'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

class TrainStats extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmationMessage: "",
      showConfirmationModal: false,
      divisions: [225, 450, 900, 1350, 1800],
      division: "",
      data: [],
      labels: [],
      stokesLost: 0,
      showActivityModal: false,
      date: "",
      putting: 0,
      chipping: 0,
      pitchShots: 0,
      wedges: 0,
      irons: 0,
      woods: 0,
      simulation: 0,
      bunkers: 0,
      activity: {},
      activityId: "",
      userId: "",
      activityDate: moment()
        .hour(12)
        .minute(0)
        .seconds(0)
        .milliseconds(0)
    };

    this.drawTrainingStatisticsChart = this.drawTrainingStatisticsChart.bind(
      this
    );
  }

  // componentDidMount(){
  //   this.fetchProfileStatsWithProps(this.props);
  //
  // }

  componentDidMount() {
    this.drawTrainingStatisticsChart();
  }

  // componentWillReceiveProps(nextProps) {
  //   if(this.props.user && this.props.user.id !== nextProps.user.id) {
  //     this.fetchProfileStatsWithProps(nextProps);
  //   }
  // }
  //
  // fetchProfileStatsWithProps(props) {
  //   props.actions.getUserStats(props.user.id).then(res => {
  //     this.drawTrainingStatisticsChart();
  //   });
  //   if (props.practice_types.length === 0) {
  //     props.practice_type_actions.getPracticeTypes();
  //   }
  //   props.actions.getUserTrainActivities(props.user.id);
  // }

  onPuttingChange = value => {
    this.setState({
      putting: value
    });
  };

  onChippingChange = value => {
    this.setState({
      chipping: value
    });
  };

  onPitchChange = value => {
    this.setState({
      pitchShots: value
    });
  };

  onWedgesChange = value => {
    this.setState({
      wedges: value
    });
  };

  onIronsChange = value => {
    this.setState({
      irons: value
    });
  };

  onWoodsChange = value => {
    this.setState({
      woods: value
    });
  };

  onSimulationChange = value => {
    this.setState({
      simulation: value
    });
  };

  onBunkersChange = value => {
    this.setState({
      bunkers: value
    });
  };

  toggleConfirmationModal = () => {
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
      confirmationMessage: "Are you sure you want to delete this activity?"
    });
  };

  deleteActivity = () => {
    this.setState(
      {
        showActivityModal: false,
        showConfirmationModal: false
      },
      () => {
        this.props.deleteActivity(this.state.activity);
      }
    );
  };

  abbrNum(number, decPlaces) {
    decPlaces = Math.pow(10, decPlaces);

    var abbrev = ["k", "m", "b", "t"];

    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);

      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;

        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }

        number += abbrev[i];
        break;
      }
    }

    return number;
  }

  drawTrainingStatisticsChart() {
    if (this.props.stats && this.props.practiceTypes.length > 0) {
      // And only if we have the proper data
      var data = [];
      var labels = [];
      var bgColors = [];

      for (var i = 0; i < this.props.practiceTypes.length; i++) {
        var type = this.props.practiceTypes[i];
        var typeName = type.name;
        var tempData = this.props.stats.practice_log[typeName];

        labels.push(typeName);
        bgColors.push(type.color_hex);
        data.push(tempData !== undefined ? tempData : 0);
      }

      const chartOptions = {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              afterLabel: function(item, data) {
                return " minutes";
              }
            }
          }
        },
      };
      const doughnutData = {
        labels: labels,
        datasets: [
          {
            // label: 'Personal Stats',
            data: data,
            backgroundColor: bgColors,
            borderWidth: 1
          }
        ]
      };

      return (
        <Doughnut
          data={doughnutData}
          options={chartOptions}
          height={200}
          width={200}
        />
      );
    }
  }

  getTotalTimePracticed() {
    var totalMins = 0;

    for (var key in this.props.stats.practice_log) {
      if (this.props.stats.practice_log.hasOwnProperty(key)) {
        var tmpMins = parseInt(this.props.stats.practice_log[key], 10);
        if (!isNaN(tmpMins)) {
          totalMins += tmpMins;
        }
      }
    }

    return Math.floor(totalMins / 60);
  }

  displayPracticeTypesLegend() {
    if (this.props.practiceTypes) {
      return this.props.practiceTypes.map((obj, index) => (
        <p className="off" style={{ color: obj.color_hex }} key={index}>
          <span className="badge badge-pill">
            {" "}
            <i className="fa fa-circle-o" aria-hidden="true" /> {obj.name}
          </span>
        </p>
      ));
    }
  }

  /*******
  Rows & Table
  *******/

  renderPagination() {
    if (!this.props.trainActivities) {
      return null;
    }
    if (this.props.trainActivitiesPages > 1) {
      return (
        <div className="row d-flex justify-content-flex-end">
          <div className="col-md-6 m-0">
            <button
              className="btn btn-dark m-0 mr-2"
              onClick={() => this.props.handlePaginateClick("previous")}
              disabled={this.props.trainCurrentPage === 1 ? true : false}
            >
              Previous
            </button>
            <button
              className="btn btn-primary m-0"
              onClick={() => this.props.handlePaginateClick("next")}
              disabled={this.props.trainCurrentPage >= this.props.trainActivitiesPages ? true : false}
            >
              Next
            </button>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderPracticeSession(activity, id) {
    if (activity.practice_sessions.length > 0) {
      return activity.practice_sessions.map(practice => {
        if (practice.practice_type.id === id) {
          return `${practice.duration} mins`;
        }
      });
    } else {
      return "---";
    }
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  checkPracticeType = (activity, practiceId) => {
    let practiceTime = 0;
    activity.practice_sessions.forEach(practice => {
      if (practiceId === practice.practice_type.id) {
        practiceTime += practice.duration;
      }
    });
    return practiceTime;
  };

  grabPracticeId = id => {
    let practiceId = -1;
    this.state.activity.practice_sessions.map(practice => {
      if (practice.practice_type.id === id) {
        practiceId = practice.id;
      }

      return null;
    });

    return practiceId;
  };

  activityDateChanged = event => {
    let startDate = moment(event, "MM-DD-YYYY").format("MM-DD-YYYY");
    let setActivityDate = moment(startDate, "MM-DD-YYYY").format();

    this.setState({
      activityDate: setActivityDate
    });
  };

  toggleActivityModal = activity => {
    this.resetForms();
    this.setState({
      showActivityModal: !this.state.showActivityModal,
      activity: activity,
      userId: this.props.user.id,
      activityId: activity.id,
      date: activity.activity_date,
      activityDate: activity.activity_date,
      putting: this.checkPracticeType(activity, 1),
      chipping: this.checkPracticeType(activity, 2),
      pitchShots: this.checkPracticeType(activity, 3),
      wedges: this.checkPracticeType(activity, 4),
      irons: this.checkPracticeType(activity, 5),
      woods: this.checkPracticeType(activity, 6),
      simulation: this.checkPracticeType(activity, 7),
      bunkers: this.checkPracticeType(activity, 8)
    });
  };

  closeActivityModal = () => {
    this.setState({
      showActivityModal: false
    });
  };

  resetForms = () => {
    this.setState({
      userId: "",
      activityId: "",
      date: "",
      putting: "",
      chipping: "",
      pitchShots: "",
      wedges: "",
      irons: "",
      woods: "",
      simulation: "",
      bunkers: "",
      activityDate: moment()
        .hour(12)
        .minute(0)
        .seconds(0)
        .milliseconds(0)
    });
  };

  updateActivity = () => {
    let trainSession = [];
    if (this.state.putting >= 0) {
      trainSession.push({
        id: this.grabPracticeId(1),
        type: 1,
        duration: this.state.putting
      });
    }
    if (this.state.chipping >= 0) {
      trainSession.push({
        id: this.grabPracticeId(2),
        type: 2,
        duration: this.state.chipping
      });
    }
    if (this.state.pitchShots >= 0) {
      trainSession.push({
        id: this.grabPracticeId(3),
        type: 3,
        duration: this.state.pitchShots
      });
    }
    if (this.state.wedges >= 0) {
      trainSession.push({
        id: this.grabPracticeId(4),
        type: 4,
        duration: this.state.wedges
      });
    }
    if (this.state.irons >= 0) {
      trainSession.push({
        id: this.grabPracticeId(5),
        type: 5,
        duration: this.state.irons
      });
    }
    if (this.state.woods >= 0) {
      trainSession.push({
        id: this.grabPracticeId(6),
        type: 6,
        duration: this.state.woods
      });
    }
    if (this.state.simulation >= 0) {
      trainSession.push({
        id: this.grabPracticeId(7),
        type: 7,
        duration: this.state.simulation
      });
    }
    if (this.state.bunkers >= 0) {
      trainSession.push({
        id: this.grabPracticeId(8),
        type: 8,
        duration: this.state.bunkers
      });
    }

    this.props.activityActions
      .updateTrainActivity(this.state, trainSession)
      .then(response => {
        if (response) {
          this.props.userActions.getUserTrainActivities(this.props.user.id)
          this.props.userActions.getUserStats(this.props.user.id)
        }
      });
    this.setState({ showActivityModal: false });
    this.resetForms();
  };

  combineSessions = (activity) => {
    let sessions = activity.practice_sessions ? activity.practice_sessions : []
    activity.logged_drills?.forEach(ld => {
      sessions = [...sessions, ...ld.practice_sessions]
    })
    return sessions
  }

  renderPracticeTypes = activity => {
    const practice_types_array = this.combineSessions(activity)
      .filter(ps => ps.duration > 0)
      .map(practice => practice.practice_type.name)

    return [...new Set(practice_types_array)].join(', ')
  }

  renderTotalPracticeTime = activity => {
    const totalTime = this.combineSessions(activity)
      .reduce((acc, practice) => acc + practice.duration, 0)

    return `${totalTime} mins`
  };

  renderSessions = (activity) => {
    const sessions = this.combineSessions({ ...activity, practice_sessions: [] })
    const disabled = !!activity
    const practice = activity ?
    {
      putting: this.checkPracticeType({ practice_sessions: sessions }, 1),
      chipping: this.checkPracticeType({ practice_sessions: sessions }, 2),
      pitchShots: this.checkPracticeType({ practice_sessions: sessions }, 3),
      wedges: this.checkPracticeType({ practice_sessions: sessions }, 4),
      irons: this.checkPracticeType({ practice_sessions: sessions }, 5),
      woods: this.checkPracticeType({ practice_sessions: sessions }, 6),
      simulation: this.checkPracticeType({ practice_sessions: sessions }, 7),
      bunkers: this.checkPracticeType({ practice_sessions: sessions }, 8)
    }
    :
    {
      putting: this.state.putting,
      chipping: this.state.chipping,
      pitchShots: this.state.pitchShots,
      wedges: this.state.wedges,
      irons: this.state.irons,
      woods: this.state.woods,
      simulation: this.state.simulation,
      bunkers: this.state.bunkers
    }

    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="train-title">
              <p>Putting</p>
              <p className="train-duration-pill">
                {practice.putting} mins
              </p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.putting, 10)}
              onChange={this.onPuttingChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
          <div className="col-md-6">
            <div className="train-title">
              <p>Chipping</p>
              <p className="train-duration-pill">
                {practice.chipping} mins
              </p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.chipping, 10)}
              onChange={this.onChippingChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="train-title">
              <p>Pitch Shots (10-30 yards)</p>
              <p className="train-duration-pill">
                {practice.pitchShots} mins
              </p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.pitchShots, 10)}
              onChange={this.onPitchChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
          <div className="col-md-6">
            <div className="train-title">
              <p>Wedges (30+ yards)</p>
              <p className="train-duration-pill">
                {practice.wedges} mins
              </p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.wedges, 10)}
              onChange={this.onWedgesChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="train-title">
              <p>Full Swing Irons</p>
              <p className="train-duration-pill">{practice.irons} mins</p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.irons, 10)}
              onChange={this.onIronsChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
          <div className="col-md-6">
            <div className="train-title">
              <p>Full Swing Woods</p>
              <p className="train-duration-pill">{practice.woods} mins</p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.woods, 10)}
              onChange={this.onWoodsChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="train-title">
              <p>On-Course Play Simulation</p>
              <p className="train-duration-pill">
                {practice.simulation} mins
              </p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.simulation, 10)}
              onChange={this.onSimulationChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
          <div className="col-md-6">
            <div className="train-title">
              <p>Bunkers</p>
              <p className="train-duration-pill">
                {practice.bunkers} mins
              </p>
            </div>
            <Slider
              min={0}
              max={300}
              step={5}
              value={parseInt(practice.bunkers, 10)}
              onChange={this.onBunkersChange}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }}
              disabled={disabled}
            />
          </div>
        </div>
      </>
    )
  }

  displayActivityModal() {
    let totalTime = 0
    let practice_types_ids = []

    this.state.activity?.logged_drills?.forEach(ld => {
      ld.practice_sessions.filter(ps => ps.duration > 0).forEach(practice => {
        totalTime += practice.duration
        practice_types_ids = [...practice_types_ids, practice.practice_type.id]
      })
    })
    const totalPractices = [... new Set(practice_types_ids)].length

    return (
      <Modal
        isOpen={this.state.showActivityModal}
        onRequestClose={this.closeActivityModal}
        contentLabel="Train Stats"
      >
        <div className="modal-header row">
          <div className="col-11">
            <h5 className="modal-title display-4 uppercase detail-title">
              Train Stats | {moment(this.state.date).format("MM-DD-YYYY")}
            </h5>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="close pull-right font-200"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeActivityModal}
            >
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>
        <div className="row d-flex align-items-center flex-column h-100 mt-5">
          <div className="col-md-8 mx-auto">
          {totalPractices > 0 &&
            <div className='row'>
              <div className='info-card col-md-12'>
                <div className='info-text'>
                <i className='fa fa-info-circle pr-3'/>
                {`${totalPractices} of the practice types and ${totalTime} minute${totalTime === 1 ? '' : 's'} of the total time are from Training Activities in the mobile app.
                  To edit that time, this user must use the mobile app and edit this practice session themselves.` }
                </div>
                {/* <div className='logged-drill-sessions'>
                  <p>Train sessions from logged drills</p>
                  {this.renderSessions(this.state.activity)}
                </div> */}
              </div>
            </div>
          }
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="program-activity-title">Activity Date</label>
                  <DateTime
                    name="firstStartDate"
                    value={moment(this.state.activityDate).format("MM-DD-YYYY")}
                    onChange={this.activityDateChanged}
                    dateFormat="MM-DD-YYYY"
                    timeFormat={false}
                    inputProps={{ readOnly: true }}
                    className="date-time"
                    // {...this.state} TODO: Is this needed?
                  />
                </div>
              </div>
            </div>
            {this.renderSessions()}
            <div className="row mt-4">
              <div className="col-md-12 d-flex justify-content-center align-items-center flex-row">
                <button
                  className="btn btn-dark m-2"
                  onClick={this.closeActivityModal}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary m-2"
                  onClick={this.updateActivity}
                >
                  Update
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-warning mt-4 mx-auto"
                onClick={this.toggleConfirmationModal}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderRows() {
    if (!this.props.trainActivities) {
      return null;
    }
    return this.props.trainActivities
      .map(activity => {
        return (
          <tr className="border-bottom" key={activity.id}>
            <td className="hidden-sm-down">
              {moment(activity.activity_date).format("MM-DD-YYYY")}
            </td>
            <td className="hidden-sm-down">
              {this.renderPracticeTypes(activity)}
            </td>
            <td className="hidden-sm-down">
              {this.renderTotalPracticeTime(activity)}
            </td>
            {this.props.current_user === "Coach" ? (
              <td className="hidden-sm-down">
                <button
                  className="btn btn-block btn-primary m-0"
                  onClick={() => this.toggleActivityModal(activity)}
                >
                  View/Edit
                </button>
              </td>
            ) : (
              <td />
            )}

            {/* <div className="row hidden-md-up">
            <div className="col">
              <div className="card mb-1 p-3">
                <h5 className="mt-0 mb-1">Todd Kidler</h5>
                <p className="mt-0 mb-1"><span className="badge primary-background">username</span></p>
                <p className="mt-0 mb-1">Family</p>
                <p className="mt-0 mb-1">date</p>
                  <button className="btn btn-primary btn-small text-white">
                    Edit
                  </button>
              </div>
            </div>
          </div> */}
          </tr>
        );
      });
  }

  renderRoundsTable() {
    return (
      <div className="animated fadeIn">
        {/* <div className="row hidden-md-up">
          <div className="col-6">
            <label className="font-200">
              Last Name
            </label>
          </div>
          <div className="col-6">
            <label className="font-200">
              Join Date
            </label>
          </div>
        </div> */}
        <table className="table w-100 ">
          <thead className="primary-background hidden-sm-down">
            <tr className="text-white font-200">
              <th className="font-200">Date</th>
              <th className="font-200">Practice Types</th>
              <th className="font-200">Total Time</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
        {this.renderPagination()}
      </div>
    );
  }

  displayStats(div) {
    if (this.props.stats && this.props.stats.points) {
      return (
        <div>
          <div className="card mt-15px">
            <h5 className="p-2 ml-3 mt-2 card-header-text">Train Stats</h5>
            <div className="card-block p-2">
              <div className="row">
                <div className="col stats-container text-center">
                  <p className="mt-3 text-center stat-title">
                    <span className="hidden-md-down">Total</span> Practices
                  </p>
                  <div className="">
                    <div className="count-container-no-icon">
                      {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                      <CountUp
                        className="md-percentage-count"
                        start={0}
                        end={this.props.stats.practices}
                        duration={2.75}
                        useEasing={true}
                        useGrouping={true}
                      />
                    </div>
                    <div className="empty-circle" />
                  </div>
                </div>
                <div className="col stats-container text-center">
                  <p className="mt-3 text-center stat-title">
                    <span className="hidden-md-down">Total</span> Hours{" "}
                    <span className="hidden-md-down">Practiced</span>
                  </p>
                  <div className="">
                    <div className="count-container-no-icon">
                      {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                      <CountUp
                        className="md-percentage-count"
                        start={0}
                        end={Number(this.getTotalTimePracticed())}
                        duration={2.75}
                        useEasing={true}
                        useGrouping={true}
                      />
                    </div>
                    <div className="empty-circle" />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row pt-5 pb-3 train-stats-row">
                <div className="col-2 hidden-md-down" />
                <div className="col-5">
                  {/*<canvas id="myChart" data-drawn="false" width="200" height="200"></canvas>*/}
                  {this.drawTrainingStatisticsChart()}
                </div>
                <div className="col-5stat-badges-wrap">
                  <div className="stat-badges">
                    {this.displayPracticeTypesLegend()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-15px">
            <h5 className="p-2 ml-3 mt-2 card-header-text">Practice List</h5>
            <div className="card-block p-2">
              <div>
                <main className="padding-base">{this.renderRoundsTable()}</main>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.user.points === 0) {
      return (
        <div className="card p-0">
          <h5 className="p-2 ml-3 mt-2 card-header-text">Train Stats</h5>
          <div className="card-block p-2">
            <Placeholder
              title={"No Train Stats Yet..."}
              icon={"fa-bar-chart"}
              subtext1={
                "Download the Op36 Golf App to begin logging your first actions"
              }
              subtext2={"Not sure how to get started on your journey?"}
              subtext3={"Reach out to your coach to get started"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <h3 className="text-center center-block mx-auto mt-5">
          <i
            className="fa fa-circle-o-notch fa-spin"
            style={{ fontSize: "55px" }}
          />
        </h3>
      );
    }
  }

  render() {
    return (
      <div id="profileStats" className="hasTabBar p-0">
        {this.displayStats()}
        {this.displayActivityModal()}
        <ConfirmationModal
          showConfirmationModal={this.state.showConfirmationModal}
          closeConfirmationModal={this.toggleConfirmationModal}
          confirmationMessage={this.state.confirmationMessage}
          confirmationDenied={this.toggleConfirmationModal}
          confirmationProceed={this.deleteActivity}
          loader={this.state.deleteLoader}
        />
      </div>
    );
  }
}

// function mapStateToProps(state, ownProps){
//   return {
//     ui: state.ui,
//     stats: state.user.user_stats,
//     ranks: state.ranks,
//     practice_types: state.practiceTypes,
//     actvities: state.user.user_activities,
//     train_activities: state.user.user_train_activities,
//   };
// }
//
// function mapDispatchToProps(dispatch){
//   return{
//     actions: bindActionCreators(userActions, dispatch),
//     practice_type_actions: bindActionCreators(practiceTypeActions, dispatch)
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(TrainStats);

export default TrainStats;
