import PropTypes from 'prop-types'
import PackageSelect from '../../classes/ProgramBuilder/Pricing/PackageSelect'

import './ClassPickerCard.scss'

const ClassPickerCard = ({ checked, course, onClick, handleAddPackage, handleDeletePackage, errors }) => (
  <div className='class-picker-wrapper'>
    <div className='d-flex'>
      <div className='d-flex align-items-center check-box-wrapper'>
        <div
          role='checkbox'
          tabIndex='0'
          aria-checked='false'
          className={`check-box ${checked && 'checked'}`}
          onClick={() => onClick(checked, course.id)}
          onKeyDown={() => onClick(checked, course.id)}
        >
          {checked && (
            <i className='fa fa-check' aria-hidden='true' />
          )}
        </div>
      </div>
      <div className=''>
        <p className='class-title '>{course.name}</p>
        <p className='mb-0 class-date'>{course.meeting_times}</p>
      </div>
    </div>
    {checked && (
      <div className='package-select-wrapper'>
        <p className='package-select-label'>Select Packages That Include 9 Hole Events</p>
        <PackageSelect
          communityPackages={course.used_packages || course.usedPackages}
          packages={course.selectedPackages || []}
          errors={errors}
          onAddPackage={(pkg) => handleAddPackage(pkg, course)}
          onDeletePackage={(pkg) => handleDeletePackage(pkg, course)}
          noCreateOption
        />
      </div>
    )}
  </div>
)

ClassPickerCard.propTypes = {
  checked: PropTypes.bool,
  course: PropTypes.object,
  onClick: PropTypes.func,
  handleAddPackage: PropTypes.func,
  handleDeletePackage: PropTypes.func,
  errors: PropTypes.object,
}

export default ClassPickerCard
