import PropTypes from 'prop-types'
import clsNames from 'classnames'
import DatePicker from '../DatePicker'
import FieldError from '../FieldError'
import _ from 'lodash'
import moment from 'moment'

import inputStyles from './Input.module.scss'
import './TelephoneInput.scss'
import ReactTelephoneInput from 'react-telephone-input'
import flags from '../../../assets/images/flags.png'

// eslint-disable-next-line complexity
const Input = ({
  id,
  name,
  type,
  classNames,
  styles,
  onChange,
  value,
  error,
  symbol,
  innerRef,
  ...props
}) => (
  <div
    className={clsNames(inputStyles['input-container-wrapper'], classNames?.inputContainerWrapper)}
    style={styles?.inputContainerWrapper}
  >
    <div
      className={clsNames(
        inputStyles['input-container'],
        classNames?.inputContainer,
        {
          [inputStyles['error-input']]: !!error,
          [inputStyles['textarea']]: type === 'textarea',
        }
      )}
      style={styles?.inputContainer}
    >
      {symbol && (
        <label
          htmlFor={id}
          className={clsNames(inputStyles.symbol, classNames?.symbol)}
          style={styles?.symbol}
        >
          {symbol}
        </label>
      )}
      {['url', 'text'].includes(type) && (
        <input
          id={id}
          name={name || `text-input-${id || _.uniqueId()}`}
          type={type}
          value={value}
          onChange={onChange}
          className={clsNames(inputStyles['input'], classNames?.input)}
          style={styles?.input}
          ref={innerRef}
          {...props}
        />
      )}
      {type === 'password' && (
        <input
          id={id}
          name={name || `text-input-${id || _.uniqueId()}`}
          type='password'
          value={value}
          onChange={onChange}
          className={clsNames(inputStyles['input'], classNames?.input)}
          style={styles?.input}
          ref={innerRef}
          {...props}
        />
      )}
      {type === 'number' && (
        <input
          id={id}
          name={name || `number-input-${id || _.uniqueId()}`}
          type='number'
          value={value}
          onChange={onChange}
          onWheel={(e) => { e.target.blur() }}
          className={clsNames(inputStyles['input'], classNames?.input)}
          style={styles?.input}
          ref={innerRef}
          {...props}
        />
      )}
      {type === 'textarea' && (
        <textarea
          id={id}
          name={name || `textarea-${id || _.uniqueId()}`}
          value={value}
          onChange={onChange}
          className={clsNames(inputStyles['input'], inputStyles['textarea'], classNames?.input)}
          style={styles?.input}
          ref={innerRef}
          {...props}
        />
      )}
      {type === 'date' && (
        <DatePicker
          id={id}
          name={name || `datepicker-${id || _.uniqueId()}`}
          type={type}
          selected={value}
          onChange={onChange}
          className={clsNames(inputStyles['input'], classNames?.input)}
          style={styles?.input}
          ref={innerRef}
          {...props}
        />
      )}
      {type === 'time' && (
        <DatePicker
          id={id}
          name={name || `timepicker-${id || _.uniqueId()}`}
          type={type}
          selected={value}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption='time'
          dateFormat='h:mm aa'
          className={clsNames(inputStyles['input'], classNames?.input)}
          style={styles?.input}
          ref={innerRef}
          {...props}
        />
      )}
      {type === 'telephone' && (
        <ReactTelephoneInput
          id={id}
          value={value}
          defaultCountry='us'
          onChange={onChange}
          className={clsNames('tel-input', classNames?.input)}
          style={styles?.input}
          flagsImagePath={flags}
          ref={innerRef}
          {...props}
        />
      )}
      {type === 'color' && (
        <label className={inputStyles['color-wrapper-label']}>
          <input
            id={id}
            type={type}
            name={name || `color-input-${id || _.uniqueId()}`}
            value={value}
            onChange={onChange}
            className={clsNames(inputStyles['color-input'], classNames?.input)}
            style={styles?.input}
            ref={innerRef}
            {...props}
          />
          <span className={clsNames(inputStyles['color-name'], { [inputStyles['placeholder']]: !value })}>
            {value || props.placeholder}
          </span>
        </label>
      )}
    </div>
    {error && <FieldError message={error} />}
  </div>
)

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  classNames: PropTypes.shape({
    inputContainerWrapper: PropTypes.string,
    inputContainer: PropTypes.string,
    symbol: PropTypes.string,
    input: PropTypes.string,
  }),
  styles: PropTypes.shape({
    inputContainerWrapper: PropTypes.object,
    inputContainer: PropTypes.object,
    symbol: PropTypes.object,
    input: PropTypes.object,
  }),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment)]),
  placeholder: PropTypes.string,
  error: PropTypes.string,
  symbol: PropTypes.node,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
}

Input.defaultProps = {
  onChange: () => {},
}

export default Input
