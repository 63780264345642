import React from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../actions/userActions'
import * as familyActions from '../../actions/familyActions'
import * as communitiesActions from '../../actions/communitiesActions'
import * as announcementsActions from '../../actions/announcementsActions'
import * as activityActions from '../../actions/activityActions'
import _ from 'lodash'
import { Wizard, Steps, Step } from 'react-albus'
import { Tab, Tabs, TabList, TabPanel } from '../common/Tabs'
import moment from 'moment'
import SearchInput, { createFilter } from 'react-search-input'
import Placeholder from '../common/Placeholder'
import Loader from '../common/Op36Loader'
import StudentModal from './StudentModal'
import PreviewModal from './PreviewModal'
import ValidationModal from '../common/ValidationModal'
import TextInput from '../common/form/TextInput'
import TextAreaInput from '../common/form/TextAreaInput'
import MyStatefulEditor from '../common/form/MyStatefulEditor'

// Assets
import '../../assets/styles/components/annoucements/CampaignPage.scss'
import coachLogo from '../../assets/images/logos/coach_logo.png'

import defaultProfilePic from '../../assets/images/default_profile.png'
import { withRouting } from '../common/hooks'

const PROGRAM_KEYS_TO_FILTERS = ['name']
const FAMILY_KEYS_TO_FILTERS = ['name', 'email']
const STUDENT_KEYS_TO_FILTERS = ['first_name', 'last_name', 'username']

const EMAIL_TEMPLATES = {
  0: {
    subject: '',
    template: '',
  },
  1: {
    subject: ' - Joins the Operation 36 Golf Network',
    template:
      `<p>
        This year our golf facility has been accepted as one of the few approved
        <a href='https://operation36.golf/' target='_blank'>
          <ins>Operation 36</ins>
        </a> Network locations. &nbsp;The
        <a href='https://operation36.golf/' target='_blank'>
          <ins>Operation 36 Network</ins>
        </a> consists of a group of golf professionals who believe in delivering the highest
         quality player development experience for new and intermediate golfers.
      </p>
      <p>
        By joining the network, we are provided with tools that will allow our team to enhance your families player development experience.
         &nbsp;Each golfer who enrolls in instructional classes will have their own profile in the Op 36 Platform and be added to our private
          golf community. &nbsp;If you are receiving this email, we have already set your family up with an account.
      </p>
      <p>
        This platform will allow us to communicate about active classes, issue/track personal goals, and will allow you to track your playing and
         training stats outside of classes.
      </p>
      <p>
        Our mission is to provide a measurable pathway for the long-term development of our golfers. To learn more about the new tools this year,
         please click the link below to get a sneak peek!
      </p>
      <p>
        <a href='https://operation36.golf/onboarding' target='_blank'>
          <ins>CLICK HERE TO VIEW THE NEW TOOLS</ins>
        </a>
      </p>
      <p>
        <a href='https://operation36.golf/' target='_blank'>
          <ins>CLICK HERE TO LEARN MORE ABOUT THE OPERATION 36 NETWORK</ins>
        </a>
      </p>`,
  },
  2: {
    subject: 'How to use the Operation 36 Golf Mobile & Web App',
    template:
      `<p>
        This year we will be using the Operation 36 Web and Mobile Apps to help provide our golfers with a better player development experience.
      </p>
      <p>
        We will cover an overview of how to use the app in the first day of classes. &nbsp;Before then, we highly recommend spending 5 minutes to
        watch the following videos to learn how to get the most out of the app to enhance your family's player development experience.
      </p>
      <p>
        <a href='https://operation36.golf/onboarding' target='_blank'>
          <ins>CLICK HERE TO LEARN HOW TO USE THE APP</ins>
        </a>
      </p>
      <p>
        Have more questions? &nbsp;You can visit
        <a href='https://operation36.golf/' target='_blank'>
          <ins>Operation36.Golf</ins>
        </a> to live chat with the organization, or feel free to ask our coaching staff questions and we would be glad to help you get set up.
      </p>`,
  },
  3: {
    subject: 'The first week is here! Important Golf Class Announcements',
    template:
      `<p>
        Parents,
      </p>
      <p>
        We are all very excited for the first week of class! &nbsp;You are receiving this email because your junior is successfully enrolled in one of
         our Operation 36 Academy Classes. Below is some more information to help everyone prepare for the semester. Please take a few minutes to read
          over everything and let me know if you have any questions.
      </p>
      <p>
        The Spring Semester consists of a &nbsp;<strong>[INSERT WEEK LENGTH SEMESTER]</strong> semester.
      </p>
      <ul>
        <li>Semester Start: <strong>[INSERT START DAY]&nbsp;</strong></li>
        <li>Semester End: <strong>[INSERT LAST DAY]</strong></li>
        <li>Class Day &amp; Start Time: <strong>[INSERT DAY AND TIME]</strong></li>
      </ul>
      <p>
        We ask that students try to arrive 10-15 minutes before class starts to get organized for the first day.
        &nbsp;Some items that may be helpful to know:
      </p>
      <p>
        <strong>MEETING LOCATION</strong> - Our meeting location will be on the Practice Putting Green. You will see an A-Frame Whiteboard where we
         set up for class each day. For the first week, I would love to get to meet everyone, but in the future you can just pull up and let the
          juniors out at the bag drop and they can walk up to us.
      </p>
      <p>
        <strong>WEATHER PLANS</strong> - If it rains the first week, we have plenty to do indoors! &nbsp;Thus, we will end at our normal time. &nbsp;
        If there is inclement weather throughout the semester, we will make a call as soon as we can on whether we will have class or not.
        An email will be sent by <strong>[INSERT TIME]</strong> to give you enough time to plan ahead if class is canceled. If we do not have class,
        there will be makeup opportunities throughout the semester that will be held on <strong>[INSERT MAKEUP DATES]</strong>.
      </p>
      <p>
        <strong>CLOTHING/ATTIRE</strong> - We must abide by the club rules for attire guidelines. Juniors must wear a collared shirt and it must be
        tucked in. We do not require a collared shirt for young ladies unless it is a tank top. For bottoms, the juniors cannot wear denim or cargo
         pants. If you are looking for some apparel, visit the pro-shop. Also, sneakers or spikeless golf shoes are preferred for class as the greens
          are delicate in this extreme heat. Please don't hesitate to reach out if you have any questions.
      </p>
      <p>
        <strong>ITEMS TO BRING EACH WEEK</strong> - Below are the items we ask that the juniors try to have each week.
      </p>
      <ol>
        <li>Operation 36 Progress Tag and Pouch - &nbsp;The juniors will be receiving their learning materials the first week of classes. We will
        attach their progress tracking tag and pouch on their bag the first week of class, so as long as they bring their clubs, we should be all
        set!</li><li>Water Bottle - &nbsp;We are outside almost the entire 60 minutes,
        so it is essential that your junior has a water bottle with them during class. &nbsp;It won't be too bad this month,
        but as it starts to get warmer, this will be essential to ensure our juniors' safety.</li>
        <li>Golf Clubs- &nbsp;When your juniors arrive to class, we will educate them on where to set their clubs each day.
        &nbsp;If your junior does not have golf clubs, that is okay! &nbsp;We recommend that if they do not have a set, wait until the first week so
        the coaches can measure them and give you your best options for ensuring your junior has golf clubs that fit. We will be measuring all the
        juniors the first week to see if their clubs are fit for them. &nbsp;If you do not have clubs, we can send you a US Kids Set that fits them,
        and if you wish to purchase a set, we can place the order in the pro-shop and have it here by the following week.</li>
        <li>Suntan Lotion - &nbsp;We are in the sun almost the entire class, so we recommend having your junior apply sunscreen before class.</li>
        <li>Bug Spray - &nbsp;As the bugs begin to get worse, this may be something you will want your junior to have each week.</li>
      </ol>
      <p>
        <strong>STUDENT ONLY ZONE</strong> - &nbsp;We ask that the class area be just for juniors. Juniors are much more willing to try new things,
        make mistakes and meet new friends when parents are not in sight. &nbsp;Watching from the clubhouse is perfectly okay. &nbsp;If I ever need
        your assistance during the classes, I will be sure to let you know.
      </p>
      <p>
        <strong>CONTACTING ME DURING CLASS</strong> - My personal cell phone is <strong>[INSERT CELL]</strong> if you ever need to get in contact with
         me. &nbsp;I will have my phone on loud so I can check text messages throughout the class breaks. &nbsp;Text messages work best as I do not
         pick up phone calls during classes and only check text messages briefly during breaks to ensure our families aren’t trying to contact us.
         &nbsp;If for some reason you cannot get a hold of me, please call the pro-shop and they will pass on the information.
      </p>
      <p>
        <strong>SUPERVISED PRACTICE AND OP 36 MATCHES</strong> - Some of you are a part of the Players Package, which includes Supervised Practice on
         <strong>[INSERT DATE]</strong> &nbsp;as well as our Operation 36 Events on <strong>[INSERT DATES]</strong>. I will be assembling an email
         list of those in that package and will send you updates next week on that as well. &nbsp;If you are interested in learning more about these
         packages, please let me know.
      </p>
      <p>
        <strong>OPERATION 36 MOBILE &amp; WEB APP</strong>
      </p>
      <p>
        This year we will be using the Operation 36 Mobile &amp; Web Apps to help provide our golfers with a better player development experience.
      </p>
      <p>
        We will cover an overview of how to use the app in the first day of classes. &nbsp;Before then, we highly recommend spending 5 minutes to
        watch the following videos to learn how to get the most out of the app and enhance your family's player development experience.
      </p>
      <p>
        <a href='https://operation36.golf/onboarding' target='_blank'>
          <ins>CLICK HERE TO LEARN HOW TO USE THE APP</ins>
        </a>
      </p>
      <p>
        We are very excited to get started! The 60 minute classes are going to be great, and we look forward to progressing your juniors through
        the Operation 36 Platform. &nbsp;It is going to be a semester of learning and growth, and we can’t wait to get started. &nbsp;Please let me
        know if you have any questions - if not, we will see you and your family this week!
      </p>`,
  },
  4: {
    subject: 'This Week in Golf Class...',
    template:
      '<p>Below is a quick note to update everyone on <strong>[INSERT THE WEEK #]</strong> events:</p><p><strong>ANNOUNCEMENTS</strong></p>' +
      '<p>[INSERT ANNOUNCEMENTS (Op 36 Medalist, Level Passed, Rank Graduates, etc)]</p><p><strong>WEEKLY EVENTS</strong></p>' +
      '<p>[INSERT &nbsp;WEEKLY EVENTS (Pink Week, College Week, etc.)]</p><p><strong>SKILLS THIS WEEK</strong></p>' +
      '<p>This week we are covering the INSERT SKILL NAME. &nbsp;Please take some time with your junior to review these skills under the ' +
      'Curriculum page in the Web or Mobile App.</p><p>It should be a great week. &nbsp;Please shoot me an email if you have any questions!</p>',
  },
  5: {
    subject: 'Class is Cancelled today for [ Enter Class Name ]',
    template:
      `<p>Due to the inclement weather, we are going to cancel class today for the safety of our participants.
      </p><p>We will be hosting a makeup date on <strong>[INSERT MAKEUP POLICY]</strong>.</p>`,
  },
  6: {
    subject: 'To Families Previously Enrolled',
    template:
      `<p>Hello Op36 Families,</p><p>We are excited to start a new season of Operation 36 programming here at
      <strong>[INSERT FACILITY NAME HERE]</strong>. &nbsp;You are receiving this email because your junior was previously enrolled in a past Operation
       36 program here. &nbsp;If you were enrolled in the most recent program, we automatically hold your spot for the next semester unless we are
       given other instructions.&nbsp;</p><p>Juniors who are currently enrolled, or previously enrolled in Operation 36 receive first right of refusal
        before we open it up to new students. Spots fill up quickly each semester and we want to make sure you are able to secure your spot to
        continue your juniors journey in the game of golf.</p><p>We will be opening registration to the rest of the membership/public within the next
        2 weeks. The only action steps you need to take are below:</p><ol>  <li>Email (<strong>[INSERT ADDRESS HERE]</strong>) and let us know if you
        would like a spot for this semester.</li>  <li>Please email us the class day/time that you will be attending. To view available classes,
           please view the Junior Academy Tuition and Schedule Flyer below.</li></ol><p><strong>(INSERT JUNIOR ACADEMY TUITION AND SCHEDULE GOOGLE
             DRIVE LINK)</strong></p><p><em>*****Contact Program Advisor if you need help obtaining this link*****</em></p><p>We are looking forward
              to another great semester. Our students improved by a total of <strong>[INSERT TOTAL STROKES LOST]</strong> shots last semester and we
              can’t wait to keep the momentum going!&nbsp;</p><p><em>*****Find Total Strokes Lost in Reports Tab of Web App -
                 “Community Stats Report”*****</em></p><p>If you have any questions, please let us know!&nbsp;</p>`,
  },
}

class Campaign extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.wizard = React.createRef()
    // const isSafari =
    //   navigator.userAgent.indexOf("Safari") !== -1 &&
    //   navigator.userAgent.indexOf("Chrome") === -1;

    this.state = {
      checked: this.props.checked,
      showModal: false,
      message: '',
      pushMessage: '',
      pushMessageLength: 0,
      subject: '',
      searchProgram: '',
      searchFamily: '',
      searchStudent: '',
      templateValue: '',
      programLoader: false,
      familyLoader: false,
      studentLoader: false,
      emailLoader: false,
      students: [],
      recipients: [],
      isFamily: false,
      // isSafari: isSafari,
      isTemplate: false,
      isEmail: true,
      selectedAllFamily: false,
      showValidationModal: false,
      validationSuccess: false,
      validationMessage: '',
      courses: [],
      messageTypeSelected: '',
    }
  }

  componentDidMount() {
    this.fetchInitialProps()
    this.applySmartToggle()
  }

  componentDidUpdate(prevProps) {
    const oldSmartToggle = prevProps.location?.state?.smartToggle
    const newSmartToggle = this.props.location?.state?.smartToggle

    if (oldSmartToggle === newSmartToggle) {
      return
    }

    this.applySmartToggle()
    this.wizard.current.push('Recipients')
  }

  applySmartToggle = () => {
    const { location } = this.props

    if (_.isEmpty(location.state) || !['email', 'push'].includes(location.state.smartToggle)) {
      return
    }

    const recipientList = location.state.students.map(s => {
      const emails = s.email && !s.familyAdminEmails.includes(s.email) ? [...s.familyAdminEmails, s.email] : s.familyAdminEmails
      const copyStudent = { ...s, emails: emails }
      return copyStudent
    })

    this.setState({
      messageTypeSelected: location.state.smartToggle,
      isEmail: location.state.smartToggle === 'email',
      recipients: recipientList || [],
      courses: [],
    })
  }

  fetchInitialProps = () => {
    this.setState({ programLoader: true })
    this.props.user_actions
      .getAnnouncementCourses(this.props.user.communities[0].id)
      .then(() => {
        this.setState({ programLoader: false })
      })

    this.setState({ familyLoader: true })
    this.props.family_actions
      .getFamilies(this.props.user.communities[0].id)
      .then(() => {
        this.setState({ familyLoader: false })
      })

    this.setState({ studentLoader: true })
    this.props.community_actions
      .getAllStudents(this.props.user.communities[0].id)
      .then(() => {
        this.setState({ studentLoader: false })
      })
  }

  handleStatefulEditorChange = (htmlString) => {
    this.setState({
      message: htmlString,
    })
  }

  onChangePushMessage = (event) => {
    this.setState({
      pushMessage: event.target.value,
      pushMessageLength: event.target.value.length,
    })
  }

  onChangeSubject = (event) => {
    this.setState({ subject: event.target.value })
  }

  onChangeMessage = (event) => {
    this.setState({ message: event.target.value })
  }

  onTemplateChange = (event) => {
    const templateId = parseInt(event.target.value)
    let subject = EMAIL_TEMPLATES[templateId].subject

    if (templateId === 1) {
      subject = this.props.user?.communities[0]?.name + subject
    }

    this.setState(
      {
        subject: subject,
        message: EMAIL_TEMPLATES[templateId].template,
        templateValue: templateId,
        isTemplate: true,
      },
      () => {
        this.setState({
          isTemplate: false,
        })
      }
    )
  }

  toggleSelectedAllFamily = () => {
    this.setState(
      {
        selectedAllFamily: !this.state.selectedAllFamily,
      },
      () => {
        if (this.state.selectedAllFamily) {
          const recipients = [...this.state.recipients]
          this.props.families.map((family) => {
            const familyAdminEmails = family.users.filter(user => user.family_admin).map(user => user.email)
            if (family.users.length === 1) {
              recipients.push(family.users[0])
            } else {
              family.users.filter(user => !user.family_admin).map((user) => {
                const userCopy = { ...user }
                userCopy.emails = userCopy.email ? [...familyAdminEmails, userCopy.email] : familyAdminEmails
                if (recipients.length > 0) {
                  const foundIndex = recipients.findIndex(
                    (recipient) => recipient.id === userCopy.id
                  )
                  if (foundIndex > -1) {
                    recipients[foundIndex] = userCopy
                  } else {
                    recipients.push(userCopy)
                  }
                } else {
                  recipients.push(userCopy)
                }
              })
            }
          })

          this.setState({
            recipients: recipients,
          })
        } else if (!this.state.selectedAllFamily) {
          this.setState({
            recipients: [],
            courses: [],
          })
        }
      }
    )
  }

  isEmail = (index) => {
    if (index === 0) {
      this.setState({
        isEmail: true,
      })
    } else if (index === 1) {
      this.setState({
        isEmail: false,
      })
    }
  }

  messageTypeSelector = (type) => {
    if (type === 'email') {
      this.setState({
        messageTypeSelected: 'email',
        isEmail: true,
      })
    } else if (type === 'push') {
      this.setState({
        messageTypeSelected: 'push',
        isEmail: false,
      })
    }
  }

  sendPressed = () => {
    if (this.state.isEmail) {
      if (
        this.state.subject === '' &&
        this.state.message === '<p><br></p>' &&
        this.state.recipients.length > 0
      ) {
        this.toggleValidationModal(
          false,
          "Please enter a 'Subject & 'Message' for your message"
        )
      } else if (
        this.state.subject === '' &&
        this.state.message === '' &&
        this.state.recipients.length > 0
      ) {
        this.toggleValidationModal(
          false,
          "Please enter a 'Subject & 'Message' for your message"
        )
      } else if (
        this.state.subject === '' &&
        this.state.message !== '' &&
        this.state.recipients.length > 0
      ) {
        this.toggleValidationModal(
          false,
          "Please enter a 'Subject' for your message"
        )
      } else if (
        (this.state.subject !== '' &&
          this.state.message === '' &&
          this.state.recipients.length > 0) ||
        this.state.message === '<p><br></p>'
      ) {
        this.toggleValidationModal(
          false,
          "Please enter a 'Message' for your message"
        )
      } else {
        this.togglePreviewModal()
      }
    } else if (!this.state.isEmail) {
      if (this.state.pushMessage === '' && this.state.courses.length > 0) {
        this.toggleValidationModal(
          false,
          "Please enter a 'Message' for your announcement"
        )
      } else {
        this.togglePreviewModal()
      }
    }
  }

  toggleModal = (students, isFamily) => {
    this.grabStudents(students, isFamily)
    this.setState({ showModal: true })
  }

  togglePreviewModal = () => {
    this.setState({ showPreviewModal: true })
  }

  toggleValidationModal = (success, message) => {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success,
    })
  }

  closeValidationModal = () => {
    if (this.state.validationSuccess) {
      this.props.navigate('/announcements')
    }

    this.setState({
      showValidationModal: false,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  closePreviewModal = () => {
    this.setState({ showPreviewModal: false })
  }

  createMarkup = () => ({ __html: this.state.message })

  renderEditor = () =>
    (
      <MyStatefulEditor
        name='content'
        content={this.state.message}
        onChange={this.handleStatefulEditorChange}
        isTemplate={this.state.isTemplate}
      />
    )


  sendMessage = () => {
    this.setState({
      emailLoader: true,
    })
    this.props.announcement_actions
      .postWebAnnouncement(this.state)
      .then((announcement) => {
        if (!this.state.isEmail) {
          this.props.activity_actions
            .postWebActivityAnnouncement(announcement)
            .then(() => {
              this.toggleValidationModal(
                true,
                'Your push notification and announcement has successfully been sent'
              )
              this.closePreviewModal()
              this.setState({ emailLoader: false })
            })
            .catch(() => {
              this.toggleValidationModal(
                false,
                'Something went wrong, please try again'
              )
              this.setState({
                emailLoader: false,
              })
            })
        } else {
          this.toggleValidationModal(
            true,
            'Your message has been put in the queue to be sent out to your recipients'
          )
          this.closePreviewModal()
          this.setState({ emailLoader: false })
        }
      })
      .catch(() => {
        this.toggleValidationModal(
          false,
          'Something went wrong, please try again'
        )
        this.setState({
          createAnnouncementLoader: false,
          emailLoader: false,
        })
      })
  }

  resetState = () => {
    this.setState({
      searchProgram: '',
      searchFamily: '',
      searchStudent: '',
      programLoader: false,
      familyLoader: false,
      studentLoader: false,
    })
  }

  changeType = (previous) => {
    let messageType = ''
    if (this.state.messageTypeSelected === 'push') {
      messageType = 'email'
    }
    if (this.state.messageTypeSelected === 'email') {
      messageType = 'push'
    }
    this.setState(
      {
        isEmail: !this.state.isEmail,
        messageTypeSelected: messageType,
        recipients: [],
        courses: [],
      },
      () => {
        previous()
      }
    )
  }

  loadTabs = (index) => {
    this.resetState()
    if (this.props.courses.length === 0 && index === 0) {
      this.setState({ programLoader: true })
      this.props.user_actions
        .getAnnouncementCourses(this.props.user.communities[0].id)
        .then(() => {
          this.setState({ programLoader: false })
        })
    } else if (this.props.families.length === 0 && index === 1) {
      this.setState({ familyLoader: true })
      this.props.family_actions
        .getFamilies(this.props.user.communities[0].id)
        .then(() => {
          this.setState({ familyLoader: false })
        })
    } else if (this.props.community_students.length === 0 && index === 2) {
      this.setState({ studentLoader: true })
      this.props.community_actions
        .getAllStudents(this.props.user.communities[0].id)
        .then(() => {
          this.setState({ studentLoader: false })
        })
    }
  }

  grabStudents = (students, isFamily) => {
    this.setState(
      {
        students: [],
        isFamily: isFamily,
      },
      () => {
        const studentArray = [...this.state.students]
        if (isFamily) {
          students.map((student) => {
            if (!student.family_admin) {
              studentArray.push(student)
            } else {
              return null
            }
          })
        } else {
          students.map((student) => {
            studentArray.push(student.user)
          })
        }

        this.setState({
          students: studentArray,
        })
      }
    )
  }

  searchPrograms = (term) => {
    this.setState({ searchProgram: term })
  }

  searchFamilies = (term) => {
    this.setState({ searchFamily: term })
  }

  searchStudents = (term) => {
    this.setState({ searchStudent: term })
  }

  calculateStudentCount = (students) => {
    let studentCount = 0
    students.map((student) => {
      if (!student.family_admin) {
        studentCount += 1
      }
    })

    return studentCount
  }

  deleteRecipient = (recipient) => {
    const recipientList = [...this.state.recipients]
    const courses = [...this.state.courses]
    const foundIndex = recipientList.findIndex(
      (user) => user.id === recipient.id
    )
    const foundCourseIndex = courses.findIndex(
      (course) => course.id === recipient.id
    )

    if (foundIndex !== -1) {
      recipientList.splice(foundIndex, 1)
    }

    if (foundCourseIndex !== -1) {
      courses.splice(foundCourseIndex, 1)
    }

    if (this.state.messageTypeSelected === 'push') {
      this.setState({
        recipients: recipientList,
        courses: courses,
      })
    } else {
      this.setState({
        courses: [],
        recipients: recipientList,
      })
    }
  }

  addRecipientsByProgram = (course) => {
    const recipientList = [...this.state.recipients]
    const courses = [...this.state.courses]

    if (this.state.messageTypeSelected === 'email') {
      courses.push(course)
      course.enrollments.map((enrollment) => {
        const student = enrollment.user.family.users.find(user => user.id === enrollment.user_id)
        const familyAdminEmails = enrollment.user.family.users.filter(user => user.family_admin && user.id !== student.id).map(user => user.email)
        const copyStudent = { ...student }
        copyStudent.emails = copyStudent.email ? [...familyAdminEmails, copyStudent.email] : familyAdminEmails
        if (recipientList.length > 0) {
          const foundIndex = recipientList.findIndex(
            (recipient) => recipient.id === copyStudent.id
          )
          if (foundIndex > -1) {
            recipientList[foundIndex] = copyStudent
          } else {
            recipientList.push(copyStudent)
          }
        } else {
          recipientList.push(copyStudent)
        }
      })
    } else {
      if (courses.length > 0) {
        const foundIndex = courses.findIndex(
          (c) => c.id === course.id
        )
        if (foundIndex > -1) {
          courses[foundIndex] = course
        } else {
          courses.push(course)
        }
      } else {
        courses.push(course)
      }
    }

    this.setState({
      recipients: recipientList,
      courses: courses,
    })
  }

  addRecipientsByFamily = (recipients) => {
    const recipientList = [...this.state.recipients]
    const familyAdminEmails = recipients.filter(user => user.family_admin).map(user => user.email)
    if (recipients.length === 1) {
      recipientList.push(recipients[0])
    } else {
      recipients.filter(user => !user.family_admin).map((user) => {
        const userCopy = { ...user }
        userCopy.emails = userCopy.email ? [...familyAdminEmails, userCopy.email] : familyAdminEmails
        if (recipientList.length > 0) {
          const foundIndex = recipientList.findIndex(
            (recipient) => recipient.id === userCopy.id
          )
          if (foundIndex > -1) {
            recipientList[foundIndex] = userCopy
          } else {
            recipientList.push(userCopy)
          }
        } else {
          recipientList.push(userCopy)
        }
      })
    }

    this.setState({
      recipients: recipientList,
    })
  }

  addRecipientByStudent = (recipient) => {
    const recipientList = [...this.state.recipients]

    if (recipient.family_admin) {
      recipientList.push(recipient)
    } else {
      const familyAdminEmails = recipient.family.users.filter(user => user.family_admin).map(user => user.email)
      const userCopy = { ...recipient }
      userCopy.emails = userCopy.email ? [...familyAdminEmails, userCopy.email] : familyAdminEmails
      if (recipientList.length > 0) {
        const foundIndex = recipientList.findIndex(
          (recipient) => recipient.id === userCopy.id
        )
        if (foundIndex > -1) {
          recipientList[foundIndex] = userCopy
        } else {
          recipientList.push(userCopy)
        }
      } else {
        recipientList.push(userCopy)
      }
    }

    this.setState({
      recipients: recipientList,
    })
  }

  displayRecipients = () => {
    if (this.state.messageTypeSelected === 'email') {
      return this.state.recipients.map((user, index) => (
        <div className='border-bottom p-1 w-100 my-1' key={`recipient-${index}`}>
          <div className='row'>
            <div className='col-1 d-flex justify-content-center align-items-center'>
              <i className='fas fa text-success p-1 fa-check-circle' />
            </div>
            <div className='col-10 pl-0 pr-0'>
              <h6 className='my-0'>{`${user.first_name || user.firstName} ${user.last_name || user.lastName}`}</h6>
              <small className='my-0'>{`${
                user.emails ? user.emails.join(', ') : user.email
              }`}</small>
            </div>
            <div
              aria-hidden='true'
              className='col-1 d-flex align-items-center'
              onClick={() => this.deleteRecipient(user, index)}
            >
              <i className='fas fa p-1 fa-minus-circle recipient-delete-icon' />
            </div>
          </div>
        </div>
      ))
    } else {
      return this.state.courses.map((course, index) => {
        if (course.enrollments.length > 0) {
          return (
            <div
              className='card p-2 w-100 my-3 d-flex justify-content-between flex-row animated fadeIn'
              key={course.id}
            >
              <div>
                <h5>{course.name}</h5>
                <small>{`${moment(course.start_date).format(
                  'MM-DD-YYYY'
                )} - ${moment(course.end_date).format('MM-DD-YYYY')}`}</small>
                <small>
                  <button
                    className='btn-link no-bg-text-btn text-primary ml-2'
                    onClick={() => this.toggleModal(course.enrollments, false)}
                    disabled={course.enrollments.length === 0}
                  >
                    {`${course.enrollments.length} Students`}{' '}
                  </button>
                </small>
              </div>
              <button
                className='btn add-recipient-button'
                type='submit'
                onClick={() => this.deleteRecipient(course, index)}
              >
                <i
                  className='fa fa-minus-circle recipient-delete-icon'
                  aria-hidden='true'
                />
              </button>
            </div>
          )
        } else {
          return null
        }
      })
    }
  }

  displayPrograms = () => {
    if (this.state.programLoader) {
      return (
        <div className='' style={{ marginTop: '50px', width: '100%' }}>
          <Loader message='loading classes' />
        </div>
      )
    }
    if (this.props.courses) {
      const filteredPrograms = this.props.courses.filter(
        createFilter(this.state.searchProgram, PROGRAM_KEYS_TO_FILTERS)
      )
      if (filteredPrograms.length === 0 && this.props.courses.length > 0) {
        return (
          <Placeholder
            title={'No results found from the search'}
            icon={'fa-search'}
            subtext1={'Please try to search again for the class'}
          />
        )
      }
      if (this.props.courses.length === 0) {
        return (
          <Placeholder
            title={'No Courses Are Available'}
            icon={'fa-clipboard'}
            subtext1={"Go to 'Classes' to get started"}
          />
        )
      }
      return filteredPrograms.map((course) => {
        if (course.enrollments.length > 0) {
          return (
            <div
              className='border-bottom p-2 w-100 my-3 d-flex justify-content-between flex-row animated fadeIn'
              key={course.id}
            >
              <div>
                <h5>{course.name}</h5>
                <small>{`${moment(course.start_date).format(
                  'MM-DD-YYYY'
                )} - ${moment(course.end_date).format('MM-DD-YYYY')}`}</small>
                <small>
                  <button
                    className='btn-link no-bg-text-btn text-primary ml-2'
                    onClick={() => this.toggleModal(course.enrollments, false)}
                    disabled={course.enrollments.length === 0}
                  >
                    {`${course.enrollments.length} Students`}{' '}
                  </button>
                </small>
              </div>
              <button
                className='btn add-recipient-button'
                type='submit'
                onClick={() => this.addRecipientsByProgram(course)}
              >
                <i
                  className='fa fa-plus add-recipient-icon'
                  aria-hidden='true'
                />
              </button>
            </div>
          )
        } else {
          return null
        }
      })
    }
  }

  displayFamilies = () => {
    if (this.state.familyLoader) {
      return (
        <div
          className=''
          style={{ marginTop: '-32%', marginBottom: '50px', width: '100%' }}
        >
          <Loader message='loading families' />
        </div>
      )
    }
    if (this.props.families) {
      const filteredFamilies = this.props.families.filter(
        createFilter(this.state.searchFamily, FAMILY_KEYS_TO_FILTERS)
      )
      if (this.props.families.length === 0) {
        return (
          <Placeholder
            title={'No Families Are Available'}
            icon={'fa-users'}
            subtext1={"Go to 'Families' to get started"}
          />
        )
      }
      if (filteredFamilies.length === 0 && this.props.families.length > 0) {
        return (
          <Placeholder
            title={'No results found from the search'}
            icon={'fa-search'}
            subtext1={'Please try to search again for the family'}
          />
        )
      }
      return filteredFamilies.map((family) => (
        <div
          className='border-bottom p-2 w-100 my-3 d-flex justify-content-between flex-row animated fadeIn'
          key={family.id}
        >
          <div>
            <h5>{family.name}</h5>
            <small>
              <button
                className='btn-link no-bg-text-btn text-primary'
                onClick={() => this.toggleModal(family.users, true)}
                disabled={family.users.length <= 1}
              >
                {`${this.calculateStudentCount(family.users)} Students`}
              </button>
            </small>
          </div>
          <button
            className='btn add-recipient-button'
            type='submit'
            onClick={() => this.addRecipientsByFamily(family.users)}
          >
            <i className='fa fa-plus add-recipient-icon' aria-hidden='true' />
          </button>
        </div>
      ))
    }
  }

  displayStudents = () => {
    if (this.state.studentLoader) {
      return (
        <div
          className=''
          style={{ marginTop: '-32%', marginBottom: '50px', width: '100%' }}
        >
          <Loader message='loading students' />
        </div>
      )
    }
    if (this.props.community_students) {
      const filteredStudents = this.props.community_students.filter(
        createFilter(this.state.searchStudent, STUDENT_KEYS_TO_FILTERS)
      )
      if (this.props.community_students.length === 0) {
        return (
          <Placeholder
            title={'No Students Are Available'}
            icon={'fa-user'}
            subtext1={"Go to 'Families'"}
            subtext2={"Click on a 'Family'"}
            subtext3={'Start adding students'}
          />
        )
      }
      if (
        filteredStudents.length === 0 &&
        this.props.community_students.length > 0
      ) {
        return (
          <Placeholder
            title={'No results found from the search'}
            icon={'fa-search'}
            subtext1={'Please try to search again for the student'}
          />
        )
      }
      return filteredStudents.map((student) => (
        <div
          className='border-bottom p-2 w-100 my-3 d-flex justify-content-between flex-row animated fadeIn'
          key={student.id}
        >
          <div className='row w-100'>
            <div className='col-2 align-items-center'>
              <div className='round-image-wrapper'>
                <img
                  className='round-image'
                  src={
                    student.avatar
                      ? student.avatar.file_url
                      : defaultProfilePic
                  }
                  alt='Student Avatar'
                />
              </div>
            </div>
            <div className='col-9'>
              <h5>{`${student.first_name} ${student.last_name}`}</h5>
              <small>
                {student.username
                  ? `${student.username}`
                  : `${student.email}`}
              </small>
            </div>
            <div className='col-1 align-items-flex-end d-flex justify-content-center flex-column'>
              <button
                className='btn add-recipient-button'
                type='submit'
                onClick={() =>
                  this.addRecipientByStudent(student)
                }
              >
                <i
                  className='fa fa-plus add-recipient-icon'
                  aria-hidden='true'
                />
              </button>
            </div>
          </div>
        </div>
      ))
    }
  }

  render() { // eslint-disable-line max-lines-per-function
    const { isEmail, messageTypeSelected } = this.state
    return (
      <div id='CampaignPage'>
        <Wizard ref={this.wizard}>
          <Steps>
            <Step
              id='Template'
              render={({ next }) => (
                <div className='row d-flex full-height flex-column justify-content-between'>
                  <div className='bg-white w-100 py-4 message-page-header'>
                    <div className='row flex-column animated fadeInDown'>
                      <strong className='d-block'>
                        <h1 className='text-center text-primary display-3'>
                          Please select your message type
                        </h1>
                      </strong>
                      <h4 className='text-center'>
                        Choose to send as an email or post an announcement
                      </h4>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='row px-md-5'>
                      <div className='col-md-6'>
                        <div className='container'>
                          <button
                            className={`card message-template-card ${
                              this.state.messageTypeSelected === 'email'
                                ? 'active-template-card'
                                : ''
                            }`}
                            onClick={
                              isEmail && messageTypeSelected === 'email'
                                ? next()
                                : () => this.messageTypeSelector('email', next)
                            }
                          >
                            <i
                              className='fa fa-envelope-o message-template-icon'
                              aria-hidden='true'
                            />
                            <h3 className='message-template-title'>Email</h3>
                            <h5 className='message-template-desc'>
                              Send a descriptive message to inform your members
                            </h5>
                          </button>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='container'>
                          <button
                            className={`card message-template-card ${
                              this.state.messageTypeSelected === 'push'
                                ? 'active-template-card'
                                : ''
                            }`}
                            onClick={
                              !isEmail && messageTypeSelected === 'push'
                                ? next()
                                : () => this.messageTypeSelector('push', next)
                            }
                          >
                            <i
                              className='fa fa-bullhorn message-template-icon'
                              aria-hidden='true'
                            />
                            <h3 className='message-template-title'>
                              Post Announcement
                            </h3>
                            <h5 className='message-template-desc'>
                              Send a quick alert to the feed of classes only
                            </h5>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Step
              id='Recipients'
              render={({ next, previous }) => ( // eslint-disable-line max-lines-per-function
                <div className='row d-flex full-height flex-column justify-content-between'>
                  <div className='bg-white w-100 py-4 message-page-header'>
                    <div className='row flex-column animated fadeInDown'>
                      <strong className='d-block'>
                        <h1 className='text-center text-primary display-3'>
                          {`Choose Your ${this.state.isEmail ? 'Email' : 'Announcement'} Recipients`}
                        </h1>
                      </strong>
                      <h4 className='text-center'>{`Hi ${
                        this.props.user.first_name
                      }! Who would you like to send ${
                        this.state.messageTypeSelected === 'email'
                          ? 'an email'
                          : 'an announcement'
                      } to?`}</h4>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='row px-md-5'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          {this.state.messageTypeSelected === 'email' ? (
                            <Tabs onSelect={(e) => this.loadTabs(e)}>
                              <TabList className='d-flex justify-content-center'>
                                <Tab>Add a Class</Tab>
                                <Tab>Add a Family</Tab>
                                <Tab>Add a Student</Tab>
                              </TabList>

                              <TabPanel className='bg-white search-result-container'>
                                <div className='border p-2'>
                                  <div className='row my-4'>
                                    <div className='col-12'>
                                      <div className='header-container'>
                                        <h3>Classes</h3>
                                        <div className='search-container'>
                                          <i
                                            className='fa fa-search search-icon'
                                            style={{ color: 'lightgrey' }}
                                          />
                                          <SearchInput
                                            className='search-input'
                                            onChange={this.searchPrograms}
                                            style={{
                                              border: '1px solid lightgrey',
                                              borderRadius: '25px',
                                              paddingLeft: '10px',
                                              paddingRight: '10px',
                                              width: '250px',
                                            }}
                                            placeholder='Search Classes'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='campaign-result-container container border'>
                                    {this.displayPrograms()}
                                  </div>
                                </div>
                              </TabPanel>

                              <TabPanel className='bg-white search-result-container'>
                                <div className='border p-2'>
                                  <div className='row my-4'>
                                    <div className='col-12'>
                                      <div className='header-container'>
                                        <h3>Families</h3>
                                        {!this.state.familyLoader ? (
                                          <div className='toggle-container animated fadeIn'>
                                            {this.state.selectedAllFamily ? (
                                              <i
                                                className='fa fa-toggle-on family-toggleon-icon'
                                                style={{ color: '#FF6633' }}
                                                aria-hidden='true'
                                                onClick={this.toggleSelectedAllFamily}
                                              />
                                            ) : (
                                              <i
                                                className='fa fa-toggle-off family-toggleoff-icon'
                                                style={{ color: 'lightgrey' }}
                                                aria-hidden='true'
                                                onClick={this.toggleSelectedAllFamily}
                                              />
                                            )}

                                            <p>Select All</p>
                                          </div>
                                        ) : null}

                                        <div className='search-container'>
                                          <i
                                            className='fa fa-search search-icon'
                                            style={{ color: 'lightgrey' }}
                                          />
                                          <SearchInput
                                            className='search-input'
                                            onChange={this.searchFamilies}
                                            style={{
                                              border: '1px solid lightgrey',
                                              borderRadius: '25px',
                                              paddingLeft: '10px',
                                              paddingRight: '10px',
                                              width: '250px',
                                            }}
                                            placeholder='Search Families'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='campaign-result-container container border'>
                                    {this.displayFamilies()}
                                  </div>
                                </div>
                              </TabPanel>

                              <TabPanel className='bg-white search-result-container'>
                                <div className='border p-2'>
                                  <div className='row my-4'>
                                    <div className='col-12'>
                                      <div className='header-container'>
                                        <h3>Students</h3>
                                        <div className='search-container'>
                                          <i
                                            className='fa fa-search search-icon'
                                            style={{ color: 'lightgrey' }}
                                          />
                                          <SearchInput
                                            className='search-input'
                                            onChange={this.searchStudents}
                                            style={{
                                              border: '1px solid lightgrey',
                                              borderRadius: '25px',
                                              paddingLeft: '10px',
                                              paddingRight: '10px',
                                              width: '250px',
                                            }}
                                            placeholder='Search Students'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='campaign-result-container container border'>
                                    {this.displayStudents()}
                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          ) : (
                            <div className='bg-white search-result-container border p-2'>
                              <div className='row my-4'>
                                <div className='col-12'>
                                  <div className='header-container'>
                                    <h3>Classes</h3>
                                    <div className='search-container'>
                                      <i
                                        className='fa fa-search search-icon'
                                        style={{ color: 'lightgrey' }}
                                      />
                                      <SearchInput
                                        className='search-input'
                                        onChange={this.searchPrograms}
                                        style={{
                                          border: '1px solid lightgrey',
                                          borderRadius: '25px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px',
                                          width: '250px',
                                        }}
                                        placeholder='Search Classes'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='campaign-result-container container border'>
                                {this.displayPrograms()}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`col-md-6 ${
                          this.state.messageTypeSelected === 'email'
                            ? 'recipient-container'
                            : ''
                        }`}
                      >
                        <div
                          className={`bg-white border p-2 ${
                            this.state.messageTypeSelected === 'email'
                              ? 'mt-4'
                              : ''
                          }`}
                        >
                          <h3>Recipients</h3>
                          <div
                            className={`recipient-list-wrapper ${
                              this.state.recipients.length > 0 ||
                              this.state.courses.length > 0
                                ? ''
                                : 'mt-5'
                            }`}
                          >
                            {this.state.recipients.length > 0 ||
                            this.state.courses.length > 0 ? (
                                this.displayRecipients()
                              ) : (
                                <Placeholder
                                  title={
                                    `No Recipients Have been Selected for this
                                  ${this.state.isEmail ? 'Email' : 'Announcement'}`
                                  }
                                  icon={
                                    this.state.messageTypeSelected === 'email'
                                      ? 'fa-envelope-o'
                                      : 'fa-bullhorn'
                                  }
                                  subtext1={
                                    'Start adding recipients to get started'
                                  }
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=' fixed-bottom bg-white p-3'>
                    <div className='row'>
                      <div className='col-6'>
                        <button
                          className='mx-auto w-100 btn btn-dark'
                          onClick={() => this.changeType(previous)}
                        >
                          Switch to
                          { this.state.isEmail ? ' push announcement' : ' email'}
                        </button>
                      </div>
                      <div className='col-6'>
                        <button
                          className='mx-auto w-100 btn btn-primary'
                          onClick={next}
                          disabled={
                            this.state.recipients.length > 0 ||
                            this.state.courses.length > 0
                              ? false
                              : true
                          }
                        >
                          {this.state.recipients.length > 0 ||
                          this.state.courses.length > 0
                            ? 'Next'
                            : 'Add Recipients'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Step
              id='MessageTemplate'
              render={({ previous }) => (
                <div className='row d-flex full-height flex-column justify-content-between '>
                  <div className='bg-white w-100 py-4 message-page-header'>
                    {this.state.isEmail ? (
                      <div
                        className={`row flex-column animated ${
                          this.state.isEmail ? 'fadeInDown' : 'fadeOutUp'
                        }`}
                      >
                        <strong className='d-block'>
                          <h1 className='text-center text-primary display-3'>
                            Let&apos;s create your email message
                          </h1>
                        </strong>
                        <h4 className='text-center'>
                          You are sending your message as an email
                        </h4>
                      </div>
                    ) : (
                      <div
                        className={`row flex-column animated ${
                          !this.state.isEmail ? 'fadeInDown' : 'fadeOutUp'
                        }`}
                      >
                        <strong className='d-block'>
                          <h1 className='text-center text-primary display-3'>
                            Let&apos;s create your announcement
                          </h1>
                        </strong>
                        <h4 className='text-center'>
                          You are sending your message as an announcement
                        </h4>
                      </div>
                    )}
                  </div>

                  <div className='col-12'>
                    {this.state.messageTypeSelected === 'email' ? (
                      <div className='row email-template-row'>
                        <div className='col-6'>
                          <h3>Templates</h3>
                          <select
                            className='custom-select custom-select-lg mb-3'
                            onChange={this.onTemplateChange}
                            value={this.state.templateValue}
                          >
                            <option value={0}>Choose Template</option>
                            <option value={1}>
                              Operation 36 Network Announcement To Families
                            </option>
                            <option value={2}>
                              Family App Tutorial How To Videos
                            </option>
                            <option value={3}>
                              Op 36 Class (First Day of Semester Overview)
                            </option>
                            <option value={4}>Weekly Op 36 Class Update</option>
                            <option value={5}>Class is Cancelled</option>
                            <option value={6}>Returning Students Email</option>
                          </select>
                          <div className='mb-3'>
                            <label htmlFor='subject'>Subject</label>
                            <TextInput
                              name='Subject'
                              type='text'
                              label=''
                              onChange={this.onChangeSubject}
                              placeholder='Enter Subject'
                              value={this.state.subject}
                            />
                          </div>
                          {this.renderEditor()}
                        </div>
                        <div className='col-6 bg-dark p-4'>
                          <h3 className='text-white mt-3'>Preview</h3>
                          <div className='card my-4 p-4'>
                            <img
                              src={
                                this.props.user.communities.length > 0
                                  ? this.props.user.communities[0].photo.file_url
                                  : coachLogo
                              }
                              className={`my-3 mx-auto ${
                                this.props.user.communities.length > 0
                                  ? 'preview-community-logo'
                                  : ''
                              }`}
                              alt='Community Logo'
                            />
                            {/* <img src={sampleImage} className="w-100" /> */}
                            <div
                              dangerouslySetInnerHTML={this.createMarkup()}
                            />
                            <div className='mt-5'>
                              <p className='mb-3'>Sincerely,</p>
                              <p className='mb-1'>
                                {this.props.user.first_name}{' '}
                                {this.props.user.last_name}
                              </p>
                              <p>{this.props.user.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='row'>
                        <div className='col-6'>
                          <h3>Text Editor</h3>
                          <TextAreaInput
                            name='Message'
                            type='text'
                            rows='3'
                            label=''
                            maxLength='120'
                            onChange={this.onChangePushMessage}
                            placeholder='Type your message here'
                            value={this.state.pushMessage}
                          />
                          <small
                            style={{
                              color:
                                this.state.pushMessageLength === 120
                                  ? '#33d9b2'
                                  : '',
                            }}
                          >
                            {this.state.pushMessageLength === 120
                              ? 'Character limit reached'
                              : `${
                                120 - this.state.pushMessageLength
                              } characters left`}
                          </small>
                        </div>
                        <div className='col-6 bg-dark'>
                          <h3 className='text-white mt-3'>Preview</h3>
                          <div className='ios-notification mb-3'>
                            <div className='ios-notification-header'>
                              <h3 className='ios-notification-header-title'>
                                Op36 App
                              </h3>
                              <h3 className='ios-notification-header-time'>
                                NOW
                              </h3>
                            </div>
                            <div className='p-2'>
                              <p>{this.state.pushMessage}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className=' fixed-bottom bg-white p-3'>
                    <div className='row'>
                      <div className='col-6'>
                        <button
                          className='mx-auto w-100 btn btn-dark'
                          onClick={previous}
                        >
                          Change Recipients
                        </button>
                      </div>
                      <div className='col-6'>
                        <button
                          className='mx-auto btn-block btn btn-primary'
                          onClick={this.sendPressed}
                        >
                          <i className='fas fa fa-paper-plane' /> Review
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </Steps>
        </Wizard>
        <StudentModal
          toggleModal={this.toggleModal}
          closeModal={this.closeModal}
          showModal={this.state.showModal}
          students={this.state.students}
          isFamily={this.state.isFamily}
        />
        <PreviewModal
          closeModal={this.closePreviewModal}
          showModal={this.state.showPreviewModal}
          subject={this.state.subject}
          message={this.state.message}
          pushMessage={this.state.pushMessage}
          sendMessage={this.sendMessage}
          recipients={this.state.recipients}
          courses={this.state.courses}
          isEmail={this.state.isEmail}
          loader={this.state.emailLoader}
        />
        <ValidationModal
          validationSuccess={this.state.validationSuccess}
          showValidationModal={this.state.showValidationModal}
          closeValidationModal={this.closeValidationModal}
          validationMessage={this.state.validationMessage}
        />
      </div>
    )
  }
}

Campaign.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  imageURL: PropTypes.string,
  family_actions: PropTypes.shape({
    getFamilies: PropTypes.func,
  }),
  community_actions: PropTypes.shape({
    getAllStudents: PropTypes.func,
  }),
  user_actions: PropTypes.shape({
    getAnnouncementCourses: PropTypes.func,
  }),
  announcement_actions: PropTypes.shape({
    postWebAnnouncement: PropTypes.func,
  }),
  activity_actions: PropTypes.shape({
    postWebActivityAnnouncement: PropTypes.func,
  }),
  courses: PropTypes.array,
  families: PropTypes.array,
  community_students: PropTypes.array,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photo: PropTypes.shape({
        file_url: PropTypes.string,
      }),
    })),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      smartToggle: PropTypes.string,
      students: PropTypes.array,
    }),
  }),
  navigate: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    // ui: state.ui,
    // ranks: state.ranks,
    user: state.user.current_user,
    family: state.family,
    families: state.families,
    courses: state.courses.courses,
    completed_courses: state.courses.completed_courses,
    community_students: state.communityStudents,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // actions: bindActionCreators(rankActions, dispatch),
    // common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    community_actions: bindActionCreators(communitiesActions, dispatch),
    announcement_actions: bindActionCreators(announcementsActions, dispatch),
    activity_actions: bindActionCreators(activityActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(Campaign))
