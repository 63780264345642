import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getCoachCoursesByMonth, getRepresentative, getCommunityFeedActivities, getCommunitySeries, getCoachCourses, getCommunityAllTimeTotals, getCommunityLeaderboardStats, getCommunityLeaderboardRanking, exportCommunityLeaderboardRanking, checkExportStatus } from '../actions/dashboardActions'
import moment from 'moment'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../utility/datesHelper'
import { getCommunityCategories } from '../actions/programCategoryActions'

const rangeDate = new Date()

const initialState = {
  representative: null,
  coursesByMonth: {},
  courses: [],
  selectedMonthDate: new Date(),
  selectedDate: new Date(),
  feedActivities: [],
  series: {},
  activitiesTotalPages: 10,
  allTimeTotals: null,
  communityCategories: [],
  leaderboardFilters: {
    startDate: new Date(rangeDate.setDate(rangeDate.getDate() - 30)),
    endDate: new Date(),
  },
  leaderboardRanking: [
    [],
    [],
    [],
    [],
  ],
  rankingTotalPages: [10, 10, 10, 10],
  rankingPages: [1, 1, 1, 1],
  tabIndex: 0,
  leaderboardStats: null,
  leaderboardLoader: false,
  clearFiltersCounter: 0,
  exportUrl: null,
  exportId: null,
  exportError: false,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedMonthDate: (state, action) => {
      state.selectedMonthDate = action.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    clearActivities: (state) => {
      state.feedActivities = []
    },
    setLeaderboardFilters: (state, action) => {
      state.leaderboardFilters = { ...state.leaderboardFilters, ...action.payload }
      state.leaderboardRanking = [[], [], [], []]
      state.rankingPages = [1, 1, 1, 1]
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload
    },
    incrementFiltersCounter: (state) => {
      state.clearFiltersCounter += 1
    },
    clearExport: (state) => {
      state.exportError = false
      state.exportId = null
      state.exportUrl = null
    },
  },
  extraReducers: {
    [getRepresentative.fulfilled]: (state, action) => {
      state.representative = action.payload.user
    },
    [getCoachCoursesByMonth.fulfilled]: (state, action) => {
      state.coursesByMonth[state.selectedMonthDate.getMonth()] = action.payload.courses
    },
    [getCoachCourses.fulfilled]: (state, action) => {
      state.courses = action.payload.courses
    },
    [getCommunityFeedActivities.fulfilled]: (state, action) => {
      state.activitiesTotalPages = action.payload.meta.pagination.total_pages
      state.feedActivities = [...state.feedActivities, ...action.payload.activities]
    },
    [getCommunitySeries.fulfilled]: (state, action) => {
      state.series[state.selectedMonthDate.getMonth()] = action.payload.series
    },
    [getCommunityAllTimeTotals.fulfilled]: (state, action) => {
      state.allTimeTotals = action.payload
    },
    [getCommunityCategories.fulfilled]: (state, action) => {
      state.communityCategories = action.payload.courseCategories.map(c => ({ value: c.id, label: c.name }))
    },
    [getCommunityLeaderboardStats.fulfilled]: (state, action) => {
      state.leaderboardStats = action.payload
    },
    [getCommunityLeaderboardRanking.pending]: (state) => {
      state.leaderboardLoader = true
    },
    [getCommunityLeaderboardRanking.fulfilled]: (state, action) => {
      const index = action.payload.tabIndex
      state.leaderboardRanking[index] = [...state.leaderboardRanking[index], ...action.payload.leaderboardRanking]
      state.rankingTotalPages[index] = action.payload.meta.pagination.totalPages
      state.rankingPages = state.rankingPages.map((p, i) => i === index ? p + 1 : p)
      state.leaderboardLoader = false
    },
    [getCommunityLeaderboardRanking.rejected]: (state) => {
      state.leaderboardLoader = false
    },
    [exportCommunityLeaderboardRanking.fulfilled]: (state, action) => {
      state.exportError = false
      state.exportId = action.payload.jobId
    },
    [checkExportStatus.fulfilled]: (state, action) => {
      const job = action.payload.job
      if (job.status === 'success') {
        state.exportId = null
        state.exportError = false
        state.exportUrl = job.parsedResult.fileUrl
      }
      if (job.status === 'error') {
        state.exportError = true
        state.exportId = null
      }
    },
  },
})

export const { setSelectedDate,
  clearActivities,
  setSelectedMonthDate,
  setLeaderboardFilters,
  setLeaderboardTab,
  setRankingPages,
  setTabIndex,
  incrementFiltersCounter,
  clearExport,
} = dashboardSlice.actions

export default dashboardSlice.reducer

export const sessionsDates = createSelector(
  state => state.dashboard.coursesByMonth,
  state => state.dashboard.selectedMonthDate,
  state => state.user.current_user.communities[0].timezone,
  (
    courses,
    selectedMonthDate,
    timezone
  ) => {
    const dates = []
    courses[selectedMonthDate.getMonth()]?.forEach(course => {
      course.schedule?.schedule_sessions?.forEach(s => {
        const sessionStartDate = getMomentInCommunityTimezoneReversed(moment(s.start_date), timezone, DATETIME_FORMAT).toDate()

        dates.push(sessionStartDate)
      })
    })
    return dates
  }
)

export const eventsDates = createSelector(
  state => state.dashboard.series,
  state => state.dashboard.selectedMonthDate,
  state => state.user.current_user.communities[0].timezone,
  (
    series,
    selectedMonthDate,
    timezone
  ) => {
    const dates = []
    series[selectedMonthDate.getMonth()]?.forEach(series => {
      series.events?.forEach(s => {
        const eventDate = getMomentInCommunityTimezoneReversed(moment(s.event_date), timezone, DATETIME_FORMAT).toDate()

        dates.push(eventDate)
      })
    })
    return dates
  }
)

export const selectedDateEvents = createSelector(
  state => state.dashboard.series,
  state => state.dashboard.selectedDate,
  state => state.user.current_user.communities[0].timezone,
  (
    series,
    selectedDate,
    timezone
  ) => {
    const selectedDateEvents = []
    series[selectedDate.getMonth()]?.forEach(s => {
      const studentsProfiles = s.students?.map(st => st.avatar?.file_url) ?? []
      s.events.forEach(e => {
        const event = {
          id: e.id,
          seriesName: s.title,
          name: e.index_name,
          format: e.format,
          location: e.location,
          date: getMomentInCommunityTimezoneReversed(moment(e.event_date), timezone, DATETIME_FORMAT).toDate(),
          students: studentsProfiles,
        }
        event.date.toDateString() === selectedDate.toDateString() && selectedDateEvents.push(event)
      })
    })
    selectedDateEvents.sort((s1, s2) => s1.date - s2.date)
    return selectedDateEvents
  }
)

export const selectedDateSessions = createSelector(
  state => state.dashboard.coursesByMonth,
  state => state.dashboard.selectedDate,
  state => state.user.current_user.communities[0].timezone,
  (
    courses,
    selectedDate,
    timezone
  ) => {
    const selectedDateSessions = []
    courses[selectedDate.getMonth()]?.forEach(course => {
      const enrollmentsProfiles = course.enrollments?.map(en => en.user?.avatar?.file_url) ?? []
      course.schedule?.schedule_sessions?.forEach(s => {
        const session = {
          programTitle: course.name,
          programId: course.id,
          categoryColor: course.category_color,
          sessionId: s.id,
          enrollments: course?.enrollments.length,
          enrollmentsProfiles: enrollmentsProfiles,
        }
        session.sessionTitle = s.title
        session.sessionStartDate = getMomentInCommunityTimezoneReversed(moment(s.start_date), timezone, DATETIME_FORMAT).toDate()
        session.sessionEndDate = getMomentInCommunityTimezoneReversed(moment(s.end_date), timezone, DATETIME_FORMAT).toDate()
        session.location = s.location
        session.sessionStartDate.toDateString() === selectedDate.toDateString() && selectedDateSessions.push(session)
      })
    })

    selectedDateSessions.sort((s1, s2) => s1.sessionStartDate - s2.sessionStartDate)
    return selectedDateSessions
  }
)
