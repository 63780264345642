import * as types from "../actions/actionTypes";

export default function events(state = [], action) {
  switch (action.type) {
    case types.GET_EVENTS:
      return action.events;

    default:
      return state;
  }
}
