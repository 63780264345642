import ReactToggle from 'react-toggle'

import './Toggle.scss'

const Toggle = (props) => (
  <ReactToggle
    className='op36-toggle'
    icons={false}
    {...props}
  />
)

export default Toggle
