import { createAsyncThunk } from '@reduxjs/toolkit'

import Axios from '../axios'
import { handleAPIError } from '../utility/actionHelper'
import { serialize, deserialize } from '../utility/serialization'

// THUNKS

export const getRepresentative = createAsyncThunk(
  'dashboard/getRepresentative',
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await requestRepresentative(userId)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCoachCoursesByMonth = createAsyncThunk(
  'dashboard/getCoachCoursesByMonth',
  async ({ communityId, selectedMonthDate }, { rejectWithValue }) => {
    try {
      const { data } = await requestCoachCourses(communityId, selectedMonthDate)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityFeedActivities = createAsyncThunk(
  'dashboard/getCommunityFeedActivities',
  async ({ communityId, page, perPage }, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityActivities(communityId, page, perPage)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunitySeries = createAsyncThunk(
  'dashboard/getCommunitySeries',
  async (selectedMonthDate, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunitySeries(selectedMonthDate)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCoachCourses = createAsyncThunk(
  'dashboard/getCoachCourses',
  async (communityId, { rejectWithValue }) => {
    try {
      const { data } = await requestCoachCourses(communityId)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityAllTimeTotals = createAsyncThunk(
  'dashboard/getCommunityAllTimeTotals',
  async (communityId, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityAllTimeTotals(communityId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityLeaderboardStats = createAsyncThunk(
  'dashboard/getCommunityLeaderboardStats',
  async ({ communityId, filters }, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityLeaderboardStats(communityId, filters)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityLeaderboardRanking = createAsyncThunk(
  'dashboard/getCommunityLeaderboardRanking',
  async ({ communityId, filters, page, perPage, tabIndex }, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityLeaderboardRanking(communityId, filters, page, perPage)
      data.tabIndex = tabIndex
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const exportCommunityLeaderboardRanking = createAsyncThunk(
  'dashboard/exportCommunityLeaderboardRanking',
  async ({ communityId, filters }, { rejectWithValue }) => {
    try {
      const { data } = await requestExportCommunityLeaderboardRanking(communityId, filters)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const checkExportStatus = createAsyncThunk(
  'dashboard/checkExportStatus',
  async (exportId, { rejectWithValue }) => {
    try {
      const { data } = await requestCheckExportStatus(exportId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestRepresentative(userId) {
  const requestUrl = `/api/v1/users/${userId}?preview=true`
  return Axios.get(requestUrl)
}

function requestCoachCourses(communityId, selectedMonthDate) {
  const requestUrl = `/api/v1/communities/${communityId}/coach_courses`
  const params = serialize({ selectedMonthDate: selectedMonthDate?.toDateString() })
  return Axios.get(requestUrl, { params })
}

function requestCommunityActivities(communityId, page, perPage) {
  const requestUrl = `/api/v1/communities/${communityId}/activities`
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
    },
  })
}

function requestCommunitySeries(selectedMonthDate) {
  const requestUrl = '/api/v1/series'
  const params = serialize({ selectedMonthDate: selectedMonthDate.toDateString() })
  return Axios.get(requestUrl, { params })
}

function requestCommunityAllTimeTotals(communityId) {
  const requestUrl = `/api/v1/reports/dashboard_all_time_totals?community_id=${communityId}`
  return Axios.get(requestUrl)
}

function requestCommunityLeaderboardStats(communityId, filters) {
  const requestUrl = 'api/v1/reports/community_leaderboard_stats'
  const params = serialize({
    communityId,
    startDate: filters.startDate.toISOString().split('T')[0],
    endDate: filters.endDate.toISOString().split('T')[0],
    categoryId: filters.categoryId,
  })
  return Axios.get(requestUrl, { params })
}

function requestCommunityLeaderboardRanking(communityId, filters, page, perPage) {
  const requestUrl = 'api/v1/reports/community_leaderboard_ranking'
  const params = serialize({
    communityId,
    startDate: filters.startDate.toISOString().split('T')[0],
    endDate: filters.endDate.toISOString().split('T')[0],
    categoryId: filters.categoryId,
    activityTypes: filters.activityTypes,
    page: page,
    perPage: perPage,
  })
  return Axios.get(requestUrl, { params })
}

function requestExportCommunityLeaderboardRanking(communityId, filters) {
  const requestUrl = 'api/v1/reports/export_community_leaderboard_ranking'
  const params = serialize({
    communityId,
    startDate: filters.startDate.toISOString().split('T')[0],
    endDate: filters.endDate.toISOString().split('T')[0],
    categoryId: filters.categoryId,
  })
  return Axios.post(requestUrl, params)
}

function requestCheckExportStatus(jobId) {
  const requestUrl = `api/v1/jobs/${jobId}`
  return Axios.get(requestUrl)
}
