import * as types from "./actionTypes";
import * as common from "./commonActions";

import Axios from '../axios'

export function successRequestingAnnouncements(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_ANNOUNCEMENTS,
      announcements: response.data.announcements,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successRequestingPostAnnouncement(response) {
  if (response.status === 201) {
    return {
      type: types.POST_ANNOUNCEMENT,
      announcement: response.data.announcement,
    };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function getAnnouncements(page, per_page) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestAnnouncements(page, per_page)
      .then((response) => dispatch(successRequestingAnnouncements(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postAnnouncement(announcement) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostAnnouncement(announcement)
      .then((res) => {
        dispatch(successRequestingPostAnnouncement(res));
        return res.data.announcement;
      })
      .then((announcement) => {
        dispatch(common.loadingChanged(false));
        return announcement;
      });
  };
}

export function postWebAnnouncement(announcement) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostWebAnnouncement(announcement)
      .then((res) => {
        dispatch(successRequestingPostAnnouncement(res));
        return res.data.announcement;
      })
      .then((announcement) => {
        dispatch(common.loadingChanged(false));
        return announcement;
      });
  };
}

function requestAnnouncements(page, per_page) {
  let requestUrl = "/api/v1/announcements";
  if (page && per_page) {
    requestUrl += `?page=${page}&per_page=${per_page}`;
  }
  return Axios.get(requestUrl);
}

function requestPostAnnouncement(announcement) {
  let requestUrl = "/api/v1/announcements";

  var recipients = [];
  var courses = announcement.courses;
  for (var i = 0; i < courses.length; i++) {
    var course = courses[i];
    recipients.push(course.value);
  }

  var data = {
    send_email: announcement.sendEmail,
    subject: announcement.subject,
    message: announcement.message,
    course_ids: recipients,
    recipients: [],
  };

  return Axios.post(requestUrl, data);
}

function requestPostWebAnnouncement(announcement) {
  let requestUrl = "/api/v1/announcements";

  var courseRecipients = [];
  if (announcement.courses.length > 0) {
    var courses = announcement.courses;
    for (var i = 0; i < courses.length; i++) {
      var course = courses[i];
      courseRecipients.push(course.id);
    }
  }

  var data = {
    send_email: announcement.isEmail,
    subject: announcement.subject,
    message: announcement.isEmail
      ? announcement.message
      : announcement.pushMessage,
    course_ids: courseRecipients,
    recipients: announcement.recipients,
  };

  return Axios.post(requestUrl, data);
}
