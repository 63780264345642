import PropTypes from 'prop-types'
import { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createFilter } from 'react-search-input'
import _ from 'lodash'
import * as Constants from '../../../misc/Constants'
import * as courseActions from '../../../actions/courseActions'
import { yardageByLevel, fullTeeYardageByLevel } from '../../../utility/curriculumHelper'

import ClassPickerCard from '../ClassPickerCard/ClassPickerCard'
import StudentPickerCard from '../../students/StudentPickerCard/StudentPickerCard'
import StudentCard from '../../students/StudentCard/StudentCard'
import StateSwitcher from '../../common/StateSwitcher'
import SearchInput from '../../common/SearchInput'
import Loader from '../../common/Op36Loader-web'

import './ManageRoster.scss'
import colors from '../../../assets/styles/globals.scss'
import { ReactComponent as PersonIcon } from '../../../assets/images/common/icons/person-icon.svg'

// eslint-disable-next-line max-lines-per-function
const ManageRoster = ({
  students,
  courses,
  switcherSelected,
  setSwitcherSelected,
  addStudent,
  deleteStudent,
  addCourse,
  deleteCourse,
  updateStudent,
  updateCourse,
}) => {
  const dispatch = useDispatch()
  const communityCourses = useSelector(state => state.courses.courses)
  const communityStudents = useSelector(state => state.communityStudents)
  const [switcherState, setSwitcherState] = useState('students')
  const [coursesSearch, setcoursesSearch] = useState('')
  const [studentsSearch, setStudentsSearch] = useState('')
  const [loader] = useState(false)

  const programsSelected = (switcherState === 'programs')

  const KEYS_TO_FILTER_STUDENTS = useMemo(() => [
    'first_name',
    'last_name',
    'username',
    'email',
  ], [])

  const KEYS_TO_FILTER_COURSES = useMemo(() => [
    'name',
  ], [])

  const switcherToggle = () => {
    if (programsSelected) {
      setSwitcherState('students')
    } else {
      setSwitcherState('programs')
    }
  }

  useEffect(() => {
    if (switcherSelected) {
      setSwitcherState(switcherSelected)
      setSwitcherSelected('')
    }
  }, [switcherSelected, setSwitcherSelected])

  const filteredStudents = useMemo(() =>
    communityStudents?.filter(createFilter(
      studentsSearch, KEYS_TO_FILTER_STUDENTS) || []
    ), [communityStudents, KEYS_TO_FILTER_STUDENTS, studentsSearch]
  )

  const filteredCourses = useMemo(() =>
    communityCourses?.filter(createFilter(
      coursesSearch, KEYS_TO_FILTER_COURSES) || []
    ), [communityCourses, KEYS_TO_FILTER_COURSES, coursesSearch]
  )

  const handleAddStudent = (std, courseId = null, packageId = null) => {
    if (std.id > 0) {
      const studentData = {
        user_id: std.id,
        first_name: std.first_name,
        last_name: std.last_name,
        current_formal_division: std.current_formal_division,
        level: std.current_formal_division,
        stats: std.stats,
        yardage: yardageByLevel[std.current_formal_division],
        fullTeeYardage: fullTeeYardageByLevel[std.current_formal_division],
        avatar: std.avatar,
      }
      const index = students.findIndex(s => s.user_id === std.id)
      if (index === -1) {
        if (courseId) {
          if (packageId) {
            dispatch(addStudent({ ...studentData, addedFrom: { [courseId]: { [packageId]: true } } }))
          } else {
            dispatch(addStudent({ ...studentData, addedFrom: { [courseId]: true } }))
          }
        } else {
          dispatch(addStudent({ ...studentData, addedFrom: { 'students': true } }))
        }
      } else {
        if (courseId) {
          if (packageId) {
            dispatch(updateStudent({ ...studentData, addedFrom: {
              ...students[index].addedFrom,
              [courseId]: students[index].addedFrom[courseId] === true
                ? { [packageId]: true }
                : { ...students[index].addedFrom[courseId], [packageId]: true },
            } }))
          } else {
            dispatch(updateStudent({ ...studentData, addedFrom: { ...students[index].addedFrom, [courseId]: true } }))
          }
        } else {
          dispatch(updateStudent({ ...studentData, addedFrom: { ...students[index].addedFrom, 'students': true } }))
        }
      }
    }
  }

  const handleDeleteStudent = (std, courseId = null, packageId = null, lastPackage) => {
    if (courseId) {
      const index = students.findIndex(s => s.user_id === std)
      if (index === -1) {
        return
      }
      const fromm = { ...students[index].addedFrom }
      if (packageId) {
        const fromCourse = { ...fromm[courseId] }
        delete fromCourse[packageId]
        if (_.isEmpty(fromCourse)) {
          lastPackage ? fromm[courseId] = true : delete fromm[courseId]
        }
      } else {
        delete fromm[courseId]
      }
      if (_.isEmpty(fromm)) {
        dispatch(deleteStudent(std))
      } else {
        dispatch(updateStudent({ ...students[index], addedFrom: fromm }))
      }
    } else {
      dispatch(deleteStudent(std))
    }
  }

  const handleAddCourse = (course) => {
    if (course.id > 0) {
      dispatch(addCourse(course))
    }
  }

  const handleDeleteCourse = (course) => {
    dispatch(deleteCourse(course))
  }

  const handleAddPackage = (pkg, course) => {
    if (pkg.id > 0) {
      const selectedPackages = [...course.selectedPackages]
      selectedPackages.push(pkg)
      dispatch(updateCourse({ ...course, selectedPackages: selectedPackages }))
    }
  }

  const handleDeletePackage = (pkg, course) => {
    const selectedPackages = [...course.selectedPackages]
    const index = selectedPackages.findIndex(p => p.id === pkg.id)
    selectedPackages.splice(index, 1)
    dispatch(updateCourse({ ...course, selectedPackages: selectedPackages }))
  }

  const packageAdded = (pkg, course) => {
    handleAddPackage(pkg, course)
    course.enrollments.map((e) => {
      const std = communityStudents.find(s => s.id === e.userId)
      if (std.enrollments.find((e) => e.package_id === pkg.id && e.course_id === course.id)) {
        handleAddStudent(std, course.id, pkg.id)
      } else if (course.selectedPackages.length === 0){
        handleDeleteStudent(std.id, course.id)
      }
    })
  }

  const packageRemoved = (pkg, course) => {
    const lastPackage = course.selectedPackages.length === 1
    course.enrollments.map((e) => {
      const std = communityStudents.find(s => s.id === e.userId)
      if (std.enrollments.find((e) => e.package_id === pkg.id && e.course_id === course.id)) {
        handleDeleteStudent(std.id, course.id, pkg.id, lastPackage)
      } else if (lastPackage) {
        handleAddStudent(std, course.id)
      }
    })
    handleDeletePackage(pkg, course)
  }

  const coursesCheckBoxToggle = (checked, id) => {
    if (checked) {
      const courseIndex = courses.findIndex(c => c.id === id)
      courses[courseIndex].enrollments.map((e) => {
        handleDeleteStudent(e.userId, id)
      })
      handleDeleteCourse(id)
    } else {
      courseActions.getEventCourse(id).then( response => {
        const course = response.data.course
        handleAddCourse(course)
        course.enrollments.map((e) => {
          const std = communityStudents.find(s => s.id === e.userId)
          handleAddStudent(std, id)
        })
      })
    }
  }

  const studentsCheckBoxToggle = (checked, id) => {
    const studentIndex = communityStudents.findIndex(s => s.id === id)
    if (checked) {
      handleDeleteStudent(id)
    } else {
      handleAddStudent(communityStudents[studentIndex])
    }
  }

  const searchUpdated = (e) => {
    if (programsSelected) {
      setcoursesSearch(e.target.value)
    } else {
      setStudentsSearch(e.target.value)
    }
  }

  return (
    <div id='ManageRoster' className='d-flex justify-content-center'>
      <div className='col-6 populate-roster'>
        <p className='pl-3 large-text'>Populate Roster</p>
        <div>
          <StateSwitcher
            options={Constants.SWITCHER_OPTIONS}
            initialTypeSelected={'programs'}
            selectedType={switcherState}
            stateSwitcher={switcherToggle}
            position={'left'}
          />
        </div>
        <div className='search-wrapper'>
          <p>
            Quickly populate your event roster by selecting programs/packages, or by selecting individual students.
          </p>
          <div className='mt-3 roster-search-bar'>
            <SearchInput
              placeholder={`Search for ${programsSelected ? 'Active Programs' : 'Students'}`}
              value={programsSelected ? coursesSearch : studentsSearch}
              onChange={searchUpdated}
            />
          </div>
        </div>
        <hr className='main-hr'/>
        <div className='mt-3'>
          {loader ? (
            <Loader message={`loading ${switcherState}`} />
          ) : (
            programsSelected ?
              filteredCourses?.length > 0 ?
                filteredCourses?.map((c) => {
                  const selectedCourse = courses.find((sc) => sc.id === c.id)
                  return (
                    <div className='pb-3' key={c.id}>
                      <ClassPickerCard
                        checked={!!selectedCourse}
                        course={selectedCourse || c}
                        onClick={coursesCheckBoxToggle}
                        errors={{}}
                        handleAddPackage={packageAdded}
                        handleDeletePackage={packageRemoved}
                      />
                    </div>
                  )
                }) :
                <div className='d-flex flex-column justify-content-center mt-5'>
                  { communityCourses &&
                    <>
                      <p className='placeholder-title'>No results for {coursesSearch}</p>
                      <p className='placeholder-description'>Please check spelling and try again.</p>
                    </>
                  }
                </div>
              :
              filteredStudents?.length > 0 ?
                filteredStudents?.map((s) => (
                  <StudentPickerCard key={s.id} checked={!!students.find((ss) => ss.user_id === s.id)} student={s} onClick={studentsCheckBoxToggle}/>
                )) :
                <div className='d-flex flex-column justify-content-center mt-5'>
                  { communityStudents &&
                    <>
                      <p className='placeholder-title'>No results for {studentsSearch}</p>
                      <p className='placeholder-description'>Please check spelling and try again.</p>
                    </>
                  }
                </div>
          )}
        </div>
      </div>
      <div className='col-6 roster-summary' >
        <div className='students-list mx-auto'>
          <p className='large-text'>{`Roster Summary (${students.length})`}</p>

          {students.length > 0 ?
            <div className='d-flex flex-wrap'>
              { students?.map((s) => (
                <StudentCard key={s.user_id} student={s} onXClick={studentsCheckBoxToggle}/>
              ))
              }
            </div>
            :
            <div className='no-students-placeholder'>
              <PersonIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
              <p className='placeholder-title'>No Students Added</p>
              <p className='placeholder-description'>
                Add your participants individually or by program from the menu on the left.
              </p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

ManageRoster.propTypes = {
  switcherSelected: PropTypes.string,
  setSwitcherSelected: PropTypes.func,
  students: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired,
  addStudent: PropTypes.func.isRequired,
  updateStudent: PropTypes.func.isRequired,
  deleteStudent: PropTypes.func.isRequired,
  addCourse: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
  deleteCourse: PropTypes.func.isRequired,
}

export default ManageRoster
