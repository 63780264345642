import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UncontrolledPopover } from 'reactstrap'
import _ from 'lodash'

import PageHeader from '../common/PageHeader/PageHeader'
import EventForm from './Form/EventForm'
import SearchInput from '../common/SearchInput'
import ConfirmationModal2 from '../common/ConfirmationModal2'
import * as constants from '../../misc/Constants'

import styles from './Events.module.scss'
import colors from '../../assets/styles/globals.scss'
import { ReactComponent as TableSelectDropDownIcon } from '../../assets/images/common/icons/table-select-drop-down-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/images/common/icons/programBuilder/edit-icon.svg'

import { useSelector, useDispatch } from 'react-redux'
import { resetState, setField } from '../../reducers/nineHoleEventReducer'
import { deleteSeries } from '../../actions/seriesActions'
import * as commonActions from '../../actions/commonActions'
import * as eventActions from '../../actions/eventActions'
import * as seriesActions from '../../actions/seriesActions'
import * as userActions from '../../actions/userActions'
import * as communitiesActions from '../../actions/communitiesActions'
import classNames from 'classnames'
import EventsList from './EventsList'
import SeriesList from './SeriesList'
import EventInProgress from './EventInProgress'

const EditIconComponent = (
  <div className={styles.editIconWrapper}>
    <EditIcon fill='#fdfdfd' width={'40px'} height={'40px'}/>
  </div>
)

const Events = () => { //eslint-disable-line max-lines-per-function
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const currentUser = useSelector(state => state.user.current_user)
  const events = useSelector(state => state.events)
  const event = useSelector(state => state.nineHoleEvent)
  const series = useSelector(state => state.series.allSeries)
  const communityStudents = useSelector(state => state.communityStudents)

  const [showForm, setShowForm] = useState(false)
  const [eventsLoader, setEventsLoader] = useState(true)
  const [eventLoader, setEventLoader] = useState(false)
  const [seriesLoader, setSeriesLoader] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermSeries, setSearchTermSeries] = useState('')
  const [showDeleteSeriesModal, setShowDeleteSeriesModal] = useState(false)
  const [selectedSeriesId, setSelectedSeriesID] = useState(null)
  const [showLoadScoresModal, setShowLoadScoresModal] = useState(false)

  const [activePage, setActivePage] = useState(1)
  const [activePageSeries, setActivePageSeries] = useState(1)
  const [tabIndex, setTabIndex] = useState(0)

  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[-1] && localScores[-1]['scores']

  const fetchEvents = useCallback((filters) => {
    dispatch(
      eventActions.getEvents(currentUser.id, filters, activePage, constants.PER_PAGE_DEFAULT)
    ).then(() => {
      setEventsLoader(false)
    })
  }, [dispatch, currentUser.id, activePage])

  const fetchSeries = useCallback((filters) => {
    setSeriesLoader(true)
    dispatch(
      seriesActions.getAllSeries(
        { filters, page: activePageSeries, perPage: constants.PER_PAGE_DEFAULT }
      )).unwrap().then(() => {
      setSeriesLoader(false)
    })
  }, [dispatch, activePageSeries])

  const debouncedSearchEvents = useMemo(() => (
    _.debounce(fetchEvents, 400)
  ), [fetchEvents])

  const debouncedSearchSeries = useMemo(() => (
    _.debounce(fetchSeries, 400)
  ), [fetchSeries])

  const onChange = (e) => {
    const value = e.target.value
    if (tabIndex === 0) {
      setSearchTermSeries(value)
      setSeriesLoader(true)
      setActivePageSeries(1)
      debouncedSearchSeries({ search: value })
    } else {
      setSearchTerm(value)
      setEventsLoader(true)
      setActivePage(1)
      debouncedSearchEvents({ search: value })
    }
  }

  const fetchCourses = useCallback(() => {
    dispatch(userActions.getEventCourses(currentUser.communities[0].id))
  }, [dispatch, currentUser.communities])

  const fetchStudents = useCallback((searchTerm = null) => {
    dispatch(communitiesActions.getAllStudents(currentUser.communities[0].id, null, null, searchTerm))
  }, [dispatch, currentUser.communities])

  useEffect(() => {
    fetchStudents()
  }, [fetchStudents])


  const createEvent = () => {
    setEventLoader(true)
    dispatch(eventActions.createEvent(event)).then(() => {
      toast.success(`Success! Created ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      removeDraftScores()
      _.delay(handleEventSubmittedClick, 5000)
      setEventLoader(false)
    }).catch(() => setEventLoader(false))
  }

  const handleEventSubmittedClick = () => {
    setShowForm(false)
    fetchEvents()
  }

  const handleDeleteSeries = () => {
    setSeriesLoader(true)

    dispatch(deleteSeries(selectedSeriesId)).unwrap()
      .catch(() => {
        toast.error('Something went wrong.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => {
        setShowDeleteSeriesModal(false)
        setSeriesLoader(false)
      })
  }

  const removeSeries = () => {
    setSelectedSeriesID(selectedSeriesId)
    setShowDeleteSeriesModal(true)
  }

  const handleSeriesMenuClick = (seriesId) => {
    setSelectedSeriesID(seriesId)
  }

  const navigateToEdit = (step) => {
    navigate(`/series/${selectedSeriesId}/edit?step=${step}`)
  }

  const navigateToEmails = () => {
    const selectedSeries = series.find(s => s.id === selectedSeriesId)
    navigate('/announcements/new-campaign', { state: { smartToggle: 'email', students: selectedSeries.students } })
  }

  const quickLog = () => {
    dispatch(resetState())
    eventScores ? setShowLoadScoresModal(true) : setShowForm(true)
  }

  const loadScores = () => {
    setShowLoadScoresModal(false)
    const updatedStudents = []
    const studentErrors = []
    Object.keys(eventScores).forEach((userId) => {
      const studentIndex = communityStudents.findIndex(std => std.id === parseInt(userId))
      const std = communityStudents[studentIndex]
      const savedData = eventScores[userId]

      if (!std) {
        studentErrors.push(`Student ${savedData['first_name']} ${savedData['last_name']} could not be loaded.`)
      } else {
        updatedStudents.push({
          user_id: std.id,
          first_name: std.first_name,
          last_name: std.last_name,
          current_formal_division: std.current_formal_division,
          stats: std.stats,
          avatar: std.avatar,
          addedFrom: std.addedFrom,
          score: savedData['score'],
          greens: savedData['greens'],
          putts: savedData['putts'],
          yardage: savedData['yardage'],
          fullTeeYardage: savedData['fullTeeYardage'],
          level: savedData['level'],
        })
      }
    })

    if (studentErrors) {
      dispatch(setField({ field: 'errors', value: { students: studentErrors } }))
    }
    dispatch(setField({ field: 'students', value: updatedStudents }))
    dispatch(setField({ field: 'action', value: 'Log Scores' }))
    setShowForm(true)
  }

  const removeDraftScores = () => {
    const localScores = JSON.parse(localStorage.getItem('draftScores'))
    delete localScores[event.id]
    localStorage.setItem('draftScores', JSON.stringify(localScores))
  }

  const startFromScratch = () => {
    removeDraftScores()
    setShowLoadScoresModal(false)
    setShowForm(true)
  }

  const closeForm = () => {
    dispatch(resetState())
    setShowForm(false)
  }

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('Events'))
  }, [dispatch])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  useEffect(() => {
    fetchSeries()
  }, [fetchSeries])

  useEffect(() => {
    if (!_.isEmpty(location.state) && location.state.smartToggle) {
      setShowForm(true)
    }
  }, [location.state])

  useEffect(() => {
    dispatch(commonActions.showSideBar(!showForm))
    dispatch(commonActions.showNavBar(!showForm))
  }, [dispatch, showForm])

  const HeaderActionButton = (
    <div>
      <button id='logEventButton' className='btn btn-primary pl-3 pr-3'>
        <div className='d-flex align-items-center'>
          <span className='mr-2'>Create Event</span>
          <TableSelectDropDownIcon stroke='#fff' style={{ marginLeft: '2px' }}/>
        </div>
      </button>
      <UncontrolledPopover
        placement='bottom'
        target='logEventButton'
        trigger='legacy'
      >
        <div className={classNames('d-flex flex-column', styles['header-button-actions'])}>
          <Link to={'/series/new'}>Create Event Series</Link>
          <p aria-hidden='true' onClick={() => quickLog()}>Quick Log an Event</p>
        </div>
      </UncontrolledPopover>
    </div>
  )

  return (
    <div className={styles['events-page']} style={{ marginLeft: !showForm ? '-15px' : '0' }} >
      { showForm ?
        <EventForm
          onClickBack={closeForm}
          fetchCourses={fetchCourses}
          fetchStudents={fetchStudents}
          createEvent={createEvent}
          handleEventSubmittedClick={handleEventSubmittedClick}
          removeDraftScores={removeDraftScores}
          eventLoader={eventLoader}
        /> :
        <div>
          <PageHeader
            title='9 Hole Events'
            actionComponent={HeaderActionButton}
          />

          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList className={styles.tabList}>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Upcoming Events</Tab>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Events History</Tab>
            </TabList>
            <TabPanel className={styles.tabPanel}>
              <div className={classNames(styles['events-search-bar'], 'mt-2')}>
                <SearchInput value={searchTermSeries} placeholder='Search Events' onChange={onChange}/>
              </div>
              {eventScores && <div className='px-4 pb-3'><EventInProgress onClick={loadScores} isQuickLog/></div>}
              <SeriesList
                series={series}
                loader={seriesLoader}
                searchTerm={searchTermSeries}
                removeSeries={removeSeries}
                activePage={activePageSeries}
                setActivePage={setActivePageSeries}
                handleSeriesMenuClick={handleSeriesMenuClick}
                navigateToEdit={navigateToEdit}
                navigateToEmails={navigateToEmails}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <div className={classNames(styles['events-search-bar'], 'mt-2')}>
                <SearchInput value={searchTerm} placeholder='Search Events' onChange={onChange}/>
              </div>
              <EventsList events={events} loader={eventsLoader} searchTerm={searchTerm} activePage={activePage} setActivePage={setActivePage}/>
            </TabPanel>
          </Tabs>
        </div>
      }
      <ConfirmationModal2
        showModal={showDeleteSeriesModal}
        closeModal={() => setShowDeleteSeriesModal(false)}
        onDeny={() => setShowDeleteSeriesModal(false)}
        denyMessage='Cancel'
        onProceed={() => handleDeleteSeries()}
        proceedMessage='Delete Series'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will remove the series and all the upcoming events.'
      />
      <ConfirmationModal2
        showModal={showLoadScoresModal}
        closeModal={() => setShowLoadScoresModal(false)}
        onDeny={startFromScratch}
        denyMessage='Start from Scratch'
        onProceed={loadScores}
        proceedMessage='Load Scores'
        icon={EditIconComponent}
        title='Load draft scores?'
        message='There are draft scores saved for this event. Do you want to load them?'
      />
    </div>
  )
}
export default Events
