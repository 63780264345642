import PropTypes from 'prop-types'

import StudentWithAvatar from '../../students/StudentWithAvatar'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'
import colors from '../../../assets/styles/globals.scss'
import styles from './GuestList.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import classNames from 'classnames'

const GuestList = ({ students, removeStudent }) => (
  <div id='LogScores' className='d-flex flex-column table-container'>
    <div className='d-flex flex-column justify-content-center'>
      {students?.length > 0 ?
        <table className='table table-responsive animated fadeIn w-100'>
          <thead>
            <tr>
              <th className='golfer-header'>Golfer</th>
              <th className='px-5'>Age</th>
              <th>Gender</th>
              <th>Program</th>
              <th className='centered-text'>Record Score</th>
              <th className='centered-text'>Level Attempts</th>
              <th className='px-5 centered-text'>Actions</th>
            </tr>
          </thead>
          <tbody>
            { students.map((std, index) =>
              <tr
                key={index}
                className={'students-table-row'}
              >
                <td style={{ width: '30%' }} className='golfer-data'>
                  <StudentWithAvatar student={std} clickable/>
                </td>
                <td style={{ width: '10%' }} className='px-5'>{std.age} yrs</td>
                <td style={{ width: '20%' }}>{std.gender}</td>
                <td style={{ width: '20%' }}>{std.course_name || 'Manually Added'}</td>
                <td style={{ width: '10%' }} className='centered-text'>{std.stats && std.stats[std.level].record_score || '-'}</td>
                <td style={{ width: '10%' }} className='centered-text'>{std.stats && std.stats[std.level].level_attempts || '-'}</td>
                <td className={classNames('centered-text', styles.deleteColumn)}>
                  <div className={styles.deleteButton}>
                    <XIcon fill={colors.lightRed} aria-hidden='true' onClick={() => removeStudent(std.user_id)} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        :
        <div className={styles.noStudentsPlaceholder}>
          <GolferIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
          <p className={styles.placeholderTitle}>No Students Enrolled</p>
          <div className={styles.placeholderDescription}>There are no enrollments for this event.</div>
        </div>
      }
    </div>
  </div>
)

GuestList.propTypes = {
  students: PropTypes.array,
  removeStudent: PropTypes.func.isRequired,
}

export default GuestList
