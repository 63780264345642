import { useDispatch, useSelector } from 'react-redux'
import { setField } from '../../../../reducers/seriesReducer'
import Input from '../../../common/form/Input'
import styles from './SeriesDetails.module.scss'

const SeriesDetails = () => {
  const dispatch = useDispatch()

  const {
    errors,
    title,
    subtitle,
  } = useSelector(state => state.series)

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    if ((field === 'title' || field === 'subtitle') && value.length > 50) {
      return
    }

    dispatch(setField({ field, value }))
  }

  return (
    <section className={styles['series-details']}>
      <div className='row'>
        <div className='col-8 mx-auto mt-5'>
          <p className={styles['title']}>9 Hole Event Series</p>
          <p>Please fill out the information below</p>
          <div className='row mb-3'>
            <div className='col-12 col-md-6 pr-5'>
              <div className='d-flex justify-content-between align-items-start'>
                <label htmlFor='title' className={styles['label']}>
                  Series Title <span className='required-text'>*</span>
                </label>
              </div>

              <Input
                name='title'
                type='text'
                onChange={handleInputChange}
                placeholder='Example: Fall Junior Series'
                value={title}
                error={errors.title}
              />
              <p className={styles['character-counter']}>
                {`${title.length}/50 characters`}
              </p>
            </div>
            <div className='col-12 col-md-6 pr-5'>
              <div className='d-flex justify-content-between align-items-start'>
                <label htmlFor='subtitle' className={styles['label']}>
                  Subtitle
                </label>
              </div>

              <Input
                name='subtitle'
                type='text'
                onChange={handleInputChange}
                placeholder='Example: Spring 9 Hole Event Series'
                value={subtitle}
                error={errors.subtitle}
              />
              <p className={styles['character-counter']}>
                {`${subtitle.length}/50 characters`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SeriesDetails
