import React, { Component } from "react";
import "./AccountForm.scss";
import Toggle from "react-toggle";
import _ from "lodash";
import PropTypes from 'prop-types'
import TelephoneInput from '../../common/TelephoneInput'
import flags from "../../../assets/images/flags.png";
import { displayYearOptions } from "../../../utility/helperFunctions";

// CHILDREN
import UserAvatarForm from "./UserAvatarForm";
import Loader from "../../common/Op36Loader-web";
import TextInput from "../../common/form/TextInput";
import AccountSettings from './AccountSettings'

class AccountForm extends Component {
  state = {
    activeForm: "personal",
  };

  togglePassword = (e) => {
    e.preventDefault();
    this.setState({
      togglePassword: !this.state.togglePassword,
    });
  };

  toggleActiveForm = (e, activeForm) => {
    e.preventDefault();
    this.setState({
      activeForm,
    });
  };

  // eslint-disable-next-line max-lines-per-function, complexity
  renderForm = () => {
    const { activeForm } = this.state;
    const {
      handleChange,
      handleResetPassword,
      formState,
      handleGolferChange,
      onChangeBirthday,
      handleInputChange,
      isProfile,
      isCurrentUser,
      isCoach,
      current_user,
      isFamilyAdmin,
      user,
      error,
    } = this.props;
    switch (activeForm) {
      case "personal":
        return (
          <div className='col-md-8 p-5 personal-form-container animated fadeIn'>
            <div className='row'>
              <h4 className='col-md-12'>Personal details</h4>
              <div className='col-md-6'>
                <label>First Name</label>
                <input
                  name='first_name'
                  type='text'
                  className='mb-3 form-control'
                  label='First Name'
                  onChange={handleChange}
                  placeholder='First Name'
                  value={formState.first_name}
                  disabled={
                    (isCoach && !isCurrentUser) ||
                    (!isFamilyAdmin && !isCurrentUser)
                  }
                />
              </div>
              <div className='col-md-6'>
                <label>Last Name</label>
                <input
                  name='last_name'
                  type='text'
                  className='mb-3 form-control'
                  label=''
                  onChange={handleChange}
                  placeholder='Last Name'
                  value={formState.last_name}
                  disabled={
                    (isCoach && !isCurrentUser) ||
                    (!isFamilyAdmin && !isCurrentUser)
                  }
                />
              </div>
            </div>

            <div className='row'>
              {user.username ? (
                <div className='col'>
                  <label>Username</label>
                  <TextInput
                    name={"username"}
                    type={"text"}
                    label={""}
                    value={formState.username}
                    onChange={handleChange}
                    className='mb-3 form-control'
                    placeholder={""}
                    disabled={
                      (isCoach && !isCurrentUser) ||
                      (!isFamilyAdmin && !isCurrentUser)
                    }
                    error={error && error.username && error.username}
                  />
                </div>
              ) : (
                <div className='col'>
                  <label>Email</label>
                  <TextInput
                    name={"email"}
                    type={"email"}
                    label={""}
                    value={formState.email}
                    onChange={handleChange}
                    className='mb-3 form-control'
                    placeholder={""}
                    disabled={
                      (isCoach && !isCurrentUser) ||
                      (!isFamilyAdmin && !isCurrentUser)
                    }
                    error={error && error.email && error.email}
                  />
                </div>
              )}
            </div>

            <div className='row'>
              <div className='col'>
                <label htmlFor='phone'>Phone (choose country)*</label>
                <TelephoneInput
                  defaultCountry='us'
                  flagsImagePath={flags}
                  onChange={handleInputChange}
                  value={formState.phone}
                  disabled={
                    (isCoach && !isCurrentUser) ||
                    (!isFamilyAdmin && !isCurrentUser)
                  }
                />
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label htmlFor='confirm_password'>Gender*</label>
                <select
                  onChange={handleChange}
                  name='gender'
                  value={formState.gender}
                  className={`form-control ${
                    formState.gender === "unselected" && "default-option"
                  }`}
                  disabled={
                    (isCoach && !isCurrentUser) ||
                    (!isFamilyAdmin && !isCurrentUser)
                  }
                >
                  <option value={"unselected"} disabled>
                    Select a gender
                  </option>
                  <option value={1}>Male</option>
                  <option value={2}>Female</option>
                  <option value={3}>Other</option>
                </select>
              </div>
            </div>

            <div>
              <label>Birthdate</label>
              <div className='row'>
                <div className='col-3'>
                  <small>
                    <label>Month</label>
                  </small>
                  <select
                    onChange={onChangeBirthday}
                    name='month'
                    value={formState.birth_month}
                    className={`form-control ${
                      formState.birth_month === "" && "default-option"
                    }`}
                    disabled={
                      (isCoach && !isCurrentUser) ||
                      (!isFamilyAdmin && !isCurrentUser)
                    }
                  >
                    <option value='-1' disabled>
                      Select a month
                    </option>
                    {_.range(1, 13).map(function (x) {
                      return <option key={x}>{x}</option>;
                    })}
                  </select>
                </div>

                <div className='col-3'>
                  <small>
                    <label>Day</label>
                  </small>
                  <select
                    onChange={onChangeBirthday}
                    name='day'
                    value={formState.birth_day}
                    className={`form-control ${
                      formState.birth_day === "" && "default-option"
                    }`}
                    disabled={
                      (isCoach && !isCurrentUser) ||
                      (!isFamilyAdmin && !isCurrentUser)
                    }
                  >
                    <option value='-1' disabled>
                      Select a day
                    </option>
                    {_.range(1, 32).map((x) => {
                      return <option key={x}>{x}</option>;
                    })}
                  </select>
                </div>
                <div className='col-3'>
                  <small>
                    <label>Year</label>
                  </small>
                  <select
                    onChange={onChangeBirthday}
                    name='year'
                    value={formState.birth_year}
                    className={`form-control ${
                      formState.birth_year === "" && "default-option"
                    }`}
                    disabled={
                      (isCoach && !isCurrentUser) ||
                      (!isFamilyAdmin && !isCurrentUser)
                    }
                  >
                    <option value='-1' disabled>
                      Select a Year
                    </option>
                    {displayYearOptions()}
                  </select>
                </div>
              </div>
            </div>
          </div>
        );
      case "profile":
        return (
          <div className='col-md-8 p-5 personal-form-container animated fadeIn'>
            <div className='row'>
              <h4 className='col-md-12'>Profile details</h4>
            </div>
            <div className='row'>
              <div className='col'>
                <label>Headline</label>
                <input
                  name='headline'
                  type='headline'
                  className='mb-3 form-control'
                  label=''
                  onChange={handleChange}
                  placeholder="What's your tagline?"
                  value={formState.headline || ''}
                  disabled={
                    (isCoach && !isCurrentUser) ||
                    (!isFamilyAdmin && !isCurrentUser)
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label>About you</label>
                <textarea
                  name='bio'
                  type='text'
                  className='form-control'
                  rows='3'
                  label=''
                  onChange={handleChange}
                  placeholder='Enter some information about yourself'
                  value={formState.bio}
                  disabled={
                    (isCoach && !isCurrentUser) ||
                    (!isFamilyAdmin && !isCurrentUser)
                  }
                />
              </div>
            </div>
            {isCoach && isCurrentUser && (
              <div>
                <div className='row'>
                  <div className='col'>
                    <label>Facebook URL</label>
                    <input
                      name='facebook'
                      type='text'
                      className='mb-3 form-control'
                      label=''
                      onChange={handleChange}
                      placeholder='facebook'
                      value={formState.facebook || ""}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <label>Twitter URL</label>
                    <input
                      name='twitter'
                      type='text'
                      className='mb-3 form-control'
                      label=''
                      onChange={handleChange}
                      placeholder='twitter'
                      value={formState.twitter || ""}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <label>Instagram URL</label>
                    <input
                      name='insta'
                      type='text'
                      className='mb-3 form-control'
                      label=''
                      onChange={handleChange}
                      placeholder='instagram'
                      value={formState.insta || ""}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className='row'>
              <div className='col d-flex flex-column'>
                <label>Active Golfer</label>
                <div className='pl-2'>
                  <Toggle
                    checked={formState.is_golfer}
                    onChange={handleGolferChange}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "booking":
        return (
          <div className='col-md-8 p-5 personal-form-container animated fadeIn'>
            <div className='row'>
              <h4 className='col-md-12'>Booking details</h4>
            </div>
            <label>Booking URL</label>
            <input
              name='booking_url'
              type='text'
              className='mb-3 form-control'
              label=''
              onChange={handleChange}
              placeholder='Enter booking url'
              value={formState.booking_url}
            />
          </div>
        );
      case "password":
        if (
          current_user.type === "Coach" &&
          isProfile &&
          !isFamilyAdmin &&
          !isCurrentUser
        ) {
          return (
            <div className='col-md-8 p-5 personal-form-container animated fadeIn'>
              <div className='row'>
                <h4 className='col-md-12'>Request To Change Password</h4>
              </div>
              <button
                onClick={handleResetPassword}
                className='btn mt-1 btn-sm mx-auto btn-dark m-4'
                disabled={formState.resetEmailSent}
              >
                <i className='fa fa-key' aria-hidden='true' /> Send Password
                Reset Email
              </button>
            </div>
          );
        } else {
          return (
            <div className='col-md-8 p-5 personal-form-container animated fadeIn'>
              <div className='row'>
                <h4 className='col-md-12'>Change Your Password</h4>
              </div>
              <label>Current Password</label>
              <input
                name='current_password'
                type='password'
                autoComplete='current-password'
                className='mb-3 form-control'
                label=''
                onChange={handleChange}
                placeholder='Current Password'
                value={formState.current_password}
                disabled={
                  (isCoach && !isCurrentUser) ||
                  (!isFamilyAdmin && !isCurrentUser)
                }
              />

              <label>New Password</label>
              <input
                name='password'
                type='password'
                autoComplete='new-password'
                className='mb-3 form-control'
                label=''
                onChange={handleChange}
                placeholder='Password'
                value={formState.password}
                disabled={
                  (isCoach && !isCurrentUser) ||
                  (!isFamilyAdmin && !isCurrentUser)
                }
              />

              <label>Confirm New Password</label>
              <input
                name='password_confirmation'
                type='password'
                autoComplete='new-password'
                className='mb-3 form-control'
                label=''
                onChange={handleChange}
                placeholder='Confirm Password'
                value={formState.password_confirmation}
                disabled={
                  (isCoach && !isCurrentUser) ||
                  (!isFamilyAdmin && !isCurrentUser)
                }
              />
            </div>
          );
        }
      case 'account-settings':
        return <AccountSettings userId={this.props.user.id} />

      default:
        return null;
    }
  };

  render() {
    const {
      handleAvatarChange,
      formState,
      user,
      errors,
      toggleAvatarEditState,
      photoUploading,
      isProfile,
      isCurrentUser,
      isFamilyAdmin,
      current_user,
    } = this.props;
    return (
      <form id='AccountForm' className='form-container'>
        <div className='row'>
          <div className='col-md-4 account-sidebar'>
            <div className='row my-5 d-flex justify-content-center'>
              {photoUploading ? (
                <Loader message={"Uploading Photo"} />
              ) : (
                <div>
                  <UserAvatarForm
                    user={user}
                    preview={formState.uploadPhotoPreview}
                    handleAvatarChange={handleAvatarChange}
                    avatarEditState={formState.avatarEditState}
                    toggleAvatarEditState={toggleAvatarEditState}
                    isProfile={isProfile}
                  />
                </div>
              )}
            </div>
            <div className='account-buttons'>
              <button
                className='btn btn-link animation-shrink'
                onClick={(e) => this.toggleActiveForm(e, "personal")}
              >
                Personal
              </button>
              <button
                className='btn btn-link animation-shrink'
                onClick={(e) => this.toggleActiveForm(e, "profile")}
              >
                Profile
              </button>
              {user.type === "Coach" && (
                <button
                  className='btn btn-link animation-shrink'
                  onClick={(e) => this.toggleActiveForm(e, "booking")}
                >
                  Booking
                </button>
              )}
              <button
                className='btn btn-link animation-shrink'
                onClick={(e) => this.toggleActiveForm(e, "password")}
              >
                {current_user.type === "Coach" &&
                isProfile &&
                !isFamilyAdmin &&
                !isCurrentUser
                  ? "Reset Password"
                  : "Change Password"}
              </button>
              {user.family_id === current_user.family_id && (
                <button
                  className='btn btn-link animation-shrink'
                  onClick={(e) => this.toggleActiveForm(e, 'account-settings')}
                >
                  Account Settings
                </button>
              )}
            </div>
          </div>
          {this.renderForm()}
        </div>

        {errors && errors.length > 0 && (
          <div id='formErrors'>
            <p>
              <span className='icon icon-info' />
              {errors}
            </p>
          </div>
        )}
      </form>
    );
  }
}

AccountForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    family_id: PropTypes.number,
  }),
  current_user: PropTypes.shape({
    family_id: PropTypes.number,
  }),
}

export default AccountForm;
