import { useSelector } from 'react-redux'

import '../../assets/styles/components/classes/ClassesPage.scss'
import { checkLicensePermission } from '../../utility/communityLicense'

import CoachClassesPage from './CoachClassesPage'
import ParentClassesPage from './ParentClassesPage'
import UnavailableClassesPage from './UnavailableClassesPage'

const ClassesPage = () => {
  const current_user = useSelector(state => state.user.current_user)

  const community = current_user?.communities[0]
  const permittedLicenseType = checkLicensePermission(community?.license_type, ['standard', 'standard_legacy'])
  const stripePaymentsReady = community?.selected_stripe_connected_account && community?.available_packages_count > 0
  const coachPage = ['Coach', 'Admin'].includes(current_user.type)

  return (
    <div id='ClassesPage' className='animated fadeIn'>
      {coachPage
        ? permittedLicenseType || stripePaymentsReady
          ? <CoachClassesPage />
          : <UnavailableClassesPage />
        : <ParentClassesPage />
      }
    </div>
  )
}

export default ClassesPage
