import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Collapse } from 'reactstrap'
import { UncontrolledPopover } from 'reactstrap'
import PropTypes from 'prop-types'

import * as constants from '../../misc/Constants'
import SeriesRow from './SeriesRow'
import Placeholder from '../common/Placeholder'
import Loader from '../common/Op36Loader-web'
import Pagination from '../common/Pagination'
import SeriesEventRow from './SeriesEventRow'

import styles from './SeriesList.module.scss'
import colors from '../../assets/styles/globals.scss'
import { ReactComponent as GolferIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'
import classNames from 'classnames'

const SeriesList = ({
  series,
  loader,
  searchTerm,
  activePage,
  setActivePage,
  handleSeriesMenuClick,
  removeSeries,
  navigateToEdit,
  navigateToEmails,
}) => {
  const pageCount = useSelector(state => state.series?.allSeriesPagination?.totalPages)
  const [expandedSeriesId, setExpandedSeriesId] = useState(-1)

  useEffect(() => {
    if (!series?.length > 0) {
      return
    }

    setExpandedSeriesId(series[0].id)
  }, [series])

  const expandSeries = (id) => {
    if (expandedSeriesId === id) {
      setExpandedSeriesId(-1)
    } else {
      setExpandedSeriesId(id)
    }
  }

  if (loader) {
    return (
      <div className='col-md-6 mx-auto'>
        <Loader message='loading series' />
      </div>
    )
  }

  if (!series?.length) {
    if (searchTerm) {
      return (
        <div className='mt-5 w-100'>
          <Placeholder
            title={'No Series Results Found'}
            icon={'fa-search'}
            subtext1={'Nothing matched the search criteria'}
            subtext2={'Please try again'}
          />
        </div>
      )
    } else {
      return (
        <div className={styles['no-series-placeholder']}>
          <GolferIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
          <p className={styles['placeholder-title']}>No 9 Upcoming Events</p>
          <div className={styles['placeholder-description']}>{constants.EVENTS_PLACEHOLDER}</div>
        </div>
      )
    }
  }

  return (
    <>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          activePage={activePage}
          fetchData={(newPage) => setActivePage(newPage)}
        />
      )}
      {series.map((series, index) => {
        const isExpanded = series.id === expandedSeriesId
        return (
          <div className={classNames('row mx-0', styles['series-group'], isExpanded && styles['expanded'])} key={series.id}>
            <SeriesRow
              series={series}
              expandSeries={expandSeries}
              isFirstChild={index === 0}
              handleSeriesMenuClick={handleSeriesMenuClick}
            />
            <Collapse className='w-100' isOpen={isExpanded}>
              {series.events.map((e, index) => <SeriesEventRow key={e.id} event={{ ...e, index: index }}/>)}
            </Collapse>
            <UncontrolledPopover
              placement='bottom'
              target='seriesMenuButton'
              trigger='legacy'
            >
              <div className='d-flex flex-column build-program-actions'>
                <p aria-hidden='true' onClick={() => navigateToEdit(1)}>Edit Event Series</p>
                <p aria-hidden='true' onClick={() => navigateToEdit(2)}>Edit Series Schedule</p>
                <p aria-hidden='true' onClick={() => navigateToEdit(3)}>Edit Master Roster</p>
                <p aria-hidden='true' onClick={() => navigateToEmails()}>Send an Email</p>
                <p aria-hidden='true' onClick={() => removeSeries()}>Delete Event Series</p>
              </div>
            </UncontrolledPopover>
          </div>
        )
      }
      )}
    </>
  )
}

SeriesList.propTypes = {
  series: PropTypes.array,
  loader: PropTypes.bool,
  searchTerm: PropTypes.string,
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
  handleSeriesMenuClick: PropTypes.func,
  navigateToEdit: PropTypes.func,
  removeSeries: PropTypes.func,
  navigateToEmails: PropTypes.func,
}

export default SeriesList
