import { useState, forwardRef, useEffect, useCallback } from 'react'
import { Popover } from 'reactstrap'
import classnames from 'classnames'
import Select from '../../common/form/Select'
import DatePicker from 'react-datepicker'
import { ReactComponent as FilterIcon } from '../../../assets/images/common/icons/filter-icon.svg'
import { ReactComponent as DropDownIcon } from '../../../assets/images/common/icons/select-drop-down-icon-light-navy.svg'
import styles from './LeaderboardFilters.module.scss'
import './LeaderboardFiltersCalendar.scss'
import SubmitButton from '../../common/buttons/SubmitButton'
import { useDispatch, useSelector } from 'react-redux'
import { getCommunityCategories } from '../../../actions/programCategoryActions'
import { setLeaderboardFilters } from '../../../reducers/dashboardReducer'
import { leaderboardTabs } from '../../../misc/Constants'
import { getCommunityLeaderboardRanking } from '../../../actions/dashboardActions'

// eslint-disable-next-line react/prop-types
const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <button className={styles.datePickerInput} onClick={onClick} ref={ref}>
    {value}
    <DropDownIcon/>
  </button>
))

DatePickerInput.displayName = 'DatePickerInput'
const PER_PAGE = 6
const LeaderboardFilters = () => {
  const currentDate = new Date()
  const dispatch = useDispatch()
  const {
    leaderboardFilters,
    communityCategories,
    tabIndex,
    clearFiltersCounter,
  } = useSelector(state => state.dashboard)
  const communityId = useSelector(state => state.user.current_user.communities[0].id)

  const [isFiltersMenuOpen, setFiltersMenuOpen] = useState(false)
  const [startDate, setStartDate] = useState(leaderboardFilters.startDate)
  const [endDate, setEndDate] = useState(leaderboardFilters.endDate)
  const [category, setCategory] = useState(null)
  const [fixedRange, setFixedRange] = useState('Last 30 Days')
  const [filtersTitle, setFiltersTitle] = useState('Last 30 Days')
  const [extraFiltersCounter, setExtraFiltersCounter] = useState(0)
  const [savedFiltersCounter, setSavedFiltersCounter] = useState(clearFiltersCounter)

  useEffect(() => {
    dispatch(getCommunityCategories())
  }, [dispatch])

  const handleOpenFiltersMenuClick = () => {
    setFiltersMenuOpen(value => !value)
  }

  const handleFixedRangeChanged = (range, rangeText) => {
    if (range > 90){
      setStartDate(new Date(range, 0, 1))
      setEndDate(new Date(range, 11, 31))
    } else {
      const rangeDate = new Date()
      setStartDate(new Date(rangeDate.setDate(rangeDate.getDate() - range)))
      setEndDate(currentDate)
    }
    setFixedRange(rangeText)
  }

  const handleStartDateChanged = (date) => {
    setFixedRange(null)
    setStartDate(date)
  }

  const handleEndDateChanged = (date) => {
    setFixedRange(null)
    setEndDate(date)
  }

  const handleCategoryChanged = (option) => {
    setExtraFiltersCounter(1)
    setCategory(option)
  }

  const handleApplyFilters = () => {
    const filters = {
      startDate: startDate,
      endDate: endDate,
      categoryId: category?.value,
    }
    setFiltersTitle(fixedRange ? fixedRange : `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`)
    handleFiltersChanged(filters)
  }

  const handleFiltersChanged = useCallback((filters) => {
    dispatch(setLeaderboardFilters(filters))
    dispatch(getCommunityLeaderboardRanking({
      communityId,
      filters: { ...filters, activityTypes: leaderboardTabs[tabIndex] },
      page: 1,
      perPage: PER_PAGE,
      tabIndex,
    }))
    setFiltersMenuOpen(false)
  }, [communityId, dispatch, tabIndex])

  const handleClearFilters = useCallback(() => {
    const rangeDate = new Date()
    const startDate = new Date(rangeDate.setDate(rangeDate.getDate() - 30))
    const endDate = new Date()
    setCategory(null)
    setStartDate(startDate)
    setEndDate(endDate)
    setFixedRange('Last 30 Days')
    setFiltersTitle('Last 30 Days')
    setExtraFiltersCounter(0)
    const filters = {
      startDate,
      endDate,
    }
    handleFiltersChanged(filters)
  }, [handleFiltersChanged])

  useEffect(() => {
    if (clearFiltersCounter !== savedFiltersCounter) {
      setSavedFiltersCounter(clearFiltersCounter)
      handleClearFilters()
    }
  }, [clearFiltersCounter, handleClearFilters, savedFiltersCounter])

  return (
    <>
      <button
        id='leaderboardFiltersButton'
        className={classnames('flex-grow-1 flex-lg-grow-0 d-flex align-items-center', styles.filtersButton)}
        onClick={handleOpenFiltersMenuClick}
      >
        <FilterIcon className='mx-2' />
        {filtersTitle}
        {extraFiltersCounter > 0 && <span className={styles.extraFilter}>+ {extraFiltersCounter} filter(s)</span>}
      </button>
      <Popover
        target='leaderboardFiltersButton'
        isOpen={isFiltersMenuOpen}
        placement='auto'
        trigger='legacy'
        toggle={handleOpenFiltersMenuClick}
        popperClassName={styles.filtersMenu}
      >
        <div className={styles.filtersBody}>
          <div className={styles.fixedRange}>
            <div className={styles.filtersTitle}>Fixed Range</div>
            <button className={styles.rangeButton} onClick={(e) => handleFixedRangeChanged(90, e.target.innerHTML)} >Last 90 Days</button>
            <button className={styles.rangeButton} onClick={(e) => handleFixedRangeChanged(60, e.target.innerHTML)}>Last 60 Days</button>
            <button className={styles.rangeButton} onClick={(e) => handleFixedRangeChanged(30, e.target.innerHTML)}>Last 30 Days</button>
            <button className={styles.rangeButton} onClick={(e) => handleFixedRangeChanged(20, e.target.innerHTML)}>Last 20 Days</button>
            <button className={styles.rangeButton} onClick={(e) => handleFixedRangeChanged(currentDate.getFullYear(), e.target.innerHTML)}>
              This Year ({currentDate.getFullYear()})
            </button>
            <button className={styles.rangeButton} onClick={(e) => handleFixedRangeChanged(currentDate.getFullYear() - 1, e.target.innerHTML)}>
              Last Year ({currentDate.getFullYear() - 1})
            </button>
          </div>
          <div className={styles.filters}>
            <div className={styles.filtersTitle}>
              Leaderboard Filters
              <button className={styles.closeButton} onClick={() => setFiltersMenuOpen(false)}>
                <i className='ml-4 fa fa-close' />
              </button>
            </div>
            <div className={styles.dates}>
              <div>
                <label className={styles.dateLabel} htmlFor='startDatePicker'>Start Date</label>
                <DatePicker
                  id='startDatePicker'
                  selected={startDate}
                  customInput={<DatePickerInput/>}
                  onChange={(date) => handleStartDateChanged(date)}
                  dateFormat='MMMM, d eee'
                />
              </div>
              <div>
                <label className={styles.dateLabel} htmlFor='endDatePicker'>End Date</label>
                <DatePicker
                  id='endDatePicker'
                  selected={endDate}
                  customInput={<DatePickerInput/>}
                  onChange={(date) => handleEndDateChanged(date)}
                  dateFormat='MMMM, d eee'
                />
              </div>
            </div>
            <div>
              <label className={styles.dateLabel} htmlFor='categorySelect'>Program Category</label>
              <Select
                id='categorySelect'
                isSearchable={false}
                placeholder='Choose a Program Category'
                options={communityCategories}
                value={category}
                classNames={{
                  menuList: () => styles.selectMenuList,
                }}
                onChange={option => handleCategoryChanged(option)}
                isClearable
              />
            </div>
            <div className={styles.buttons}>
              <button
                className={classnames('ml-3 w-100 mt-3 animation-shrink d-flex align-items-center justify-content-center', styles.resetFiltersButton)}
                onClick={handleClearFilters}
              >
                Clear <i className='ml-4 fa fa-close' />
              </button>
              <SubmitButton buttonMessage='Apply' className='mt-3' onClick={handleApplyFilters}/>
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default LeaderboardFilters
