import * as types from "./actionTypes";
import * as common from "./commonActions";
import * as Constants from "../misc/Constants.js";

import Axios from '../axios'

// export function like_activity(activityId){
//   return { type: types.POST_ACTIVITY_LIKE, activityId: activityId };
// }

// export function addCommentToActivity(activityId, user, comment){
//   return { type: types.POST_ACTIVITY_COMMENT, activityId: activityId, user: user, comment: comment };
// }

export function successRequestingActivities(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_COURSE_ACTIVITIES,
      activities: response.data.activities,
      pagination: response.data.meta.pagination,
    };
  } else {
    console.log("ERROR ON GETTING COURSE ACTIVITIES");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successRequestingActivity(response) {
  if (response.status === 200) {
    return { type: types.GET_ACTIVITY, activity: response.data.activity };
  } else {
    console.log("ERROR ON GETTING ACTIVITY");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function succcessDeletingActivity(response) {
  if (response.status === 201) {
    return { type: types.DELETE_ACTIVITY, activity: response.data.activity };
  } else {
    console.log("ERROR DELETING GOAL");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successRequestingActivityLikes(response) {
  if (response.status === 200) {
    return { type: types.ADD_ACTIVITY_LIKES, likes: response.data.likes };
  } else {
    console.log("ERROR ON ACTIVITY LIKES");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successRequestingActivityComments(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_ACTIVITY_COMMENTS,
      comments: response.data.comments,
    };
  } else {
    console.log("ERROR ON ACTIVITY COMMENTS");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successPostingActivityLike(response, activity) {
  if (response.status === 201) {
    return {
      type: types.POST_ACTIVITY_LIKE,
      like: response.data.like,
      activity: activity,
    };
  } else {
    console.log("ERROR ON POSTING ACTIVITY LIKE");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateActivity(response) {
  if (response.status === 201) {
    return { type: types.UPDATE_ACTIVITY, activity: response.data.activity };
  } else {
    console.log("ERROR ON UPDATING ACTIVITY");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateTrainActivity(response) {
  if (response.status === 201) {
    return { type: types.UPDATE_ACTIVITY, activity: response.data.activity };
  } else {
    console.log("ERROR ON UPDATING ACTIVITY");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successDeletingActivityLike(response, activityId) {
  if (response.status === 201) {
    return { type: types.DELETE_ACTIVITY_LIKE, activity_id: activityId };
  } else {
    console.log("ERROR ON DELETING ACTIVITY LIKE");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successPostingActivityComment(response, activity) {
  if (response.status === 201) {
    return {
      type: types.POST_ACTIVITY_COMMENT,
      comment: response.data.comment,
      activity: activity,
    };
  } else {
    console.log("ERROR ON POSTING ACTIVITY COMMENT");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successDeletingActivityComment(
  response,
  activityId,
  commentId
) {
  if (response.status === 204) {
    return {
      type: types.DELETE_ACTIVITY_COMMENT,
      activity_id: activityId,
      comment_id: commentId,
    };
  } else {
    console.log("ERROR ON DELETING ACTIVITY COMMENT");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successPostingActivityPlay(response) {
  if (response.status === 201) {
    return {
      type: types.POST_COURSE_ACTIVITY,
      activity: response.data.activity,
    };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successPostingActivity(response) {
  if (response.status === 201) {
    return {
      type: types.POST_COURSE_ACTIVITY,
      activity: response.data.activity,
    };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successPostingActivities(response) {
  if (response.status === 201) {
    return { type: types.POST_COURSE_ACTIVITY, activity: response.data };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getCourseActivities(
  courseId,
  page,
  perPage = Constants.PER_PAGE_DEFAULT,
  sinceTS
) {
  return function (dispatch) {
    if (page <= 1) {
      // Don't show if past the first page
      // CALL LOADING CHANGED TO TRUE
      dispatch(common.loadingChanged(true));
    }
    return requestCourseActivities(courseId, page, perPage)
      .then((response) => dispatch(successRequestingActivities(response)))
      .then(
        // AFTER COMPLETION OF RESPONSE, STOP SPINNER
        //if(page <= 1){
        // CALL LOADING CHANGED TO TRUE
        (response) => dispatch(common.loadingChanged(false))
        //}
      );
  };
}

export function getFamilyActivities(
  familyId,
  page,
  perPage = Constants.PER_PAGE_DEFAULT,
) {
  return function (dispatch) {
    if (page <= 1) {
      // Don't show if past the first page
      // CALL LOADING CHANGED TO TRUE
      dispatch(common.loadingChanged(true));
    }
    return requestFamilyActivities(familyId, page, perPage)
      .then((response) => dispatch(successRequestingActivities(response)))
      .then(
        // AFTER COMPLETION OF RESPONSE, STOP SPINNER
        //if(page <= 1){
        // CALL LOADING CHANGED TO TRUE
        (response) => dispatch(common.loadingChanged(false))
        //}
      );
  };
}

export function getCommunityActivities(
  communityId,
  page,
  perPage = Constants.PER_PAGE_DEFAULT,
  sinceTS
) {
  return function (dispatch) {
    if (page <= 1) {
      // Don't show if past the first page
      // CALL LOADING CHANGED TO TRUE
      dispatch(common.loadingChanged(true));
    }
    return requestCommunityActivities(communityId, page, perPage)
      .then((response) => dispatch(successRequestingActivities(response)))
      .then(
        // AFTER COMPLETION OF RESPONSE, STOP SPINNER
        //if(page <= 1){
        // CALL LOADING CHANGED TO TRUE
        (response) => dispatch(common.loadingChanged(false))
        //}
      );
  };
}

export function getActivity(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestActivity(activityId)
      .then((response) => dispatch(successRequestingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function updateActivity(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateActivity(activity)
      .then((response) =>
        dispatch(successRequestingUpdateActivity(response, activity))
      )
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function deleteActivity(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestDeleteActivity(activity).then((response) =>
      dispatch(succcessDeletingActivity(response))
    );
  };
}

export function updateTrainActivity(activity, trainSession) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateTrainActivity(activity, trainSession)
      .then((response) =>
        dispatch(successRequestingUpdateTrainActivity(response, activity))
      )
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function getActivityLikes(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestActivityLikes(activityId)
      .then((response) => dispatch(successRequestingActivityLikes(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function getActivityComments(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestActivityComments(activityId)
      .then((response) => dispatch(successRequestingActivityComments(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityLike(activityId, activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityLike(activityId)
      .then((response) =>
        dispatch(successPostingActivityLike(response, activity))
      )
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function deleteActivityLike(activityId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestDeleteActivityLike(activityId)
      .then((response) =>
        dispatch(successDeletingActivityLike(response, activityId))
      )
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityComment(activityId, text, activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityComment(activityId, text)
      .then((response) =>
        dispatch(successPostingActivityComment(response, activity))
      )
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function deleteActivityComment(comment) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestDeleteActivityComment(comment.id)
      .then((response) => dispatch(successDeletingActivityComment(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivitySocial(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivitySocial(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function coachPostActivitySocial(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCoachPostActivitySocial(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityPlay(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityPlay(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function coachPostActivityPlay(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCoachPostActivityPlay(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityTrain(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityTrain(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function coachPostActivityTrain(activity, trainSession) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCoachPostActivityTrain(activity, trainSession)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityObjective(activity, userCurriculum) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityObjective(activity, userCurriculum)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityObjectives(rank_id, objective_id, users) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityObjectives(rank_id, objective_id, users)
      .then((response) => dispatch(successPostingActivities(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityRank(activity, userCurriculum) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityRank(activity, userCurriculum)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityAnnouncement(activity, photo) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityAnnouncement(activity, photo)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postWebActivityAnnouncement(activity, photo) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostWebActivityAnnouncement(activity, photo)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function postActivityGoal(activity) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPostActivityGoal(activity)
      .then((response) => dispatch(successPostingActivity(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

// // API CALLS
function requestCourseActivities(courseId, page, perPage, sinceTS) {
  let requestUrl = "/api/v1/courses/" + courseId + "/activities";
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
      web: true,
    },
  });
}

function requestFamilyActivities(familyId, page, perPage, sinceTS) {
  let requestUrl = "/api/v1/families/" + familyId + "/activities";
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
    },
  });
}

function requestCommunityActivities(communityId, page, perPage, sinceTS) {
  let requestUrl = "/api/v1/communities/" + communityId + "/activities";
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
    },
  });
}

function requestActivity(activityId) {
  let requestUrl = "/api/v1/activities/" + activityId;
  return Axios.get(requestUrl);
}

function requestUpdateActivity(activity) {
  let requestUrl = "/api/v1/activities/" + activity.activityId;

  var data = new FormData();

  if (activity.yardage === "fullTee") {
    data.append("activity[yardage]", activity.fullTeeYardage);
    data.append("activity[is_full_tee]", true);
  } else {
    data.append("activity[yardage]", activity.yardage);
    data.append("activity[is_full_tee]", false);
  }

  data.append("activity[play_type_id]", activity.format);
  data.append("activity[user_id]", activity.userId);
  data.append("activity[score]", activity.score);
  data.append('activity[greens]', activity.greens || '')
  data.append('activity[putts]', activity.putts || '')
  data.append("activity[location]", activity.location);
  data.append("activity[holes]", activity.holes);
  data.append("activity[activity_date]", activity.activityDate);

  return Axios.put(requestUrl, data);
}

function requestDeleteActivity(activity) {
  let requestUrl = "/api/v1/activities/" + activity.id;
  return Axios.delete(requestUrl);
}

function requestUpdateTrainActivity(activity, trainSession) {
  let requestUrl = "/api/v1/activities/" + activity.activityId;

  var data = new FormData();
  data.append("activity[user_id]", activity.userId);
  data.append("activity[activity_date]", activity.activityDate);
  // Iterate through each 'trainSession' and add it to our data

  data.append("activity[practice_sessions]", JSON.stringify(trainSession));

  return Axios.put(requestUrl, data);
}

function requestActivityLikes(activityId) {
  let requestUrl = "/api/v1/activities/" + activityId + "/likes";
  return Axios.get(requestUrl);
}

function requestActivityComments(activityId) {
  let requestUrl = "/api/v1/activities/" + activityId + "/comments";
  return Axios.get(requestUrl);
}

function requestPostActivityLike(activityId) {
  let requestUrl = "/api/v1/activities/" + activityId + "/like";
  return Axios.post(requestUrl);
}

function requestDeleteActivityLike(activityId) {
  let requestUrl = "/api/v1/activities/" + activityId + "/like";
  return Axios.delete(requestUrl);
}

function requestPostActivityComment(activityId, text) {
  let requestUrl = "/api/v1/comments";
  return Axios.post(requestUrl, {
    activity_id: activityId,
    text: text,
  });
}

function requestDeleteActivityComment(commentId) {
  let requestUrl = "/api/v1/comments/" + commentId;
  return Axios.delete(requestUrl);
}

function requestPostActivitySocial(activity) {
  let requestUrl = "/api/v1/activity_social";

  var data = new FormData();
  data.append("activity[photo]", activity.uploadPhotoFile);
  data.append("activity[objective_id]", activity.objectiveId);
  data.append("activity[caption]", activity.description);

  return Axios.post(requestUrl, data);
}

function requestCoachPostActivitySocial(activity) {
  let requestUrl = "/api/v1/activity_social";

  var data = new FormData();
  data.append("activity[photo]", activity.uploadPhotoFile);
  data.append("activity[objective_id]", activity.objectiveId);
  data.append("activity[caption]", activity.description);

  return Axios.post(requestUrl, data);
}

function requestCoachPostActivityPlay(activity) {
  let requestUrl = "/api/v1/activity_play";

  var data = new FormData();
  // data.append("activity[street]", activity.location)

  if (activity.yardage === "fullTee") {
    data.append("activity[yardage]", activity.fullTeeYardage);
    data.append("activity[is_full_tee]", true);
  } else {
    data.append("activity[yardage]", activity.yardage);
    data.append("activity[is_full_tee]", false);
  }

  if (!activity.activityDate) activity.activityDate = new Date();

  if (activity.shareScore) {
    data.append("activity[share_score]", true);
  }

  data.append("activity[user_id]", activity.userId);
  data.append("activity[play_type_id]", activity.format);
  data.append("activity[caption]", activity.description);
  data.append("activity[score]", activity.score);
  data.append("activity[greens]", activity.greens);
  data.append("activity[putts]", activity.putts);
  data.append("activity[location]", activity.location);
  data.append("activity[holes]", activity.holes);
  data.append("activity[activity_date]", activity.activityDate);

  return Axios.post(requestUrl, data);
}

function requestPostActivityPlay(activity) {
  let requestUrl = "/api/v1/activity_play";

  var data = new FormData();
  if (activity.yardageSelectedOption === "fullTee") {
    data.append("activity[yardage]", activity.fullTeeYardage);
    data.append("activity[is_full_tee]", true);
  } else {
    data.append("activity[yardage]", activity.yardageSelectedOption);
    data.append("activity[is_full_tee]", false);
  }

  if (activity.shareScore) {
    data.append("activity[share_score]", true);
  }

  if (!activity.activityDate) activity.activityDate = new Date();

  // data.append("activity[street]", activity.location)
  data.append("activity[photo]", activity.uploadPhotoFile);
  data.append("activity[play_type_id]", activity.gameTypeSelectedOption);
  data.append("activity[caption]", activity.description);
  data.append("activity[score]", activity.score);
  data.append("activity[greens]", activity.greens);
  data.append("activity[putts]", activity.putts);
  data.append("activity[location]", activity.location);

  data.append("activity[holes]", activity.holesSelectedOption);

  return Axios.post(requestUrl, data);
}

function requestPostActivityTrain(activity) {
  let requestUrl = "/api/v1/activity_practice";

  var data = new FormData();
  data.append("activity[photo]", activity.uploadPhotoFile);
  data.append("activity[caption]", activity.description);

  if (!activity.activityDate) activity.activityDate = new Date();

  // Iterate through each 'trainSession' and add it to our data
  var trainSession = activity.trainSession;
  var i = 0;
  for (var key in trainSession) {
    if (trainSession.hasOwnProperty(key)) {
      var practiceTypeId = trainSession[key]["type"];
      var duration = trainSession[key]["duration"];

      data.append("activity[sessions][" + i + "][duration]", duration);
      data.append(
        "activity[sessions][" + i + "][practice_type_id]",
        practiceTypeId
      );
      i++;
    }
  }

  return Axios.post(requestUrl, data);
}

function requestCoachPostActivityTrain(activity, trainSession) {
  let requestUrl = "/api/v1/activity_practice";

  console.log({ trainSession });

  var data = new FormData();

  if (!activity.activityDate) activity.activityDate = new Date();

  data.append("activity[user_id]", activity.userId);
  data.append("activity[caption]", activity.description);
  data.append("activity[activity_date]", activity.activityDate);

  // Iterate through each 'trainSession' and add it to our data
  var i = 0;
  for (var key in trainSession) {
    if (trainSession.hasOwnProperty(key)) {
      var practiceTypeId = trainSession[key]["type"];
      var duration = trainSession[key]["duration"];
      if (duration > 0) {
        data.append("activity[sessions][" + i + "][duration]", duration);
        data.append(
          "activity[sessions][" + i + "][practice_type_id]",
          practiceTypeId
        );
        i++;
      }
    }
  }

  return Axios.post(requestUrl, data);
}

function requestPostActivityObjective(activity, userCurriculum) {
  let requestUrl = "/api/v1/activity_objective";

  var data = new FormData();
  //data.append("activity[rank_id]", activity.currentEnrollment.current_rank_id)

  data.append("activity[rank_id]", userCurriculum.rank_id);
  data.append("activity[objective_id]", activity.issueTrophyFormObjectiveId);
  data.append("activity[user_id]", activity.issueTrophyFormUserId);

  return Axios.post(requestUrl, data);
}

function requestPostActivityObjectives(rank_id, objective_id, users) {
  let requestUrl = "/api/v1/activity_objectives";

  var data = new FormData();
  //data.append("activity[rank_id]", activity.currentEnrollment.current_rank_id)
  data.append("rank_id", rank_id);
  data.append("objective_id", objective_id);
  data.append("users", users);

  return Axios.post(requestUrl, data);
}

function requestPostActivityRank(activity, userCurriculum) {
  let requestUrl = "/api/v1/activity_rank";

  var data = new FormData();
  //data.append("activity[rank_id]", activity.currentEnrollment.current_rank_id);
  data.append("activity[rank_id]", userCurriculum.rank_id);
  data.append("activity[user_id]", userCurriculum.user_id);

  return Axios.post(requestUrl, data);
}

function requestPostActivityAnnouncement(announcement, photo) {
  let requestUrl = "/api/v1/activity_announcement";

  var data = new FormData();
  data.append("activity[announcement_id]", announcement.id);
  data.append("activity[user_id]", announcement.coach.id);
  data.append("activity[caption]", announcement.message);
  data.append("activity[photo]", photo);

  return Axios.post(requestUrl, data);
}

function requestPostWebActivityAnnouncement(announcement) {
  let requestUrl = "/api/v1/activity_announcement";

  var data = new FormData();
  data.append("activity[announcement_id]", announcement.id);
  data.append("activity[user_id]", announcement.coach.id);
  data.append("activity[caption]", announcement.message);

  return Axios.post(requestUrl, data);
}

function requestPostActivityGoal(announcement) {
  let requestUrl = "/api/v1/activity_goal";

  var data = new FormData();
  data.append("activity[goal_id]", announcement.goalId);
  data.append("activity[user_id]", announcement.userId);

  return Axios.post(requestUrl, data);
}
