import ReactTelephoneInput from 'react-telephone-input'
import 'react-telephone-input/css/default.css'

const TelephoneInput = (props) => {

  return (
    <ReactTelephoneInput
      {...props}
    />
  )
}

export default TelephoneInput
