import PropTypes from 'prop-types'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'

import StudentWithAvatar from '../StudentWithAvatar'

import './StudentCard.scss'

const StudentCard = ({ student, onXClick }) => (
  <div className='student-card-wrapper my-2 mx-3'>
    <div className='p-2 d-flex align-items-center student-data'>
      <StudentWithAvatar student={student} clickable/>
    </div>
    <div
      className='x-button-wrapper'
      role='button'
      tabIndex={0}
      onClick={() => onXClick(true, student.user_id)}
      onKeyDown={null}
    >
      <XIcon fill='#fff' />
    </div>
  </div>
)

StudentCard.propTypes = {
  student: PropTypes.object.isRequired,
  onXClick: PropTypes.func.isRequired,
}

export default StudentCard
