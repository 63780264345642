import styles from './CoachDashboardOp36.module.scss'
import { ReactComponent as ApprovedSkillsIcon } from '../../../assets/images/common/icons/allTimeTotals/ApprovedSkills.svg'
import { ReactComponent as EventsIcon } from '../../../assets/images/common/icons/allTimeTotals/Events.svg'
import { ReactComponent as GolfersIcon } from '../../../assets/images/common/icons/allTimeTotals/Golfers.svg'
import { ReactComponent as LevelUpsIcon } from '../../../assets/images/common/icons/allTimeTotals/LevelUps.svg'
import { ReactComponent as StrokesIcon } from '../../../assets/images/common/icons/allTimeTotals/Strokes.svg'
import { ReactComponent as VideosWatchedIcon } from '../../../assets/images/common/icons/allTimeTotals/VideosWatched.svg'
import AllTimeTotalsItem from './AllTimeTotalsItem'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCommunityAllTimeTotals } from '../../../actions/dashboardActions'

const CoachDashboardAllTimeTotal = () => {
  const communityId = useSelector(state => state.user.current_user.communities[0].id)
  const allTimeTotals = useSelector(state => state.dashboard.allTimeTotals)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCommunityAllTimeTotals(communityId))
  }, [communityId, dispatch])

  return (
    <div className={styles.allTimeTotals}>
      <p className={styles.title}>All Time Totals</p>
      {allTimeTotals ?
        <>
          <AllTimeTotalsItem icon={<EventsIcon/>} info={allTimeTotals?.eventsLogged} title='Events Logged'/>
          <AllTimeTotalsItem icon={<StrokesIcon/>} info={allTimeTotals?.strokesLost} title='Strokes Lost'/>
          <AllTimeTotalsItem icon={<LevelUpsIcon/>} info={allTimeTotals?.levelUps} title='Level Ups'/>
          <AllTimeTotalsItem icon={<GolfersIcon/>} info={allTimeTotals?.golfersCreated} title='Golfers Created'/>
          <AllTimeTotalsItem icon={<ApprovedSkillsIcon/>} info={allTimeTotals?.coachApprovedSkills} title='Coach Approved Skills'/>
          <AllTimeTotalsItem icon={<VideosWatchedIcon/>} info={allTimeTotals?.skillsVideosWatched} title='Skills Videos Watched'/>
        </>
        :
        <div className='d-flex justify-content-center mb-5'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
        </div>
      }
    </div>
  )
}

export default CoachDashboardAllTimeTotal
