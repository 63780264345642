import styles from './CoachDashboardOp36.module.scss'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { ReactComponent as ExportIcon } from '../../../assets/images/common/communityLeaderboard/Export.svg'
import CommunityLeaderboardRanking from './CommunityLeaderboardRanking'
import { ReactComponent as CoachApprovedIcon } from '../../../assets/images/common/communityLeaderboard/CoachApproved.svg'
import { ReactComponent as TotalLevelUpsIcon } from '../../../assets/images/common/communityLeaderboard/TotalLevelUps.svg'
import { ReactComponent as TotalPointsIcon } from '../../../assets/images/common/communityLeaderboard/TotalPoints.svg'
import { ReactComponent as VideosIcon } from '../../../assets/images/common/communityLeaderboard/Videos.svg'
import { ReactComponent as TrainingSessionsIcon } from '../../../assets/images/common/communityLeaderboard/TrainingSessions.svg'
import { ReactComponent as TotalRoundsIcon } from '../../../assets/images/common/communityLeaderboard/TotalRounds.svg'
import LeaderboardFilters from './LeaderboardFilters'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { leaderboardTabs } from '../../../misc/Constants'
import { exportCommunityLeaderboardRanking, getCommunityLeaderboardRanking, getCommunityLeaderboardStats } from '../../../actions/dashboardActions'
import { incrementFiltersCounter, setTabIndex } from '../../../reducers/dashboardReducer'
import CommunityLeaderboardExport from './CommunityLeaderboardExport'
import useReportPolling from './useReportPolling'

const PER_PAGE = 6

const CoachDashboardCommunityLeaderboard = () => {
  const communityId = useSelector(state => state.user.current_user.communities[0].id)
  const dispatch = useDispatch()
  const {
    leaderboardFilters,
    leaderboardRanking,
    leaderboardStats,
    rankingTotalPages,
    rankingPages,
    tabIndex,
    leaderboardLoader,
    exportUrl,
    exportError,
    exportId,
  } = useSelector(state => state.dashboard)

  useReportPolling()

  const playingItems = [
    { icon: <TotalRoundsIcon/>, data: leaderboardStats?.totalRounds, text: 'Total Rounds' },
    { icon: <TotalPointsIcon/>, data: leaderboardStats?.totalPoints, text: 'Total Points' },
    { icon: <TotalLevelUpsIcon/>, data: leaderboardStats?.totalLevelUps, text: 'Total Level Ups' },
  ]

  const trainingItems = [
    { icon: <TotalRoundsIcon/>, data: leaderboardStats?.totalTrainingHours, text: 'Total Training Hours' },
    { icon: <TrainingSessionsIcon/>, data: leaderboardStats?.totalTrainingSessions, text: 'Total Training Sessions' },
  ]

  const curriculumItems = [
    { icon: <CoachApprovedIcon/>, data: leaderboardStats?.coachApproved, text: 'Coach Approved' },
    { icon: <VideosIcon/>, data: leaderboardStats?.videosWatched, text: 'Videos Watched' },
  ]

  const exportingLeaderboard = exportId || exportUrl || exportError
  const emptyStats = leaderboardRanking.every((arr) => arr.length === 0)

  useEffect(() => {
    dispatch(getCommunityLeaderboardStats({ communityId, filters: leaderboardFilters }))
  }, [communityId, dispatch, leaderboardFilters])

  useEffect(() => {
    leaderboardRanking[0].length === 0 && fetchRanking(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchRanking = (index) => {
    const currentTab = index ?? tabIndex
    dispatch(getCommunityLeaderboardRanking({
      communityId,
      filters: { ...leaderboardFilters, activityTypes: leaderboardTabs[currentTab] },
      page: rankingPages[currentTab],
      perPage: PER_PAGE,
      tabIndex: currentTab,
    }))
  }

  const onTabChange = (index) => {
    dispatch(setTabIndex(index))
    fetchRanking(index)
  }

  const clearFilters = () => {
    dispatch(incrementFiltersCounter())
  }

  const filtersApplied = () => {
    const rangeDate = new Date()
    const startDate = new Date(rangeDate.setDate(rangeDate.getDate() - 30))
    const endDate = new Date()
    return startDate.toLocaleDateString() !== leaderboardFilters.startDate.toLocaleDateString() ||
      endDate.toLocaleDateString() !== leaderboardFilters.endDate.toLocaleDateString()
  }

  return (
    <div className={styles.communityLeaderboard}>
      <div className={styles.leaderboardHeader}>
        <p className={styles.title}>Community Leaderboard</p>
        <LeaderboardFilters/>
      </div>
      <Tabs
        className={styles.tabs}
        selectedIndex={tabIndex}
        onSelect={onTabChange}
      >
        <TabList className={styles.tabList}>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>All Activities</Tab>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Playing</Tab>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Training</Tab>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Curriculum</Tab>
          <button
            className={`btn btn-primary pull-right mr-2 ${styles.exportButton}`}
            onClick={() => dispatch(exportCommunityLeaderboardRanking({ communityId, filters: leaderboardFilters }))}
            disabled={exportingLeaderboard || emptyStats}
          >
            Export
            <ExportIcon className={styles.exportIcon}/>
          </button>
        </TabList>
        <TabPanel className={styles.tabPanel}>
          {exportingLeaderboard ?
            <CommunityLeaderboardExport communityId={communityId}/>
            :
            <CommunityLeaderboardRanking
              students={leaderboardRanking[0]}
              fetchRanking={fetchRanking}
              hasMore={rankingPages[0] <= rankingTotalPages[0]}
              tabId={0}
              loader={leaderboardLoader}
              clearFilters={clearFilters}
              filtersApplied={filtersApplied()}
            />
          }
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          {exportingLeaderboard ?
            <CommunityLeaderboardExport communityId={communityId}/>
            :
            <CommunityLeaderboardRanking
              students={leaderboardRanking[1]}
              statsItems={playingItems}
              fetchRanking={fetchRanking}
              hasMore={rankingPages[1] <= rankingTotalPages[1]}
              tabId={1}
              loader={leaderboardLoader}
              clearFilters={clearFilters}
              filtersApplied={filtersApplied()}
            />
          }
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          {exportingLeaderboard ?
            <CommunityLeaderboardExport communityId={communityId}/>
            :
            <CommunityLeaderboardRanking
              students={leaderboardRanking[2]}
              statsItems={trainingItems}
              fetchRanking={fetchRanking}
              hasMore={rankingPages[2] <= rankingTotalPages[2]}
              tabId={2}
              loader={leaderboardLoader}
              clearFilters={clearFilters}
              filtersApplied={filtersApplied()}
            />
          }
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          {exportingLeaderboard ?
            <CommunityLeaderboardExport communityId={communityId}/>
            :
            <CommunityLeaderboardRanking
              students={leaderboardRanking[3]}
              statsItems={curriculumItems}
              fetchRanking={fetchRanking}
              hasMore={rankingPages[3] <= rankingTotalPages[3]}
              tabId={3}
              loader={leaderboardLoader}
              clearFilters={clearFilters}
              filtersApplied={filtersApplied()}
            />
          }
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default CoachDashboardCommunityLeaderboard
